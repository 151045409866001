
// App Components
import BackgroundGenerator from '../../components/bannerGenerator/BackgroundGenerator.js';

// Use-wallet for account management 
import { useWallet } from 'use-wallet';

// Context modules
import { useSharedDataManipulationFunctions } from "../../shared/DataManipulationFunctions";
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "../../shared/NavigationPageGlobalState";
import { useSharedCollectionsMetadata } from "../CollectionsMetadata";

// Location of the collection's assets
const DEMO_COLLETION_ASSET_DIRECTORY = "images/partners/DEMO/lowRes_transparentBackground/";
const PROOF_MOONBIRDS_COLLETION_ASSET_DIRECTORY_THUMBNAILS = "images/partners/DEMO/lowRes_thumbnails/";
// Location of the collection's backgrounds
const DEMO_BACKGROUND_ASSET_DIRECTORY = "images/partners/DEMO/backgrounds/";
const DEMO_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = "images/templates/";
// Location of the collection's accessories
const DEMO_ACCESSORIES_ASSET_DIRECTORY = "images/partners/DEMO/accessories/";





// ------------------------
// BACKGROUND CUSTOMISATION
// ------------------------

// Backgrounds for Collection
// **************************

function generateBackgroundItemsForCollection() {

    let imageArray = [];
    
    let filePrefixDir = DEMO_BACKGROUND_ASSET_DIRECTORY;

    // [] Square Dark Podium
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Dark Podium",  
            src:filePrefixDir + "square-dark-podium.jpg", 
            width: 600, height: 337, x:0, y:0}]
    );

    // [] Podium On Bright
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Spotlight",  
            src:filePrefixDir + "podium-on-bright.jpg", 
            width: 600, height: 419, x:0, y:0}]
    );

    // [] Beach Podium
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Beach",  
            src:filePrefixDir + "beach-podium.jpg", 
            width: 600, height: 343, x:0, y:0}]
    );

    // [] Heart Podium
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Hearts",  
            src:filePrefixDir + "heart-podium.jpg", 
            width: 600, height: 398, x:0, y:0}]
    );

    // [] Exhibition podium
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Exhibition",  
            src:filePrefixDir + "scene-circle-podium.jpg", 
            width: 600, height: 399, x:0, y:0}]
    );

    // [] Wall podium
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Museum wall",  
            src:filePrefixDir + "wall-podium.jpg", 
            width: 600, height: 325, x:0, y:0}]
    );
    
    // [] Wood Pedastal podium
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "background", name: "Wood podium",  
            src:filePrefixDir + "wood-pedestal-podium.jpg", 
            width: 600, height: 427, x:0, y:0}]
    );

    // [] Complex Good Morning Cafe
    // tempArray = [];
    // tempArray.push({ index: 0, mode: "BACKGROUND", category: "background", name: "WP Cafe",  
    //                     src:filePrefixDir + "wp_fan_arts/goodMorning_1_cafe.png", 
    //                     width: 512, height: 512, x:0, y:0});
    // tempArray.push({ index: 1, mode: "BACKGROUND_LAYER", 
    //                     src:filePrefixDir + "wp_fan_arts/cafe_element.png", 
    //                     width: 208, height: 281, x:263, y:272, rotation: 0});
    // imageArray.push(tempArray);

    // EMPTY TEMPLATES
    // ---------------

    filePrefixDir = DEMO_BACKGROUND_TEMPLATE_ASSET_DIRECTORY;
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "Twitter banner", 
           backgroundColor: "#ff7dbd",  
           src:filePrefixDir + "tpl_twitter_banner.png", 
           width: 1500, height: 500, x:0, y:0}]
    );
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "Instagram Post",
           backgroundColor: "#ff7dbd",    
           src:filePrefixDir + "tpl_instagram_post.png", 
           width: 1080, height: 1080, x:0, y:0}]
    );
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone X", 
           backgroundColor: "#ff7dbd",  
           src:filePrefixDir + "tpl_iphoneX.png", 
           width: 1125, height: 2436, x:0, y:0}]
    );
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XR", 
           backgroundColor: "#ff7dbd",  
           src:filePrefixDir + "tpl_iphoneXR.png", 
           width: 828, height: 1792, x:0, y:0}]
    );
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XS", 
           backgroundColor: "#ff7dbd",  
           src:filePrefixDir + "tpl_iphoneXS.png", 
           width: 1125, height: 2436, x:0, y:0}]
    );
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "Google Pixel 3", 
           backgroundColor: "#ff7dbd",  
           src:filePrefixDir + "tpl_pixel3.png", 
           width: 1080, height: 2160, x:0, y:0}]
    );
    imageArray.push(
        [{ index: 0, mode: "BACKGROUND", category: "template", name: "Samsung Note 10", 
           backgroundColor: "#ff7dbd",  
           src:filePrefixDir + "tpl_samsungNote10.png", 
           width: 1080, height: 2280, x:0, y:0}]
    );

    return imageArray;

};





// -------------------------
// ACCESSORIES CUSTOMISATION
// -------------------------

// Accessories 1
// *************

function generateAccessories1ForCollection() {
    let availableAssets = [];
    const filePrefixDir = DEMO_ACCESSORIES_ASSET_DIRECTORY;
    let itemId = 0;
    availableAssets.push(
        {
            type: "ACCESSORIES",
            id: 'accessoriesItem' + itemId++,
            image_local_file_transparent: filePrefixDir + "EmptyBubbleLeft.png",
            image_local_file_thumbnail: filePrefixDir + "EmptyBubbleLeft.png", 
            name: "Bubble (Left)",
            scale: 1,               // -1 to flip the element
            addedToCanvas: false,    // Allow to only add the cow once to the canvas
            width: 174, height: 168, x:0, y:0
        }
    );
    availableAssets.push(
        {
            type: "ACCESSORIES",
            id: 'accessoriesItem' + itemId++,
            image_local_file_transparent: filePrefixDir + "EmptyBubbleRight.png",
            image_local_file_thumbnail: filePrefixDir + "EmptyBubbleRight.png",
            name: "Bubble (right)",
            scale: 1,               // -1 to flip the element
            addedToCanvas: false,    // Allow to only add the cow once to the canvas
            width: 174, height: 168, x:0, y:0
        }
    );

    return availableAssets;
};

// Accessories 2
// *************

function generateAccessories2ForCollection() {
    let availableAssets = [];
    // let filePrefixDir = DEMO_ACCESSORIES_ASSET_DIRECTORY;
    return availableAssets;
};





const BackgroundGeneratorConfig = (props) => {

    // Handle Metamask connection 
    const wallet = useWallet();
    const activate = (connector) => wallet.connect(connector);
    const deActivate = () => wallet.reset();

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        isSelectedCowOnCanvas,                                  // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        ownerAvailableCows,
    } = useSharedNavigationPageGlobalState();
    // Collections metadata
    const { 
        DEMO_WORLD_COLLETION_METADATA,
        generateAllDemoAssets,
        generateAllGoodMorningCafeAssets,
    } = useSharedCollectionsMetadata();
    // Data Manipulation
    const {
        performGenericAssetSearchFromQueryString
    } = useSharedDataManipulationFunctions();
    // Retrieve items from context --------------------------------------------------
    
    // Search for an asset based on either a number of 
    // based on some trait 
    function assetSearch(queryString, assetList, category) {
        if (category === "collection") {
            return performGenericAssetSearchFromQueryString(DEMO_COLLETION_ASSET_DIRECTORY, queryString, assetList, PROOF_MOONBIRDS_COLLETION_ASSET_DIRECTORY_THUMBNAILS);
        }
        if (category === "partner1") {
            //TBD
        }
        if (category === "partner2") {
            //TBD
        }
    }

    return (

        <BackgroundGenerator 

            // Wallet connector
            wallet={wallet}
            walletConnect={activate}
            walletDisconnect={deActivate}

            // List of available asset to select 
            collectionAssets={generateAllDemoAssets}                                    // We should pass functions, not results 
            myAssets={ownerAvailableCows} 
            isCowOwner={userHasCow}

            // Global variable to track if a cow is selected on the canvas
            isSelectedCowOnCanvas={isSelectedCowOnCanvas}

            // List of available partner asset to select 
            partnerAssets={[generateAllGoodMorningCafeAssets, () => { return []} ]}    // We should pass functions, not results 
            // List of available background to select 
            availableBackground={generateBackgroundItemsForCollection()}
            availableAccessories1={generateAccessories1ForCollection()}
            availableAccessories2={generateAccessories2ForCollection()}

            allowAssetReRollConfig={
                {   
                    collection: true,
                    partner1: true,
                    partner2: true
                }
            }

            // Generator behavior's config flags
            allowCheckUserWallet = {true}           // Do we want to lookup the user wallet to search its asset?
            allowAssetSelection={true}              // Do we want the user to pick a specific collection asset? 
            allowAssetUploadAsAssets={true}         // Do we want the user to upload custom assets?
            allowAssetUploadAsBackground={true}     // Do we want the user to upload custom background?

            // Asset search function
            assetSearchFunction={assetSearch}
            assetCollectionMetadata={DEMO_WORLD_COLLETION_METADATA}                       // Metadata for the collection

            // Configure which panels to display
            // Collection assets + Collection backgrouns are mandatory 
            displayItemConfig={
                {
                    // Value are "always" for always
                    //           "walletOnly" for only when user is connect and has assetDirectory
                    //            "never" for never
                    partner1: "always",
                    partner2: "never",
                    accessories1: "always",
                    accessories2: "walletOnly"
                }
            }

            // Display Items legend (below each asset, background, accessories)
            displayItemLegendConfig={
                {
                    assets: true,
                    partner1: true,
                    partner2: true,
                    background: true,
                    accessories1: true,
                    accessories2: true
                }
            }

            // Configure labels 
            labelsConfig={
                { 
                    nftYouOwnTitle: 'NFT You Own',                          // Module title on the left hand side (When wallet is connected)
                    
                    availableItems: 'Assets',                               //         
                    allAssetTitlePannel: "My NFTs",                         // Asset panel title (all collections items)
                    myAssetTitlePannel: "This Collection",                  // Asset panel title (my items)
                    partner1TitlePannel: "Partner Collection",              // (Partner) Asset panel 1 title
                    partner2TitlePannel: null,                              // (Partner) Asset panel 2 title

                    accessories1TitlePannel: "Delightful Accessories",      // Accessories panel 1 title 
                    accessories1MessageToConnectWallet:                     // Message to let user connect wallet to unlock this panel (if wallet gated)
                        "You can unlock delightful items from the collection if you own at least one qualifying NFT.",
                    accessories1MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any NFT in the wallet you connected to let you unlock this section...",   
                    accessories2TitlePannel: "Exclusive Items",             // Accessories panel 2 title 
                    accessories2MessageToConnectWallet:                     // Message to let user connect wallet to unlock accessories module 1 (if wallet gated)
                        "You can unlock exclusive items from the collection if you own at least one qualifying NFT.",
                    accessories2MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any NFT in the wallet you connected to let you unlock this section...", 

                    yourUploadedAsset: "Upload your own asset",             // Upload your own asset panel title
                    
                    availableBackgrounds: 'Available backgrounds',          // 
                    officialBackgrounds: 'Official backgrounds',            // Available background panel title 
                    templateBackgrounds: 'Template backgrounds',            // Available template panel title 
                    yourUploadedBackgrounds: 'Upload your own',             // Upload your own background panel title 

                    defaultTextAddedToCanvas: 
                            'Welcome to Banner Creator',                    // Default text being added to canvas 
                    
                    okButton: "MEW",                                        // OK Button
                    collectionShortName: "DMO",                             // Collection short name
                    assetName: "NFT",                                       // Label to describe an asset
                    assetSearchButtonText: "NFT Search",                    // Search button to lookup new asset   
                    assetSearchLabelText: "Looking for a specific asset? Search by name or traits" ,    
                                                                            // Search button to lookup new asset 
                    partner1AssetSearchButtonText: "Partners 1 Search",    // Search button to lookup partner 1 asset   
                                                                         // Search label to lookup partner 1 asset  
                    partner1AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",
                    partner2AssetSearchButtonText: "Partner 2 Search",    // Search button to lookup partner 2 asset   
                                                                         // Search label to lookup partner 2 asset  
                    partner2AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits", 

                    instructionsTitle: "Banner Creator!",                   // Title of the instructions popup 

                    paletteColor1Text: "Sample Color #1",
                    paletteColor1HexValue: "#272727", 
                    paletteColor2Text: "Sample Color #2",
                    paletteColor2HexValue: "#ffffff",
                    paletteColor3Text: "Sample Color #3",
                    paletteColor3HexValue: "#FF652F",
                    paletteColor4Text: "Sample Color #4",
                    paletteColor4HexValue: "#FFE400", 
                    // paletteColor5Text: "Sample Color #5",
                    // paletteColor5HexValue: "#14A76C", 
                    // paletteColor5Text: "Sample Color #6",
                    // paletteColor5HexValue: "#14A76C", 
                    // paletteColor5Text: "Sample Color #7",
                    // paletteColor5HexValue: "#14A76C", 

                    templateColor1Text: "Abyss",
                    templateColor1HexValue: "#6a6870", 
                    templateColor2Text: "Cyan",
                    templateColor2HexValue: "#95e6d6",
                    templateColor3Text: "Lime",
                    templateColor3HexValue: "#cadb99",
                    templateColor4Text: "Midnight",
                    templateColor4HexValue: "#d5c9fe", 
                    templateColor5Text: "Pixker",
                    templateColor5HexValue: "#31622f",
                    templateColor6Text: "Sunlight",
                    templateColor6HexValue: "#eecaa3",  
                    templateColor7Text: "Trenches",
                    templateColor7HexValue: "#dd5d61",
                    templateColor8Text: "Twilight",
                    templateColor8HexValue: "#edacb9",
                }
            }

        />

    )

}

export default BackgroundGeneratorConfig;