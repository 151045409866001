import FooterWalletConnector from "../../components/shared/FooterWalletConnect"

const Footer = (props) => {

    return (

    <>
        <footer className="footer container-fluid">
            <FooterWalletConnector nftEntityName="ChubbyLand" doConnectWallet={true} />
        </footer>
    </>

    );

}

export default Footer;