import React from 'react';

/**
 * Common HTML code to include to any footers
 * @returns 
 */
 const FooterCommonNote = () => {

    return (

        <React.Fragment>
            <p><strong>Own an NFT collection and want to offer a similar tool to your community?</strong> Reach out to us so we can get you started. Here are a few collections that have been using the Banner Builder:</p>
            <ul>
                <li className="text-left"><strong><a href="https://banners.gmcafe.io/" target="_blank">Good Morning Cafe</a></strong></li>
                <li className="text-left"><strong><a href="http://mememaker.kittycones.com/" target="_blank">Kitty Cones</a></strong></li>
                <li className="text-left"><strong><a href="https://banner.whiskersnft.xyz/" target="_blank">Whiskers</a></strong></li>
                <li className="text-left"><strong><a href="https://banners.wonderpals.com/" target="_blank">Wonderpals</a></strong></li>
                <li className="text-left">and more...</li>
            </ul>
        </React.Fragment>

    );

}

export default FooterCommonNote;