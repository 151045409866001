
// App Components
import BackgroundGenerator from '../../../components/bannerGenerator/BackgroundGenerator.js';

// Use-wallet for account management 
import { useWallet } from 'use-wallet';

// Context modules
import { useSharedDataManipulationFunctions } from "../../../shared/DataManipulationFunctions";
import { useSharedNavigationFunctions } from "../../../shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "../../../shared/NavigationPageGlobalState";
import { useSharedCollectionsMetadata } from "../../CollectionsMetadata";







const BackgroundGeneratorConfig = (props) => {

    // Handle Metamask connection 
    const wallet = useWallet();
    const activate = (connector) => wallet.connect(connector);
    const deActivate = () => wallet.reset();

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        isSelectedCowOnCanvas,                                  // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        ownerAvailableCows,
    } = useSharedNavigationPageGlobalState();
    // Collections metadata
    const { 
        PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        PROOF_MOONBIRDS_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        generateAllMoonbirdsAssets,
        generateAllMBOdditiesAssets,
    } = useSharedCollectionsMetadata();
    // Data Manipulation
    const {
        performGenericAssetSearchFromQueryString
    } = useSharedDataManipulationFunctions();
    // Retrieve items from context --------------------------------------------------
    
    // Search for an asset based on either a number of 
    // based on some trait 
    function assetSearch(queryString, assetList, category) {
        if (category === "collection") {
            return performGenericAssetSearchFromQueryString(PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY, queryString, assetList, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
        }
        if (category === "partner1") {
            return performGenericAssetSearchFromQueryString(PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY, queryString, assetList, PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
        }
        if (category === "partner2") {
            return performGenericAssetSearchFromQueryString(PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY, queryString, assetList, PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
        }
    }

    // Backgrounds for Collection
    // **************************

    function generateBackgroundItemsForCollection() {

        let imageArray = [];
        
        let filePrefixDir = PROOF_MOONBIRDS_COLLECTION_BACKGROUND_ASSET_DIRECTORY;

        // [] Opensea  Moonbirds
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Moonbirds",  
                src:filePrefixDir + "opensea-moonbirds.png", 
                width: 2100, height: 600, x:0, y:0}]
        );
        // [] Opensea Oddities
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Oddities",  
                src:filePrefixDir + "opensea-oddities.png", 
                width: 2100, height: 600, x:0, y:0}]
        );

        // // [] Square Dark Podium
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Dark Podium",  
        //         src:filePrefixDir + "square-dark-podium.jpg", 
        //         width: 600, height: 337, x:0, y:0}]
        // );

        // // [] Podium On Bright
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Spotlight",  
        //         src:filePrefixDir + "podium-on-bright.jpg", 
        //         width: 600, height: 419, x:0, y:0}]
        // );

        // // [] Beach Podium
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Beach",  
        //         src:filePrefixDir + "beach-podium.jpg", 
        //         width: 600, height: 343, x:0, y:0}]
        // );

        // // [] Heart Podium
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Hearts",  
        //         src:filePrefixDir + "heart-podium.jpg", 
        //         width: 600, height: 398, x:0, y:0}]
        // );

        // // [] Exhibition podium
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Exhibition",  
        //         src:filePrefixDir + "scene-circle-podium.jpg", 
        //         width: 600, height: 399, x:0, y:0}]
        // );

        // // [] Wall podium
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Museum wall",  
        //         src:filePrefixDir + "wall-podium.jpg", 
        //         width: 600, height: 325, x:0, y:0}]
        // );
        
        // // [] Wood Pedastal podium
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Wood podium",  
        //         src:filePrefixDir + "wood-pedestal-podium.jpg", 
        //         width: 600, height: 427, x:0, y:0}]
        // );

        // [] Complex Good Morning Cafe
        // tempArray = [];
        // tempArray.push({ index: 0, mode: "BACKGROUND", category: "background", name: "WP Cafe",  
        //                     src:filePrefixDir + "wp_fan_arts/goodMorning_1_cafe.png", 
        //                     width: 512, height: 512, x:0, y:0});
        // tempArray.push({ index: 1, mode: "BACKGROUND_LAYER", 
        //                     src:filePrefixDir + "wp_fan_arts/cafe_element.png", 
        //                     width: 208, height: 281, x:263, y:272, rotation: 0});
        // imageArray.push(tempArray);

        // EMPTY TEMPLATES
        // ---------------

        filePrefixDir = PROOF_MOONBIRDS_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY;
        
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Twitter banner", 
            backgroundColor: "#ffffff",  
            src:filePrefixDir + "tpl_twitter_banner.png", 
            width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Instagram Post",
            backgroundColor: "#ffffff",    
            src:filePrefixDir + "tpl_instagram_post.png", 
            width: 1080, height: 1080, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone X", 
            backgroundColor: "#ffffff",  
            src:filePrefixDir + "tpl_iphoneX.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XR", 
        //        backgroundColor: "#ffffff",  
        //        src:filePrefixDir + "tpl_iphoneXR.png", 
        //        width: 828, height: 1792, x:0, y:0}]
        // );
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XS", 
        //        backgroundColor: "#ffffff",  
        //        src:filePrefixDir + "tpl_iphoneXS.png", 
        //        width: 1125, height: 2436, x:0, y:0}]
        // );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Google Pixel 3", 
            backgroundColor: "#ffffff",  
            src:filePrefixDir + "tpl_pixel3.png", 
            width: 1080, height: 2160, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Samsung Note 10", 
            backgroundColor: "#ffffff",  
            src:filePrefixDir + "tpl_samsungNote10.png", 
            width: 1080, height: 2280, x:0, y:0}]
        );

        return imageArray;

    };


    // -------------------------
    // ACCESSORIES CUSTOMISATION
    // -------------------------

    // Accessories 1
    // *************

    function generateAccessories1ForCollection() {
        let availableAssets = [];
        const filePrefixDir = PROOF_MOONBIRDS_COLLECTION_ACCESSORIES_ASSET_DIRECTORY;
        let itemId = 0;

        // availableAssets.push(
        //     {
        //         type: "ACCESSORIES",
        //         id: 'accessoriesItem' + itemId++,
        //         image_local_file_transparent: filePrefixDir + "pixel-speech-bubble-right.png",
        //         image_local_file_thumbnail: filePrefixDir + "pixel-speech-bubble-right.png",
        //         name: "Bubble (Right)",
        //         scale: 1,               // -1 to flip the element
        //         addedToCanvas: false,    // Allow to only add the cow once to the canvas
        //         width: 612, height: 612, x:0, y:0
        //     }
        // );
        // availableAssets.push(
        //     {
        //         type: "ACCESSORIES",
        //         id: 'accessoriesItem' + itemId++,
        //         image_local_file_transparent: filePrefixDir + "pixel-speech-bubble-left.png",
        //         image_local_file_thumbnail: filePrefixDir + "pixel-speech-bubble-left.png",
        //         name: "Bubble (Left)",
        //         scale: 1,               // -1 to flip the element
        //         addedToCanvas: false,    // Allow to only add the cow once to the canvas
        //         width: 612, height: 612, x:0, y:0
        //     }
        // );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_1.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_1.png",
                name: "MB Headwear: raincloud",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 649, height: 325, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_2.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_2.png",
                name: "MB Headwear: halo",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 599, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_3.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_3.png",
                name: "MB Headwear: tiny crown",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 600, height: 317, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_4.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_4.png",
                name: "MB Headwear: grail",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 597, height: 320, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_5.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_5.png",
                name: "MB Headwear: dancing flame",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 596, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_6.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_6.png",
                name: "MB Headwear: diamond",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 748, height: 325, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mb_head_7.png",
                image_local_file_thumbnail: filePrefixDir + "mb_head_7.png",
                name: "MB Headwear: chromie",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 596, height: 300, x:0, y:0
            }
        );

        return availableAssets;
    };

    // Accessories 2
    // *************

    function generateAccessories2ForCollection() {
        let availableAssets = [];
        // let filePrefixDir = DEMO_ACCESSORIES_ASSET_DIRECTORY;
        return availableAssets;
    };

    return (

        <BackgroundGenerator 

            // Wallet connector
            wallet={wallet}
            walletConnect={activate}
            walletDisconnect={deActivate}

            // List of available asset to select 
            collectionAssets={generateAllMoonbirdsAssets}                                    // We should pass functions, not results 
            myAssets={ownerAvailableCows} 
            isCowOwner={userHasCow}

            // Global variable to track if a cow is selected on the canvas
            isSelectedCowOnCanvas={isSelectedCowOnCanvas}

            // List of available partner asset to select 
            partnerAssets={[generateAllMBOdditiesAssets, () => { return []} ]}              // We should pass functions, not results 
            // List of available background to select 
            availableBackground={generateBackgroundItemsForCollection()}
            availableAccessories1={generateAccessories1ForCollection()}
            availableAccessories2={generateAccessories2ForCollection()}

            allowAssetReRollConfig={
                {   
                    collection: true,
                    partner1: true,
                    partner2: true
                }
            }

            // Generator behavior's config flags
            allowCheckUserWallet = {true}           // Do we want to lookup the user wallet to search its asset?
            allowAssetSelection={true}              // Do we want the user to pick a specific collection asset? 
            allowAssetUploadAsAssets={true}         // Do we want the user to upload custom assets?
            allowAssetUploadAsBackground={true}     // Do we want the user to upload custom background?

            // Asset search function
            assetSearchFunction={assetSearch}
            assetCollectionMetadata={PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS}                       // Metadata for the collection
            partner1CollectionMetadata={PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS}            // Metadata for the partner collection
            partner2CollectionMetadata={PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS}            // Metadata for the partner collection

            // Configure which panels to display
            // Collection assets + Collection backgrouns are mandatory 
            displayItemConfig={
                {
                    // Value are "always" for always
                    //           "walletOnly" for only when user is connect and has assetDirectory
                    //           "never" for never
                    partner1: "always",
                    partner2: "never",
                    accessories1: "always",
                    accessories2: "never"
                }
            }

            // Display Items legend (below each asset, background, accessories)
            displayItemLegendConfig={
                {
                    assets: true,
                    partner1: true,
                    partner2: true,
                    background: true,
                    accessories1: true,
                    accessories2: true
                }
            }

            // Configure labels 
            labelsConfig={
                { 
                    nftYouOwnTitle: 'Moonbirds You Own',                 // Module title on the left hand side (When wallet is connected)
                    
                    availableItems: 'Assets',                            //         
                    allAssetTitlePannel: "The Parliament",               // Asset panel title (all collections items)
                    myAssetTitlePannel: "My Moonbird(s)",                // Asset panel title (my items)

                    partner1TitlePannel: "The Oddities",                 // (Partner) Asset panel 1 title
                    partner2TitlePannel: null,                           // (Partner) Asset panel 2 title
                    
                    accessories1TitlePannel: "Accessories",              // Accessories panel 1 title 
                    accessories1MessageToConnectWallet:                  // Message to let user connect wallet to unlock this panel (if wallet gated)
                        "You can unlock delightful and exclusives accessories from the Moonbirds collection if you own at least one Moonbird.",
                    accessories1MessageNoNFTFoundInWallet:               // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any Moonbird in the wallet you connected to let you unlock this section...",   
                    accessories2TitlePannel: "Oddities",                 // Accessories panel 2 title 
                    accessories2MessageToConnectWallet:                  // Message to let user connect wallet to unlock accessories module 1 (if wallet gated)
                        "You can unlock tasty oddities from the Moonbirds collection if you own at least one Moonbird.",
                    accessories2MessageNoNFTFoundInWallet:               // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any Moonbird in the wallet you connected to let you unlock this section...", 

                    yourUploadedAsset: "Upload your own asset",          // Upload your own asset panel title
                    
                    availableBackgrounds: 'Available backgrounds',       // 
                    officialBackgrounds: 'Official backgrounds',         // Available background panel title 
                    templateBackgrounds: 'Template backgrounds',         // Available template panel title 
                    yourUploadedBackgrounds: 'Upload your own',          // Upload your own background panel title 

                    defaultTextAddedToCanvas: 
                            'Hoot Hoot Hoot!',                           // Default text being added to canvas 
                    
                    okButton: "OK",                                      // OK Button
                    collectionShortName: "PROOF-MB",                     // Collection short name
                    assetName: "Moonbird",                               // Label to describe an asset
                    assetSearchButtonText: "Moonbird Search",            // Search button to lookup new collection asset   
                    assetSearchLabelText: "Looking for a specific bird? Search by token ID or traits" ,    
                                                                         // Search label to lookup new partner asset 

                    partner1AssetSearchButtonText: "Oddities Search",    // Search button to lookup partner 1 asset   
                                                                         // Search label to lookup partner 1 asset  
                    partner1AssetSearchLabelText: "Looking for a specific oddities? Search by token ID or traits",
                    partner2AssetSearchButtonText: "Partner2 Search",    // Search button to lookup partner 2 asset   
                                                                         // Search label to lookup partner 2 asset  
                    partner2AssetSearchLabelText: "Looking for a specific NFT? Search by token ID or traits",

                    instructionsTitle: "Moonbirds Banner Maker!",        // Title of the instructions popup 

                    paletteColor1Text: "Moonbird Color #1",
                    paletteColor1HexValue: "rgb(255,255,255)", 
                    paletteColor2Text: "Moonbird Color #2",
                    paletteColor2HexValue: "rgb(230, 184, 67)",
                    paletteColor3Text: "Moonbird Color #3",
                    paletteColor3HexValue: "rgb(130,132,196)",
                    paletteColor4Text: "Moonbird Color #4",
                    paletteColor4HexValue: "rgb(47,98,139)", 
                    // paletteColor5Text: "Sample Color #5",
                    // paletteColor5HexValue: "#14A76C", 
                    // paletteColor5Text: "Sample Color #6",
                    // paletteColor5HexValue: "#14A76C", 
                    // paletteColor5Text: "Sample Color #7",
                    // paletteColor5HexValue: "#14A76C", 

                    templateColor1Text: "Moonbird Color #1",
                    templateColor1HexValue: "rgb(255,255,255)", 
                    templateColor2Text: "Moonbird Color #2",
                    templateColor2HexValue: "rgb(230, 184, 67)",
                    templateColor3Text: "Moonbird Color #3",
                    templateColor3HexValue: "rgb(130,132,196)",
                    templateColor4Text: "Moonbird Color #4",
                    templateColor4HexValue: "rgb(47,98,139)", 
                    // templateColor5Text: "Sample Color #5",
                    // templateColor5HexValue: "#14A76C", 
                    // templateColor6Text: "Sample Color #6",
                    // templateColor6HexValue: "#14A76C", 
                    // templateColor7Text: "Sample Color #7",
                    // templateColor7HexValue: "#14A76C", 
                    // templateColor8Text: "Sample Color #8",
                    // templateColor8HexValue: "#14A76C", 
                }
            }

        />

    )

}

export default BackgroundGeneratorConfig;