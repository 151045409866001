import { useBetween } from "use-between";
import { useContext } from 'react';

// Bootstrap components 
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

// Custom Icons Bootstrap
import { List } from 'react-bootstrap-icons';

const useUIFunctions = () => {

    // Perform logic to toggle Accordion
    // And keep always one open!
    function GenericBootstrapAccordionToggle({ children, eventKey }) {

        const { activeEventKey } = useContext(AccordionContext);
        
        // Are we clicking on the active element? 
        const isCurrentEventKey = activeEventKey === eventKey;
        //console.log("AssetsAccordionToggle: children", children + " | eventKey = ", eventKey + " | activeEventKey = ", activeEventKey + " | isCurrentEventKey = ", isCurrentEventKey);
        
        const decoratedOnClick = useAccordionButton(eventKey, (e) => {
            // Do nothing
        },);

        // If we are clicking on a pannel that is not 
        // Currently open, we add a click event to open it
        if (!isCurrentEventKey) {
            return (

                <>
                    <div className="accordion-button" 
                         onClick={decoratedOnClick}>
                        <List className="align-self-center"/>
                        &nbsp;{children}
                    </div>
                </>
                // <div
                // className="accordion-button"
                // // style={{ backgroundColor: 'pink' }}
                // onClick={decoratedOnClick}
                // >
                // {children}
                // </div>
            );
        } else {
            return (
                <>
                    <div className="accordion-button">
                        <List className="align-self-center"/>
                    &nbsp;{children}
                    </div>
                </>
            );
        }
    }

    return {
        GenericBootstrapAccordionToggle,
    };

};

export const useSharedUIFunctions = () => useBetween(useUIFunctions);