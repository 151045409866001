// Docs / References
//
// OpenSea API for collection
// https://api.opensea.io/api/v1/assets?collection=goodmorningcafe&owner=0x9EB2DD513b007646B10a019f11481F9eB69DB77c
//
// React-konvas
// https://konvajs.org/docs/react/index.html
//
// Autoconnect wallet
// https://github.com/aragon/use-wallet
//
// Confirmation popup
// https://github.com/nicolas-van/react-bootstrap-confirmation
//
// Global context
// https://github.com/betula/use-between






// React components
import React, {useEffect} from 'react'

// Bootstrap declarations
import Container from 'react-bootstrap/Container';

// Use-wallet for account management 
import { UseWalletProvider, useWallet } from 'use-wallet'

// Context modules
import { useSharedGlobalState } from "./shared/GlobalState";
import { useSharedNavigationFunctions } from "./shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "./shared/NavigationPageGlobalState";
import { useSharedDataManipulationFunctions } from "./shared/DataManipulationFunctions";
import { useSharedCollectionsMetadata } from "./partner/CollectionsMetadata";

// App Components
import OpenseaStats from './components/OpenseaStats.js';
import CollectionExplorer from './components/collectionExplorer/CollectionExplorer.js';




// ---------------------------
// PARTNER SPECIFIC RESSOURCES
// ---------------------------


// Header
import Generic_Header from './partner/_Generic/Header.js';
import GMC_Header from './partner/GMC/Header.js';
import GMC_HeaderExplorer from './partner/GMC/HeaderExplorer.js';
import KC_Header from './partner/KC/Header.js';
import WP_Header from './partner/WP/Header.js';
import WHISKERS_Header from './partner/WHISKERS/Header.js';
import MOONBIRDSPROOF_Header from './partner/MOONBIRDS/PROOF/Header.js';
import MOONBIRDSDBS_Header from './partner/MOONBIRDS/DBS/Header.js';
import CHUBBY_Header from './partner/CHUBBY/Header.js';
// import KUMO_Header from './partner/KUMO/Header.js';

// Footer
import Generic_Footer from './partner/_Generic/Footer.js';
import GMC_Footer from './partner/GMC/Footer.js';
import KC_Footer from './partner/KC/Footer.js';
import WP_Footer from './partner/WP/Footer.js';
import WHISKERS_Footer from './partner/WHISKERS/Footer.js';
import MOONBIRDSPROOF_Footer from './partner/MOONBIRDS/PROOF/Footer.js';
import MOONBIRDSDBS_Footer from './partner/MOONBIRDS/DBS/Footer.js';
import CHUBBY_Footer from './partner/CHUBBY/Footer.js';
// import KUMO_Footer from './partner/KUMO/Footer.js';

// Background generator configs
import DEMO_Backgroundgenerator from './partner/_Generic/BackgroundGeneratorConfig.js';
import GMC_Backgroundgenerator from './partner/GMC/BackgroundGeneratorConfig.js';
import KC_Backgroundgenerator from './partner/KC/BackgroundGeneratorConfig.js';
import WHISKERS_Backgroundgenerator from './partner/WHISKERS/BackgroundGeneratorConfig.js';
import MOONBIRDSPROOF_Backgroundgenerator from './partner/MOONBIRDS/PROOF/BackgroundGeneratorConfig.js';
import MOONBIRDSDBS_Backgroundgenerator from './partner/MOONBIRDS/DBS/BackgroundGeneratorConfig.js';
import WP_Backgroundgenerator from './partner/WP/BackgroundGeneratorConfig.js';
import CHUBBY_Backgroundgenerator from './partner/CHUBBY/BackgroundGeneratorConfig.js';
// import KUMO_Backgroundgenerator from './partner/KUMO/BackgroundGeneratorConfig.js';





        


    
// ----------------
// MAIN APPLICATION
// ----------------

const Application = () => {

    // Handle Metamask connection 
    const wallet = useWallet();
    
    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        env_APP_MODE,
        setUserIsAdmin,
        errorMessage, setErrorMessage,
        setDefaultAccount,
        setWalletConnected
    } = useSharedGlobalState();
    // DataManipulation functions
    const { 
        getAssetIdFromGoodMorningCafeCollectionTokenId,
        getAssetIdFromKittyConeClassicCollectionTokenId,
        getAssetIdFromChubbyLandNFTCollectionTokenId,
        getAssetIdFromWonderPalsCollectionTokenId,
        getAssetIdFromWhiskersCollectionTokenId,
        getAssetIdFromMoonbirdsCollectionTokenId,
        // getAssetIdFromKumoWorldCollectionTokenName,
    } = useSharedDataManipulationFunctions();
    // Navigation functions
    const { 
        setUserHasCow,                              // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        selectedNavItem, 
        setOwnerAvailableCows,
        setOwnerAvailableCowsNFT,
        setHasUserSelectedACow,
        ownerWalletAddress, setOwnerWalletAddress,      // User wallet address to prevent querying user collection multiple time 
        setOpenseaCollectionData
    } = useSharedNavigationPageGlobalState();
    // Collections metadata
    const { 
        GMC_OG_COLLECTION_DETAILS,
        GMC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        GMC_OG_COLLECTION_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_METADATA_ASSETS,
        GMC_OG_COLLECTION_METADATA_TRAITS,

        KC_OG_COLLECTION_DETAILS,
        KC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        KC_OG_COLLECTION_ASSET_DIRECTORY,
        KC_OG_COLLECTION_METADATA_ASSETS,
        KC_OG_COLLECTION_METADATA_TRAITS,

        KC_5K_COLLECTION_DETAILS,
        KC_5K_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        KC_5K_COLLECTION_ASSET_DIRECTORY,
        KC_5K_COLLECTION_METADATA_ASSETS,
        KC_5K_COLLECTION_METADATA_TRAITS,

        CHUBBY_OG_COLLECTION_DETAILS,
        CHUBBY_OG_COLLECTION_ASSET_DIRECTORY,

        WONDERPALS_OG_COLLECTION_DETAILS,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        WONDERPALS_OG_COLLECTION_METADATA_ASSETS,
        WONDERPALS_OG_COLLECTION_METADATA_TRAITS,

        WHISKERS_OG_COLLECTION_DETAILS,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        WHISKERS_OG_COLLECTION_METADATA_ASSETS,
        WHISKERS_OG_COLLECTION_METADATA_TRAITS,

        PROOF_MOONBIRDS_COLLECTION_DETAILS,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS,
        PROOF_MOONBIRDS_COLLECTION_METADATA_TRAITS,

        // KUMOWORLD_COLLETION_ASSET_DIRECTORY

    } = useSharedCollectionsMetadata();
    // Retrieve items from context --------------------------------------------------

    

    // Handle wallet events 
    useEffect(() => {
        //console.log("In useEffet = wallet");
        if (wallet.status === 'connected' && wallet.account) {
            //console.log("Setting up wallet and loading NFT own... Wallet addr:" + wallet.account);
            setHasUserSelectedACow(false);
            setDefaultAccount(wallet.account);
            setWalletConnected(true);
            if ((env_APP_MODE === "GMC")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(GMC_OG_COLLECTION_DETAILS.collectionName, wallet.account, GMC_OG_COLLECTION_ASSET_DIRECTORY, "Highland #", false);
                }
            }
            if ((env_APP_MODE === "GMC-EXPLORER")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(GMC_OG_COLLECTION_DETAILS.collectionName, wallet.account, GMC_OG_COLLECTION_ASSET_DIRECTORY, "Highland #", false);
                }
            }
            if ((env_APP_MODE === "KC")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(KC_OG_COLLECTION_DETAILS.collectionName, wallet.account, KC_OG_COLLECTION_ASSET_DIRECTORY, "KittyCone #", false);
                }
            }
            if ((env_APP_MODE === "WHISKERS")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(WHISKERS_OG_COLLECTION_DETAILS.collectionName, wallet.account, WHISKERS_OG_COLLECTION_ASSET_DIRECTORY, "Whiskers #", false);
                }
            }
            if ((env_APP_MODE === "WP")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(WONDERPALS_OG_COLLECTION_DETAILS.collectionName, wallet.account, WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY, "WonderPals #", false);
                }
            }
            if ((env_APP_MODE === "MOONBIRDS-PROOF")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(PROOF_MOONBIRDS_COLLECTION_DETAILS.collectionName, wallet.account, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY, "Moonbirds #", true);
                }
            }
            if ((env_APP_MODE === "MOONBIRDS-DBS")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(PROOF_MOONBIRDS_COLLECTION_DETAILS.collectionName, wallet.account, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY, "(DBS) Moonbirds #", true);
                }
            }

            if ((env_APP_MODE === "CHUBBY")) {
                if (ownerWalletAddress === null) {
                    setOwnerWalletAddress(wallet.account);
                    openseaCollectionLookup(CHUBBY_OG_COLLECTION_DETAILS.collectionName, wallet.account, CHUBBY_OG_COLLECTION_ASSET_DIRECTORY, "ChubbyLand #", false);
                }
            }
            // if ((env_APP_MODE === "KUMO")) {
            //     if (ownerWalletAddress === null) {
            //         setOwnerWalletAddress(wallet.account);
            //         openseaCollectionLookup("goodmorningcafe", wallet.account, KUMOWORLD_COLLETION_ASSET_DIRECTORY, "Kumo Resident #", false);
            //     }
            // }
            
        } 

        if (wallet.status !== 'connected') {
            setHasUserSelectedACow(false);
            setDefaultAccount(null);
            setWalletConnected(false);
            setOwnerAvailableCows([]);
            setOwnerAvailableCowsNFT(0);
            setOwnerWalletAddress(null);
            setUserHasCow(false);

            // Generate asset for the active implementaion  
            // generateAvailableAsset();

            // Behavior when user has not connected its wallet
            // These are usually instance for which we want to update the default behavior
            // When we do not offer to the user to connect its wallet
            if ((env_APP_MODE === "MOONBIRDS-PROOF")) {
                setUserHasCow(true);
            }
            if ((env_APP_MODE === "MOONBIRDS-DBS")) {
                setUserHasCow(true);
            }

        }
        if (wallet.error?.name) {
            setErrorMessage("Can't connect to you wallet! In order for us to read your wallet information, you need to install the MetaMask extension.");
        }
        //console.log("Is wallet connected? " + walletConnected);
    }, [wallet]);





    // OPENSEA
    // *******
        
    // Looking OS Collection NFTs that belong to ownerAddress
    // ------------------------------------------------------
    
    // Function 
    // API: https://api.opensea.io/api/v1/assets?collection=goodmorningcafe&owner=

    // ForceUserHasCow - if True, we set that boolean to true. This is used to circumvent access to wallet to unlock specific access to opensea statistic page 

    function openseaCollectionLookup(collectionName, ownerAddress, assetDirectory, assetPrefix, forceUserHasCow) { 

        console.log("openseaCollectionLookup: Wallet address connected: " + ownerAddress);

        // GET request using fetch inside useEffect React hook
        fetch('https://api.opensea.io/api/v1/assets?collection=' + collectionName + '&owner=' + ownerAddress)
        .then(response => response.json())
        .then(nftArray => {

            let myCowNftCollectionArray = [];
            let availableCowsArray = [];        // Array of NFTs that can be selected and later placed on the canvas
    
            //console.log(nftArray.assets);
            let results = nftArray;
            if (results.length === 0) {

                console.log("No NFT found in user wallet. Addr=" + ownerAddress);

                if (forceUserHasCow) {
                    setUserHasCow(true);
                } else {
                    setUserHasCow(false);
                }
            } else {
                setUserHasCow(true);

                // TODO: Migrate this code to be partner specific

                if (
                       ownerAddress === "0x9EB2DD513b007646B10a019f11481F9eB69DB77c"        // Myself 
                    || ownerAddress === "0xb3457c2065fd1f384e9f05495251f2894d1659b6"        // Ben Colefax
                    ) {
                    setUserIsAdmin(true);
                } else {
                    setUserIsAdmin(false);
                }

                let n=0;
                //console.log("Parsing assets from wallet...");
                nftArray.assets.forEach(eachNFT => {
                    
                    let assetNumber = null; let assetFileName = null; let asset = null;
                    let thumbnailDirectory = null;

                    if (collectionName === GMC_OG_COLLECTION_DETAILS.collectionName) {
                        console.log("Found an asset that belong to GMC OG Collection: ", eachNFT);
                        asset = getAssetIdFromGoodMorningCafeCollectionTokenId(eachNFT.token_id);
                        assetFileName = asset.fileName;
                        assetNumber = asset.assetIndexNumber;
                    }
                    else if (collectionName === KC_OG_COLLECTION_DETAILS.collectionName) {
                        console.log("Found an asset that belong to KC OG Collection: " + eachNFT.name);
                        asset = getAssetIdFromKittyConeClassicCollectionTokenId(eachNFT.token_id);
                        assetFileName = asset.fileName;
                        assetNumber = asset.assetIndexNumber;
                    }
                    else if (collectionName === WHISKERS_OG_COLLECTION_DETAILS.collectionName) {
                        console.log("Found an asset that belong to WHISKERS Collection: " + eachNFT.name);
                        asset = getAssetIdFromWhiskersCollectionTokenId(eachNFT.token_id);
                        assetFileName = asset.fileName;
                        assetNumber = asset.assetIndexNumber;
                        thumbnailDirectory = WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS;
                    }
                    else if (collectionName === WONDERPALS_OG_COLLECTION_DETAILS.collectionName) {
                        console.log("Found an asset that belong to WONDERPAL Collection: " + eachNFT.name);
                        asset = getAssetIdFromWonderPalsCollectionTokenId(eachNFT.token_id);
                        assetFileName = asset.fileName;
                        assetNumber = asset.assetIndexNumber;
                        thumbnailDirectory = WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS;
                    }
                    else if (collectionName === PROOF_MOONBIRDS_COLLECTION_DETAILS.collectionName) {
                        console.log("Found an asset that belong to MOONBIRDS Collection: " + eachNFT.name);
                        asset = getAssetIdFromMoonbirdsCollectionTokenId(eachNFT.token_id);
                        assetFileName = asset.fileName;
                        assetNumber = asset.assetIndexNumber;
                        thumbnailDirectory = PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS;
                    }
                    else if (collectionName === CHUBBY_OG_COLLECTION_DETAILS.collectionName) {
                        console.log("Found an asset that belong to CHUBBYLAND Collection: " + eachNFT.name);
                        asset = getAssetIdFromChubbyLandNFTCollectionTokenId(eachNFT.token_id);
                        assetFileName = asset.fileName;
                        assetNumber = asset.assetIndexNumber;
                    } 
                    // else if (collectionName === "kumo-x-world-residents") {
                    //     assetId = getAssetIdFromKumoWorldCollectionTokenName(eachNFT.name);
                    // }

                    else {
                        console.error("Code not implemented for collection: " + collectionName);
                    }

                    const localFile = assetDirectory + assetFileName;
                    let localThumbnail = null;
                    if (thumbnailDirectory !== null) {
                        localThumbnail = thumbnailDirectory + assetFileName; 
                    } else {
                        localThumbnail = localFile;
                    }
                    eachNFT.image_local_file_transparent = localFile;
                    myCowNftCollectionArray.push(eachNFT);
                    // Create an array with only the information we need about the cow 
                    // customCowNumberToAddToCollectionthat belong to the user wallet 
                    availableCowsArray.push(
                        {
                            id: 'assetId' + assetNumber,
                            image_local_file_transparent: localFile,
                            image_local_file_thumbnail: localThumbnail,
                            name: assetPrefix + assetNumber,
                            scale: 1,              // -1 to flip the cow
                            addedToCanvas: false  // Allow to only add the cow once to the canvas
                        }
                    );
                })
                setOwnerAvailableCowsNFT(availableCowsArray.length);

                // Add one more cow for people that want more cows
                // With placeholders cows
                if (collectionName === "goodmorningcafe") {
                    // Unavailable cow
                    const localFile = GMC_OG_COLLECTION_ASSET_DIRECTORY + "HL_needCow.png";
                    availableCowsArray.push(
                        {
                            id: 'assetId' + availableCowsArray.length,
                            image_local_file_transparent: null,         // Set to null as we do not want to add this image to the canvas
                            image_local_file_thumbnail: localFile,
                            name: "Need more? Check the collection on Opensea!",
                            scale: 1,            // -1 to flip the cow
                            addedToCanvas: true  // Allow to only add the cow once to the canvas
                        }
                    );
                }

                //console.log("NOW myCowNftCollectionArray: ", myCowNftCollectionArray);
                //console.log("NOW availableCowsArray: ", availableCowsArray);
                setOpenseaCollectionData(myCowNftCollectionArray);
                setOwnerAvailableCows(availableCowsArray);
            }
        })
        .catch(err => console.error(err))

    };





    return (

        <div className="gmcApp container-fluid d-flex flex-column vh-100 mh-100 overflow-hidden">
    
            {/* APP HEADER */}
            <div className="row flex-shrink-1">
                {(env_APP_MODE === "DEMO") && ( <Generic_Header /> )}
                {(env_APP_MODE === "GMC") && ( <GMC_Header /> )}
                {(env_APP_MODE === "GMC-EXPLORER") && ( <GMC_HeaderExplorer /> )}
                {(env_APP_MODE === "KC") && ( <KC_Header /> )}
                {(env_APP_MODE === "WHISKERS") && ( <WHISKERS_Header /> )}
                {(env_APP_MODE === "WP") && ( <WP_Header /> )}
                {(env_APP_MODE === "MOONBIRDS-PROOF") && ( <MOONBIRDSPROOF_Header /> )}
                {(env_APP_MODE === "MOONBIRDS-DBS") && ( <MOONBIRDSDBS_Header /> )}
                {(env_APP_MODE === "CHUBBY") && ( <CHUBBY_Header /> )}  
                {/* {(env_APP_MODE === "KUMO") && ( <KUMO_Header /> )} */}
                
                {/* This is for all level errors (like Metamask not installed) */}
                { errorMessage && 
                    <Container>
                        <div className="m-2 alert alert-danger">{errorMessage}</div>    
                    </Container>
                }
            </div>

            {/*OPENSEA STATS COLLECTION PAGE */}
            {selectedNavItem.includes("STATS_COLLECTION_") && 

                <div className="row flex-grow-1 overflow-hidden">
                    <div id="col-left" className="col-12 mh-100 overflow-auto">

                        <OpenseaStats />

                    </div>
                </div>

            }

            {/* APP EXPLORER COLLECTION PAGE */}
            {selectedNavItem.includes("EXPLORER_COLLECTION_") &&  

                        <>
                        {(env_APP_MODE === "GMC-EXPLORER") && (
                            <CollectionExplorer 
                            collectionMetadataAssets={GMC_OG_COLLECTION_METADATA_ASSETS}
                            collectionMetadataTraits={GMC_OG_COLLECTION_METADATA_TRAITS}
                            collectionAssetsRootDirectory={GMC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND}
                            collectionName={GMC_OG_COLLECTION_DETAILS.collectionName}
                            />
                        )}
 
                        {(env_APP_MODE === "KC") && (
                            <CollectionExplorer 
                            collectionMetadataAssets={KC_OG_COLLECTION_METADATA_ASSETS}
                            collectionMetadataTraits={KC_OG_COLLECTION_METADATA_TRAITS}
                            collectionAssetsRootDirectory={KC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND}
                            collectionName={KC_OG_COLLECTION_DETAILS.collectionName}
                            />
                        )}

                        {(env_APP_MODE === "KC5K") && (
                            <CollectionExplorer 
                            collectionMetadataAssets={KC_5K_COLLECTION_METADATA_ASSETS}
                            collectionMetadataTraits={KC_5K_COLLECTION_METADATA_TRAITS}
                            collectionAssetsRootDirectory={KC_5K_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND}
                            collectionName={KC_5K_COLLECTION_DETAILS.collectionName}
                            />
                        )}
                        </>
   
            }

            {/* APP BACKGROUND GENERATOR PAGE */}
            {(selectedNavItem === "BACKGROUND_GENERATOR_NOT_GATED") && 
                <>
                <div className="row flex-grow-1 overflow-hidden">
                    <div className="mh-100 overflow-auto">
                        {(env_APP_MODE === "DEMO") && <DEMO_Backgroundgenerator />}
                        {(env_APP_MODE === "GMC") && <GMC_Backgroundgenerator />}
                        {(env_APP_MODE === "KC") && <KC_Backgroundgenerator />}
                        {(env_APP_MODE === "WHISKERS") && <WHISKERS_Backgroundgenerator /> }
                        {(env_APP_MODE === "WP") && <WP_Backgroundgenerator /> }
                        {(env_APP_MODE === "MOONBIRDS-PROOF") && <MOONBIRDSPROOF_Backgroundgenerator /> }
                        {(env_APP_MODE === "MOONBIRDS-DBS") && <MOONBIRDSDBS_Backgroundgenerator /> }
                        {(env_APP_MODE === "CHUBBY") && <CHUBBY_Backgroundgenerator />}
                        {/* {(env_APP_MODE === "KUMO") && <KUMO_Backgroundgenerator />}  */}
                     </div>
                </div>
                </>
            }

            {/* APP FOOTER */}
            <div className="row flex-shrink-1">
                {(env_APP_MODE === "DEMO") && ( <Generic_Footer /> )}
                {(env_APP_MODE === "GMC") && ( <GMC_Footer /> )}
                {(env_APP_MODE === "GMC-EXPLORER") && ( <GMC_Footer /> )}
                {(env_APP_MODE === "KC") && ( <KC_Footer /> )}
                {(env_APP_MODE === "WHISKERS") && ( <WHISKERS_Footer /> )}
                {(env_APP_MODE === "WP") && ( <WP_Footer /> )}
                {(env_APP_MODE === "MOONBIRDS-PROOF") && ( <MOONBIRDSPROOF_Footer /> )}
                {(env_APP_MODE === "MOONBIRDS-DBS") && ( <MOONBIRDSDBS_Footer /> )}
                {(env_APP_MODE === "CHUBBY") && ( <CHUBBY_Footer /> )}
                {/* {(env_APP_MODE === "KUMO") && ( <KUMO_Footer /> )}  */}
            </div>
    </div>

    )

}

// Wrap everything in <UseWalletProvider />
export default () => (
    <UseWalletProvider
        autoConnect
        connectors={{
            injected: {
                //allows you to connect to mainnet within Metamask.
                chainId: [1],
            },
            walletlink: {
                chainId: 1,
                url: 'https://mainnet.eth.aragon.network/',
            },
            walletconnect: {
                rpc: {
                  1: 'https://mainnet.infura.io/v3/a0d8c94ba9a946daa5ee149e52fa5ff1'
                },
                bridge: 'https://bridge.walletconnect.org',
                pollingInterval: 12000,
              },
        }}>
        <Application />
    </UseWalletProvider>
)