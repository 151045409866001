
// App Components
import BackgroundGenerator from '../../components/bannerGenerator/BackgroundGenerator.js';

// Use-wallet for account management 
import { useWallet } from 'use-wallet';

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "../../shared/NavigationPageGlobalState";
import { useSharedDataManipulationFunctions } from "../../shared/DataManipulationFunctions";
import { useSharedCollectionsMetadata } from "../CollectionsMetadata";






// ------------------------
// BACKGROUND CUSTOMISATION
// ------------------------

const BackgroundGeneratorConfig = (props) => {

    // Handle Metamask connection 
    const wallet = useWallet();
    const activate = (connector) => wallet.connect(connector);
    const deActivate = () => wallet.reset();

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        isSelectedCowOnCanvas,                                  // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        ownerAvailableCows,
        generateAvailableAsset,
    } = useSharedNavigationPageGlobalState();    
    // Collections metadata
    const { 
        WONDERPALS_OG_COLLECTION_DETAILS,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_METADATA_ASSETS,
        WONDERPALS_OG_COLLECTION_METADATA_TRAITS,
        WONDERPALS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        WONDERPALS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllWonderPalsAssets,
        generateAllKittyConesClassicAssets,
        generateAllGoodMorningCafeAssets
    } = useSharedCollectionsMetadata();
    // Data Manipulation
    const {
        performGenericAssetSearchFromQueryString
    } = useSharedDataManipulationFunctions();
    // Retrieve items from context --------------------------------------------------

    

    // Backgrounds for Collection
    // **************************

    function generateBackgroundItemsForCollection() {
        
        let imageArray = [];
        let tempArray = [];
        
        let filePrefixDir = WONDERPALS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY;

        // [] Official backgorunds 
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Background #1",  
                src:filePrefixDir + "WP_4.jpg", 
                width: 2500, height: 850, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Background #2",  
                src:filePrefixDir + "WP_5.jpg", 
                width: 2500, height: 850, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Background #3",  
                src:filePrefixDir + "WP_6.jpg", 
                width: 2500, height: 850, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Background #4",  
                src:filePrefixDir + "WP_7.jpg", 
                width: 2500, height: 850, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Wonderpals Background #5",  
                src:filePrefixDir + "WP_8.jpg", 
                width: 2500, height: 850, x:0, y:0}]
        );

        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #1",  
                src:filePrefixDir + "comic_BLUE1.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #2",  
                src:filePrefixDir + "comic_BLUE2.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #3",  
                src:filePrefixDir + "comic_BLUE4.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #4",  
                src:filePrefixDir + "comic_GREEN1.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #5",  
                src:filePrefixDir + "comic_GREEN2.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #6",  
                src:filePrefixDir + "comic_GREEN4.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #7",  
                src:filePrefixDir + "comic_YELLOW1.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #8",  
                src:filePrefixDir + "comic_YELLOW2.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "WonderPals Comic #9",  
                src:filePrefixDir + "comic_YELLOW4.jpg", 
                width: 3600, height: 3600, x:0, y:0}]
        );

        // EMPTY TEMPLATES
        // ---------------

        filePrefixDir = WONDERPALS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY;

        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Twitter banner", 
            backgroundColor: "#fbf9f2",  
            src:filePrefixDir + "tpl_twitter_banner.png", 
            width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Instagram Post",
            backgroundColor: "#fbf9f2",    
            src:filePrefixDir + "tpl_instagram_post.png", 
            width: 1080, height: 1080, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone X", 
            backgroundColor: "#fbf9f2",  
            src:filePrefixDir + "tpl_iphoneX.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XR", 
            backgroundColor: "#fbf9f2",  
            src:filePrefixDir + "tpl_iphoneXR.png", 
            width: 828, height: 1792, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XS", 
            backgroundColor: "#fbf9f2",  
            src:filePrefixDir + "tpl_iphoneXS.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Google Pixel 3", 
            backgroundColor: "#fbf9f2",  
            src:filePrefixDir + "tpl_pixel3.png", 
            width: 1080, height: 2160, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Samsung Note 10", 
            backgroundColor: "#fbf9f2",  
            src:filePrefixDir + "tpl_samsungNote10.png", 
            width: 1080, height: 2280, x:0, y:0}]
        );

        return imageArray;

    };





    // -------------------------
    // ACCESSORIES CUSTOMISATION
    // -------------------------

    // Pals Props
    // ***********

    function generatePalPropsForCollection() {

        let availableAssets = [];
        let itemId = 0;

        const filePrefixDir = WONDERPALS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY + "pal_props/";

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "apple.png",
                image_local_file_thumbnail: filePrefixDir + "apple.png",
                name: "Pal Prop #1",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 497, height: 517, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame520.png",
                image_local_file_thumbnail: filePrefixDir + "Frame520.png",
                name: "Pal Prop #2",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1292, height: 1276, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame521.png",
                image_local_file_thumbnail: filePrefixDir + "Frame521.png",
                name: "Pal Prop #3",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1167, height: 1203, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame537.png",
                image_local_file_thumbnail: filePrefixDir + "Frame537.png",
                name: "Pal Prop #5",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1303, height: 1203, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame538.png",
                image_local_file_thumbnail: filePrefixDir + "Frame538.png",
                name: "Pal Prop #5",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1201, height: 1188, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame539.png",
                image_local_file_thumbnail: filePrefixDir + "Frame539.png",
                name: "Pal Prop #6",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1352, height: 1253, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame540.png",
                image_local_file_thumbnail: filePrefixDir + "Frame540.png",
                name: "Pal Prop #7",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1318, height: 1222, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Frame543.png",
                image_local_file_thumbnail: filePrefixDir + "Frame543.png",
                name: "Pal Prop #8",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 989, height: 1106, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group75-1.png",
                image_local_file_thumbnail: filePrefixDir + "Group75-1.png",
                name: "Pal Prop #9",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 647, height: 644, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group75-2.png",
                image_local_file_thumbnail: filePrefixDir + "Group75-2.png",
                name: "Pal Prop #10",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 647, height: 644, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group75.png",
                image_local_file_thumbnail: filePrefixDir + "Group75.png",
                name: "Pal Prop #11",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 647, height: 644, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group92.png",
                image_local_file_thumbnail: filePrefixDir + "Group92.png",
                name: "Pal Prop #12",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 630, height: 603, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group209.png",
                image_local_file_thumbnail: filePrefixDir + "Group209.png",
                name: "Pal Prop #13",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 717, height: 553, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group280.png",
                image_local_file_thumbnail: filePrefixDir + "Group280.png",
                name: "Pal Prop #14",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 708, height: 720, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group281.png",
                image_local_file_thumbnail: filePrefixDir + "Group281.png",
                name: "Pal Prop #15",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 551, height: 406, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group370.png",
                image_local_file_thumbnail: filePrefixDir + "Group370.png",
                name: "Pal Prop #16",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 499, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group606.png",
                image_local_file_thumbnail: filePrefixDir + "Group606.png",
                name: "Pal Prop #17",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1430, height: 1199, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group607.png",
                image_local_file_thumbnail: filePrefixDir + "Group607.png",
                name: "Pal Prop #18",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1317, height: 1263, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group609.png",
                image_local_file_thumbnail: filePrefixDir + "Group609.png",
                name: "Pal Prop #19",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1374, height: 1043, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group611.png",
                image_local_file_thumbnail: filePrefixDir + "Group611.png",
                name: "Pal Prop #20",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1351, height: 1099, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group612.png",
                image_local_file_thumbnail: filePrefixDir + "Group612.png",
                name: "Pal Prop #21",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1219, height: 1170, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group613.png",
                image_local_file_thumbnail: filePrefixDir + "Group613.png",
                name: "Pal Prop #22",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 1255, height: 1205, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group761.png",
                image_local_file_thumbnail: filePrefixDir + "Group761.png",
                name: "Pal Prop #23",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 692, height: 601, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group762.png",
                image_local_file_thumbnail: filePrefixDir + "Group762.png",
                name: "Pal Prop #24",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 692, height: 601, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group763.png",
                image_local_file_thumbnail: filePrefixDir + "Group763.png",
                name: "Pal Prop #25",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 692, height: 601, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group764.png",
                image_local_file_thumbnail: filePrefixDir + "Group764.png",
                name: "Pal Prop #26",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 692, height: 601, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "Group762.png",
                image_local_file_thumbnail: filePrefixDir + "Group762.png",
                name: "Pal Prop #27",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 468, height: 391, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "peach.png",
                image_local_file_thumbnail: filePrefixDir + "peach.png",
                name: "Pal Prop #28",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 633, height: 659, x:0, y:0
            }
        );

        return availableAssets;

    };

    // Storytelling Props
    // ******************

    function generateStoryTellingPropsForCollection() {
        let availableAssets = [];
        let itemId = 0;

        const filePrefixDir = WONDERPALS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY + "storytelling/";

        // BUBBLEs
        // -------
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BUBBLE_BIG_LEFT.png",
                image_local_file_thumbnail: filePrefixDir + "BUBBLE_BIG_LEFT.png",
                name: "Storytelling #1",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 702, height: 536, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BUBBLE_BIG_RIGHT.png",
                image_local_file_thumbnail: filePrefixDir + "BUBBLE_BIG_RIGHT.png",
                name: "Storytelling #2",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 702, height: 536, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BUBBLE_SMALL_LEFT.png",
                image_local_file_thumbnail: filePrefixDir + "BUBBLE_SMALL_LEFT.png",
                name: "Storytelling #3",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 419, height: 284, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BUBBLE_SMALL_RIGHT.png",
                image_local_file_thumbnail: filePrefixDir + "BUBBLE_SMALL_RIGHT.png",
                name: "Storytelling #4",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 419, height: 284, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "THOUGHT_BUBBLE.png",
                image_local_file_thumbnail: filePrefixDir + "THOUGHT_BUBBLE.png",
                name: "Storytelling #5",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 815, height: 765, x:0, y:0
            }
        );

        // PALHEADs
        // --------
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BLUE_PALHEAD.png",
                image_local_file_thumbnail: filePrefixDir + "BLUE_PALHEAD.png",
                name: "Storytelling #6",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 382, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GREEN_PALHEAD.png",
                image_local_file_thumbnail: filePrefixDir + "GREEN_PALHEAD.png",
                name: "Storytelling #7",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 382, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "MINT_PALHEAD.png",
                image_local_file_thumbnail: filePrefixDir + "MINT_PALHEAD.png",
                name: "Storytelling #8",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 382, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "ORANGE_PALHEAD.png",
                image_local_file_thumbnail: filePrefixDir + "ORANGE_PALHEAD.png",
                name: "Storytelling #9",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 382, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "PURPLE_PALHEAD.png",
                image_local_file_thumbnail: filePrefixDir + "PURPLE_PALHEAD.png",
                name: "Storytelling #10",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 382, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "YELLOW_PALHEAD.png",
                image_local_file_thumbnail: filePrefixDir + "YELLOW_PALHEAD.png",
                name: "Storytelling #11",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 439, height: 382, x:0, y:0
            }
        );


        // EYEs
        // ----
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "ANGRY_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "ANGRY_EYES.png",
                name: "Storytelling #12",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 229, height: 70, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BIG_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "BIG_EYES.png",
                name: "Storytelling #13",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 312, height: 105, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "HEART_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "HEART_EYES.png",
                name: "Storytelling #14",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 330, height: 105, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "REGULAR_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "REGULAR_EYES.png",
                name: "Storytelling #15",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 211, height: 27, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SPARKLE_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "SPARKLE_EYES.png",
                name: "Storytelling #16",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 367, height: 141, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SPIRAL_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "SPIRAL_EYES.png",
                name: "Storytelling #17",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 361, height: 139, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "X_EYES.png",
                image_local_file_thumbnail: filePrefixDir + "X_EYES.png",
                name: "Storytelling #18",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 272, height: 75, x:0, y:0
            }
        );

        // OTHERS
        // ------
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "BIRTHDAY_HAT.png",
                image_local_file_thumbnail: filePrefixDir + "BIRTHDAY_HAT.png",
                name: "Storytelling #19",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 206, height: 244, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "EYE_ROLL.png",
                image_local_file_thumbnail: filePrefixDir + "EYE_ROLL.png",
                name: "Storytelling #20",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 329, height: 118, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "FANGS.png",
                image_local_file_thumbnail: filePrefixDir + "FANGS.png",
                name: "Storytelling #21",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 137, height: 58, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "FIRE.png",
                image_local_file_thumbnail: filePrefixDir + "FIRE.png",
                name: "Storytelling #22",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 241, height: 233, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "HALO.png",
                image_local_file_thumbnail: filePrefixDir + "HALO.png",
                name: "Storytelling #23",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 216, height: 109, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "HEART_GLASSES.png",
                image_local_file_thumbnail: filePrefixDir + "HEART_GLASSES.png",
                name: "Storytelling #24",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 380, height: 132, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "HEART.png",
                image_local_file_thumbnail: filePrefixDir + "HEART.png",
                name: "Storytelling #25",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 149, height: 124, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "little_cap.png",
                image_local_file_thumbnail: filePrefixDir + "little_cap.png",
                name: "Storytelling #26",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 223, height: 176, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "NEUTRAL.png",
                image_local_file_thumbnail: filePrefixDir + "NEUTRAL.png",
                name: "Storytelling #27",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 104, height: 18, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "RED_BOW.png",
                image_local_file_thumbnail: filePrefixDir + "RED_BOW.png",
                name: "Storytelling #28",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 285, height: 231, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SMILE.png",
                image_local_file_thumbnail: filePrefixDir + "SMILE.png",
                name: "Storytelling #29",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 119, height: 33, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SPROUT.png",
                image_local_file_thumbnail: filePrefixDir + "SPROUT.png",
                name: "Storytelling #30",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 229, height: 158, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SQUIGGLE.png",
                image_local_file_thumbnail: filePrefixDir + "SQUIGGLE.png",
                name: "Storytelling #31",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 114, height: 30, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SUNGLASSES.png",
                image_local_file_thumbnail: filePrefixDir + "SUNGLASSES.png",
                name: "Storytelling #32",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 354, height: 135, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "SURPRISE.png",
                image_local_file_thumbnail: filePrefixDir + "SURPRISE.png",
                name: "Storytelling #33",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 81, height: 94, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "TEAR.png",
                image_local_file_thumbnail: filePrefixDir + "TEAR.png",
                name: "Storytelling #34",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 76, height: 99, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "TONGUE.png",
                image_local_file_thumbnail: filePrefixDir + "TONGUE.png",
                name: "Storytelling #35",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 121, height: 70, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "UGH.png",
                image_local_file_thumbnail: filePrefixDir + "UGH.png",
                name: "Storytelling #36",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 120, height: 75, x:0, y:0
            }
        );

        return availableAssets;
    };



    // Search for an asset based on either a number of 
    // based on some trait 
    function assetSearch(queryString, assetList) {
        return performGenericAssetSearchFromQueryString(WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY, queryString, assetList, WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }

    return (

        <BackgroundGenerator 
        
            // Wallet connector
            wallet={wallet}
            walletConnect={activate}
            walletDisconnect={deActivate}

            // List of available asset to select 
            collectionAssets={generateAllWonderPalsAssets}                                              // We should pass functions, not results 
            myAssets={ownerAvailableCows} 
            isCowOwner={userHasCow}

            // Global variable to track if a cow is selected on the canvas
            isSelectedCowOnCanvas={isSelectedCowOnCanvas}

            // List of available partner asset to select 
            partnerAssets={[() => { return [] }, () => { return [] } ]}      // We should pass functions, not results 
            // List of available background to select 
            availableBackground={generateBackgroundItemsForCollection()}
            availableAccessories1={generatePalPropsForCollection()}
            availableAccessories2={generateStoryTellingPropsForCollection()}

            allowAssetReRollConfig={
                {   
                    collection: true,
                    partner1: true,
                    partner2: true
                }
            }

            // Generator behavior's config flags
            allowCheckUserWallet = {true}           // Do we want to lookup the user wallet to search its asset?
            allowAssetSelection={true}              // Do we want the user to pick a specific collection asset? (ie. search)
            allowAssetUploadAsAssets={true}         // Do we want the user to upload custom assets?
            allowAssetUploadAsBackground={true}     // Do we want the user to upload custom background?

            assetSearchFunction={assetSearch}
            assetCollectionMetadata={WONDERPALS_OG_COLLECTION_METADATA_ASSETS}                          // Metadata for the collection

            // Configure which panels to display
            // Collection assets + Collection backgrouns are mandatory 
            displayItemConfig={
                {
                    // Value are "always" for always
                    //           "walletOnly" for only when user is connect and has assetDirectory
                    //            "never" for never
                    partner1: "never",
                    partner2: "never",
                    accessories1: "always",
                    accessories2: "always"
                }
            }

            // Display Items legend (below each asset, background, accessories)
            displayItemLegendConfig={
                {
                    assets: true,
                    partner1: true,
                    partner2: true,
                    background: false,
                    template: true,
                    uploads: true,
                    accessories1: false,
                    accessories2: false
                }
            }

            // Configure labels 
            labelsConfig={
                { 
                    nftYouOwnTitle: 'WonderPal(s) You Own',                 // Module title on the left hand side (When wallet is connected)
                    
                    availableItems: 'Assets - Click or Drag!',              //         
                    allAssetTitlePannel: "WonderPals",                      // Asset panel title (all collections items)
                    myAssetTitlePannel: "My WonderPals",                    // Asset panel title (my items)
                    partner1TitlePannel: "Partner Assets (Kitty Cones)",    // (Partner) Asset panel 1 title
                    partner2TitlePannel: "Partner Assets (GMC)",            // (Partner) Asset panel 2 title

                    accessories1TitlePannel: "Pal Props",       // Accessories panel 1 title 
                    accessories1MessageToConnectWallet:                     // Message to let user connect wallet to unlock this panel (if wallet gated)
                        "You can unlock tasty food items from the collection if you own at least one WonderPal NFT.",
                    accessories1MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any WonderPal NFT in the wallet you connected to let you unlock this section...",   
                    accessories2TitlePannel: "Storytelling",                // Accessories panel 2 title 
                    accessories2MessageToConnectWallet:                     // Message to let user connect wallet to unlock accessories module 1 (if wallet gated)
                        "You can unlock exclusive items from the collection if you own at least one WonderPal NFT.",
                    accessories2MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any WonderPal NFT in the wallet you connected to let you unlock this section...", 

                    yourUploadedAsset: "Upload your own asset",              // Upload your own asset panel title 
                    
                    availableBackgrounds: 'Available backgrounds',          // 
                    officialBackgrounds: 'Official backgrounds',            // Available background panel title 
                    templateBackgrounds: 'Template backgrounds',            // Available template panel title 
                    yourUploadedBackgrounds: 'Upload your own',             // Upload your own background panel title 

                    defaultTextAddedToCanvas: 
                            'text box',                                     // Default text being added to canvas 
                    
                    okButton: "OK",                                         // OK Button
                    collectionShortName: "WP",                              // Collection short name
                    assetName: "WonderPal",                                 // Label to describe an asset
                    assetSearchButtonText: "Pal Search" ,            // Search button to lookup new asset  
                    assetSearchLabelText: "Looking for a specific WonderPal? Search by number/properties" ,    
                                                                            // Search button to lookup new asset 
                    partner1AssetSearchButtonText: "Partners 1 Search",     // Search button to lookup partner 1 asset   
                                                                            // Search label to lookup partner 1 asset  
                    partner1AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",
                    partner2AssetSearchButtonText: "Partner 2 Search",      // Search button to lookup partner 2 asset   
                                                                            // Search label to lookup partner 2 asset  
                    partner2AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",  
                     

                    instructionsTitle: "WonderPals Banner Maker!",          // Title of the instructions popup

                    paletteColor1Text: "Black",
                    paletteColor1HexValue: "#2c2b28", 
                    paletteColor2Text: "Navy",
                    paletteColor2HexValue: "#2f5b83",
                    paletteColor3Text: "Green",
                    paletteColor3HexValue: "#498b77",
                    paletteColor4Text: "Orange",
                    paletteColor4HexValue: "#dfa145", 
                    paletteColor5Text: "Beige",
                    paletteColor5HexValue: "#fbf9f2",
                    // paletteColor6Text: "Black",
                    // paletteColor6HexValue: "#000000",  
                    // paletteColor7Text: "White",
                    // paletteColor7HexValue: "#ffffff",

                    templateColor1Text: "Beige",
                    templateColor1HexValue: "#fbf9f2", 
                    templateColor2Text: "Orange",
                    templateColor2HexValue: "#f8dfa0",
                    templateColor3Text: "Green",
                    templateColor3HexValue: "#dde4b6",
                    templateColor4Text: "Light Teal",
                    templateColor4HexValue: "#eef8f5", 
                    templateColor5Text: "Light Blue",
                    templateColor5HexValue: "#dae7f8",
                    // templateColor6Text: "Black",
                    // templateColor6HexValue: "#000000",  
                    // templateColor7Text: "White",
                    // templateColor7HexValue: "#ffffff",
                }
            }
    
    />

    )

}

export default BackgroundGeneratorConfig;