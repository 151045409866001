// WP
import wonderPalsHeaderLogo from "./assets/WP_Header_Logo.png"
import wonderPalsBannerLogo from "./assets/WP_Banner_Logo.png"

const Header = (props) => {

    return (

    <>

        <header className="container-fluid header">
            
            {/* Storefront row */}
            {/* <Row className="d-flex justify-content-center">
                <img className="d-none d-md-block" src={wonderPalsFrontBanner} 
                    alt="Wonderpals Front Banner" 
                    height={200}
                    />
            </Row> */}
            <div className="d-md-flex justify-content-between align-items-center mt-2 mb-2 mx-5">
                
                <div className="ml-2">
                    <a href="#" onClick={() => window.open('https://www.wonderpals.com')}>
                        <img src={wonderPalsHeaderLogo} height={80} alt="Wonderpals Logo" />
                    </a>    
                </div>

                <div className="m-2">
                    <img height={35} src={wonderPalsBannerLogo} alt="Wonderpals Header" />
                </div> 

                <div className="mr-2">
                    {/* <a href="#" onClick={handleModalShow}>About</a> */}
                </div>

            </div>
 
        </header>

    </>

    );

}

export default Header;