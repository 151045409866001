import { useBetween } from "use-between";

// Context modules
import { useSharedCollectionsMetadata } from "../partner/CollectionsMetadata.js";

// Toast management 
import { toast } from 'react-toastify';

const useDataManipulationFunctions = () => {

    // Retrieve items from context --------------------------------------------------
    // Collections metadata
    const { 
        GMC_OG_COLLECTION_METADATA_ASSETS,
        KC_OG_COLLECTION_METADATA_ASSETS,
        CHUBBY_OG_COLLECTION_METADATA_ASSETS,
        WONDERPALS_OG_COLLECTION_METADATA_ASSETS,
        WHISKERS_OG_COLLECTION_METADATA_ASSETS,
        PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS
    } = useSharedCollectionsMetadata();
    // Retrieve items from context --------------------------------------------------

    // PRIVATE FUNCTIONS

    // Function that return the cow number from a list name
    // Two possible listing name
    // Generic - Highland #123
    // Custom  - Highland #306 - Mooku
    function getAssetIdFromGoodMorningCafeCollectionTokenId(tokenId) {
        const assetList = GMC_OG_COLLECTION_METADATA_ASSETS;

        for (var i=0; i<assetList.length; i++) {
            const item = assetList[i];
            if (item.tokenId === tokenId) {
                //console.log("We found a match", item);
                return item; 
            }
        }
        // Could not find a match
        //console.log("getAssetIdFromGoodMorningCafeCollectionTokenId: NO MATCH");
        return null; 
    }

    // Function that return the kitty code index from the tokenId
    // This is used to get a unique index from 0...99 when processing opensea data
    function getAssetIdFromKittyConeClassicCollectionTokenId(tokenId) {
        const assetList = KC_OG_COLLECTION_METADATA_ASSETS;

        for (var i=0; i<assetList.length; i++) {
            const item = assetList[i];
            if (item.tokenId === tokenId) {
                //console.log("We found a match", item);
                return item; 
            }
        }
        // Could not find a match
        //console.log("getAssetIdFromKittyConeClassicCollectionTokenId: NO MATCH");
        return null; 
    }

    // Function that return the chubby land index from the tokenId
    // This is used to get a unique index from 0...99 when processing opensea data
    function getAssetIdFromChubbyLandNFTCollectionTokenId(tokenId) {
        const assetList = CHUBBY_OG_COLLECTION_METADATA_ASSETS;
        for (var i=0; i<assetList.length; i++) {
            const item = assetList[i];
            if (item.tokenId === tokenId) {
                //console.log("We found a match", item);
                return item; 
            }
        }
        // Could not find a match
        //console.log("getAssetIdFromChubbyLandNFTCollectionTokenId: NO MATCH");
        return null; 
    }

    // Function that return the wonderpal index from the tokenId
    // This is used to get a unique index from 0...99 when processing opensea data
    function getAssetIdFromWonderPalsCollectionTokenId(tokenId) {
        const assetList = WONDERPALS_OG_COLLECTION_METADATA_ASSETS;
        for (var i=0; i<assetList.length; i++) {
            const item = assetList[i];
            if (item.tokenId === tokenId) {
                //console.log("We found a match", item);
                return item; 
            }
        }
        // Could not find a match
        //console.log("getAssetIdFromWonderPalsCollectionTokenId: NO MATCH");
        return null; 
    }

    // Function that return the whiskers index from the tokenId
    // This is used to get a unique index from 0...99 when processing opensea data
    function getAssetIdFromWhiskersCollectionTokenId(tokenId) {
        const assetList = WHISKERS_OG_COLLECTION_METADATA_ASSETS;
        for (var i=0; i<assetList.length; i++) {
            const item = assetList[i];
            if (item.tokenId === tokenId) {
                //console.log("We found a match", item);
                return item; 
            }
        }
        // Could not find a match
        //console.log("getAssetIdFromWhiskersCollectionTokenId: NO MATCH");
        return null; 
    }

    // Function that return the moonbirds index from the tokenId
    // This is used to get a unique index from 0...99 when processing opensea data
    function getAssetIdFromMoonbirdsCollectionTokenId(tokenId) {
        const assetList = PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS;
        for (var i=0; i<assetList.length; i++) {
            const item = assetList[i];
            if (item.tokenId === tokenId) {
                //console.log("We found a match", item);
                return item; 
            }
        }
        // Could not find a match
        //console.log("getAssetIdFromMoonbirdsCollectionTokenId: NO MATCH");
        return null; 
    }

    // Function that return the kumo world index from the tokenId
    // This is used to get a unique index from 0...99 when processing opensea data
    function getAssetIdFromKumoWorldCollectionTokenName(listingName) {
        const firstPos = listingName.lastIndexOf("#") +1;       // Position of "#" sign
        let itemNumber = listingName.substr(firstPos);           // Number after "#" sign
        //console.log("Listing: " + listingName + " itemNumber=" + itemNumber); 
        return itemNumber; 
    }



    /**
     * Lookup a specific set of asset based on a query string
     * 
     * @param {*} collectionFileDirectory the directory where the assets are located 
     * @param {*} queryString the query string to lookup 
     * @param {*} metadataArray the metadata array for that collection 
     * @param {*} thumbnailAssetDirectory an optional directory for the thumbnail version fo the asset
     * @returns Either NULL if nothing has been found or an array with the search results 
     */
    function performGenericAssetSearchFromQueryString(collectionFileDirectory, queryString, metadataArray, thumbnailAssetDirectory = null) {

        if (metadataArray == null) {
            toast.error("We do not have the required metadata to search through the assets' properties for this collection!");
            return null;
        }

        if (queryString === null || queryString === "" || queryString === " ") {
            toast.error("Please enter a valid search query");
            return null;
        }

        let item = null;

        // TODO: Detect digit instead 
        if ((queryString >0 && queryString <=9999999)) {

            //console.log("Looking up by number...");
            
            //search the item from the list
            for (var i=0; i<metadataArray.length; i++) {
                if (metadataArray[i].assetIndexNumber == queryString) {
                    item = metadataArray[i];
                    break;
                }
                
            }
            
            if (item !== null) {
                const localFile = collectionFileDirectory + item.fileName;
                let localThumbnail = null;
                if (thumbnailAssetDirectory !== null) {
                    localThumbnail = thumbnailAssetDirectory + item.fileName; 
                } else {
                    localThumbnail = localFile;
                }

                // RETURN RESULT
                return [{
                    id: 'itemId' + queryString,
                    image_local_file_transparent: localFile,
                    image_local_file_thumbnail: localThumbnail,
                    name: item.name,
                    scale: 1,             // -1 to flip the cow
                    addedToCanvas: false  // Allow to only add the cow once to the canvas
                }]

            } else {
                toast.error("We have not find any matching asset for your search query: '" + queryString + "'. Wanna try something different?");
                return null;
            }

        } else {

            //console.log("Looking up by trait...");
            
            const result = [];
            for (var i=0; i<metadataArray.length; i++) {
                const item = metadataArray[i];
                const propertyList = item.properties;
                // properties: {
                //     "background"    : "Purple",
                //     "colour"        : "White",
                //     "mood"          : "Basic",
                //     "object"        : "None",
                //     "type"          : "Highland Cow/Bull"
                // } 

                let match = false;
                
                // We get the JSON object of the propertyList (which is the first element of the array)
                // And parse through each value to see if they match what we are looking for 
                const jsonObject = propertyList[0];
                Object.keys(jsonObject).forEach(function(key) {
                    const propertyValue = jsonObject[key].toLowerCase();
                    const searchValue = queryString.toLowerCase();
                    if (propertyValue.includes(searchValue)) {
                        //console.log("[traitLookup] Match queryString=" + (i+1) + " on textToSearchArray=" + propertyValue + " Text=" + searchValue);
                        //console.log(jsonObject[key])
                        match = true;
                    } else {
                        //console.log("[traitLookup] No match on:" + propertyValue);
                    }
                    
                });

                if (match) {
                    //console.log("We found a match", item);
                    const localFile = collectionFileDirectory + item.fileName;
                    let localThumbnail = null;
                    if (thumbnailAssetDirectory !== null) {
                        localThumbnail = thumbnailAssetDirectory + item.fileName; 
                    } else {
                        localThumbnail = localFile;
                    }

                    // RETURN RESULT  
                    result.push ({
                        id: 'itemId' + item.assetIndexNumber,
                        image_local_file_transparent: localFile,
                        image_local_file_thumbnail: localThumbnail,
                        name: item.name,
                        scale: 1,             // -1 to flip the cow
                        addedToCanvas: false  // Allow to only add the cow once to the canvas
                    });
                }
            }



            //console.log("Looking up by asset name...");

            for (var i=0; i<metadataArray.length; i++) {
                const item = metadataArray[i];
                const assetName = item.name.toLowerCase();
                let match = false;
                const searchValue = queryString.toLowerCase();
                    
                if (assetName.includes(searchValue)) {
                    //console.log("[assetNamelookup] Match queryString=" + (i+1) + " on textToSearchArray=" + assetName + " Text=" + searchValue);
                    //console.log(jsonObject[key])
                    match = true;
                } else {
                    //console.log("[assetNamelookup] No match on:" + assetName);
                }

                if (match) {
                    //console.log("We found a match", item);
                    const localFile = collectionFileDirectory + item.fileName;
                    let localThumbnail = null;
                    if (thumbnailAssetDirectory !== null) {
                        localThumbnail = thumbnailAssetDirectory + item.fileName; 
                    } else {
                        localThumbnail = localFile;
                    }

                    // RETURN RESULT  
                    result.push ({
                        id: 'itemId' + item.assetIndexNumber,
                        image_local_file_transparent: localFile,
                        image_local_file_thumbnail: localThumbnail,
                        name: item.name,
                        scale: 1,             // -1 to flip the cow
                        addedToCanvas: false  // Allow to only add the cow once to the canvas
                    });
                }
            }

            // Did we find results?
            if (result.length >0) {
                return result;
            } else {
                //console.log("assetSearchFunction: No matching search query provided: " + cowId);
                toast.error("We have not find any matching asset for your search query: '" + queryString + "'. Wanna try something different?");
                return null;
            }
        }

    }

    return {
        performGenericAssetSearchFromQueryString,

        getAssetIdFromGoodMorningCafeCollectionTokenId,
        getAssetIdFromKittyConeClassicCollectionTokenId,
        getAssetIdFromChubbyLandNFTCollectionTokenId,
        getAssetIdFromWonderPalsCollectionTokenId,
        getAssetIdFromWhiskersCollectionTokenId,
        getAssetIdFromMoonbirdsCollectionTokenId,
        getAssetIdFromKumoWorldCollectionTokenName,
    };

};

export const useSharedDataManipulationFunctions = () => useBetween(useDataManipulationFunctions);