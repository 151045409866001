import React, {useEffect, useState} from 'react'

// Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Tabs from 'react-bootstrap/Tabs';

// Custom Icons Bootstrap
import { XCircle } from 'react-bootstrap-icons';


// Context modules
import { useSharedGlobalState } from "../../shared/GlobalState";
import { useSharedOpenseaFunctions } from "../../shared/OpenseaFunctions";
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedUIFunctions } from "../../shared/UIFunctions";

const TraitBreadcrumb = (props) => {

    const traitName = props.traitName;
    const traitIndex = props.traitIndex;

    const traitsValueList = props.traitsValueList;
    const traitsValueListFunction = props.updateTraitsValueListFunction;

    const traitNameList = props.traitNameList;
    const traitNameListFunction = props.traitNameListFunction;
    const metadataTraitsArray = props.metadataTraitsArray;

    // DEBUG
    // console.log("TraitBreadcrumb INIT DATA: ", props);
    // if (traitsValueList === null) {
    //     console.log("traitsValueList is NULL | index=" + traitIndex);
    // } else {
    //     console.log("traitsValueList SELECTED = " + (traitNameList[traitIndex].selected));
    //     for (var h=0; h<traitsValueList[traitIndex].length; h++) {
    //         const traitValueBool = traitsValueList[traitIndex][h]
    //         console.log("traitValue = " + traitValueBool);
    //     }
    // }

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        // 
    } = useSharedGlobalState();
    // UIFunctions functions
    const { 
        // 
    } = useSharedUIFunctions();
    // Retrieve items from context --------------------------------------------------



    
    // -------------------
    // Component functions
    // -------------------
    
    /**
     * Remove a given trait from the search filter 
     * @param {} traitType the trait type (or name) to remove 
     * @param {*} traitValue the trait value corresponding to that trait type 
     */
     function removeTraitValue(traitType, traitValue, indexValue) {

        console.log("removeTraitValue: calling removeTraitValue with type=" + traitType + " index=" + indexValue + " states=" , traitsValueList);
        let valuesList = [...traitsValueList];
        
        if (valuesList !== undefined) {
            valuesList[indexValue].selected = false;
            // We also need to remove this trait from the trait list
            handleRemoveTraitListIfEmpty(traitType, valuesList);

        } else {
            // Do nothing
            // We should not be here as we can't remove an traitType that does not exists 
        }

        console.log("removeTrait SearchFilter UPD=", valuesList);
        traitsValueListFunction(valuesList);
        //updateSearchFilterCriteria(searchFilter);
        
    }


     /**
     * Remove a trait from the traitList list if and only if this trait has all its value unchecked 
     * @param {*} traitName the trait to remove 
     * @param {*} valuesList the list of checkbox status for this trait (we do not use the state value as it seems to be behind the latest update)
     */
      function handleRemoveTraitListIfEmpty(traitName, valuesList) {

        // Check if all the checkbox for a given trait are unchecked
        // if so, remove the trait from the list of traits 
        // const valuesList = traitValuesStatus;

        let isAllFalse = true;
        for (var h=0; h<valuesList.length; h++) {
            const currentValue = valuesList[h].selected;
            //console.log("handleRemoveTraitListIfEmpty: processsing index = " + h + " with value = " + currentValue);
            // As soon as we found a value that is true, we invalidate isAllFalse
            // and stop the loop 
            if (currentValue == true) {
                console.log("handleRemoveTraitListIfEmpty: traitName = " + traitName + " is partially checked for valueIndex=" + h);
                isAllFalse = false;
                break;
            }
        }

        if (!isAllFalse) {
            // Do nothing 
        } else { 
            let updatedTraitsList = []
            traitNameList.forEach(localTraitName => {
                if (localTraitName.name !== traitName) {
                    updatedTraitsList.push({ name: localTraitName.name, selected: localTraitName.selected }); 
                    console.log("handleRemoveTraitListIfEmpty: DO NOT removeTrait deleting " + localTraitName.name + " from traitNameList");
                } else {
                    updatedTraitsList.push({ name: localTraitName.name, selected: false }); 
                    console.log("handleRemoveTraitListIfEmpty: removeTrait deleting " + localTraitName.name + " from traitNameList");
                }
            });
            console.log("UpdatedTraitList", updatedTraitsList);
            traitNameListFunction(updatedTraitsList);
        }
    }

    return (

        <>
            {(traitNameList !== undefined && traitsValueList !== null && (
                <>
                    {(traitNameList[traitIndex].selected && (
                        <>
                            <span><strong>{traitName}</strong><span> </span>
                            {traitsValueList.map((traitValue, indexTraitValue) => (
                                <>
                                {(traitValue.selected === true) && (
                                    <>
                                        <Badge className="filterBadge">{metadataTraitsArray["trait_" + metadataTraitsArray.traits_list[traitIndex].toLowerCase() + "_values"][indexTraitValue]} <a onClick={() => removeTraitValue(traitName, traitValue, indexTraitValue )} href="#"><XCircle/></a></Badge>
                                        <span> </span>
                                    </>
                                )}
                                </>
                            ))}
                            </span>
                        </>
                    ))}
                </>
            ))}
        </>

    )

}

export default TraitBreadcrumb;