import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

// Password protection for page
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

// Context modules
import { useSharedGlobalState } from "./shared/GlobalState";

// Application level style sheets 
import './index.css';
import './css/common.css';

// App 
import Application from './Application';

// Add support for Bootsrap 5 CSS
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Environment specific CSS
const DemoTheme = React.lazy(() => import('./partner/_Generic/css/demoTheme'));

const GoodMorningCafeTheme = React.lazy(() => import('./partner/GMC/css/gmcTheme'));
const KittyConesTheme = React.lazy(() => import('./partner/KC/css/kcTheme'));
const WhiskersTheme = React.lazy(() => import('./partner/WHISKERS/css/whiskersTheme'));
const MoonbirdsPROOFTheme = React.lazy(() => import('./partner/MOONBIRDS/PROOF/css/moonbirdsTheme'));
const MoonbirdsDBSTheme = React.lazy(() => import('./partner/MOONBIRDS/DBS/css/moonbirdsTheme'));
const WonderPalsTheme = React.lazy(() => import('./partner/WP/css/wpTheme'));

const ChubbyTheme = React.lazy(() => import('./partner/CHUBBY/css/chubbyTheme'));

// Application password 
const env_APP_PASSWORD = process.env.REACT_APP_PASSWORD_SHA;
const env_APP_PASSWORD_REQUIRED = process.env.REACT_APP_REQUIRE_PASSWORD;

let requirePassword = true; 
if (env_APP_PASSWORD_REQUIRED && env_APP_PASSWORD_REQUIRED == "false") {
  requirePassword = false
}

//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }) => {

  // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
      env_APP_MODE
  } = useSharedGlobalState();

  // Retrieve items from context --------------------------------------------------

  

  return (
    <>
      <React.Suspense fallback={<></>}>

        {(env_APP_MODE === "DEMO") && <DemoTheme />}
        {(env_APP_MODE === "GMC") && <GoodMorningCafeTheme />}
        {(env_APP_MODE === "GMC-EXPLORER") && <GoodMorningCafeTheme />}
        {(env_APP_MODE === "KC") && <KittyConesTheme />}
        {(env_APP_MODE === "WHISKERS") && <WhiskersTheme />}
        {(env_APP_MODE === "MOONBIRDS-PROOF") && <MoonbirdsPROOFTheme />}
        {(env_APP_MODE === "MOONBIRDS-DBS") && <MoonbirdsDBSTheme />}
        
        {(env_APP_MODE === "WP") && <WonderPalsTheme />}
        {(env_APP_MODE === "CHUBBY") && <ChubbyTheme />}
        
      </React.Suspense>
      {children}
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
      <ThemeSelector>

        { requirePassword ? (

        <Protect 
            sha512={env_APP_PASSWORD}
            blur={true}
            styles={{
              input: { textAlign: 'center', color: 'blue', width: '220px' },
              header: { fontSize: '20px' },
              button: {  }
            }}
            boxTitle='✋ Security check'
            inputPlaceholder='What is the Secret Word?'
            buttonLabel='Submit'>
          <Application />
        </Protect>

        ) : (

          <Application />

        )}

      </ThemeSelector>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
