import React, {useEffect, useState} from 'react'

// Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Tabs from 'react-bootstrap/Tabs';

// App sub-components
import TraitSelector from './TraitSelector';

// Spinning wheel
import {ReactSpinner} from 'react-spinning-wheel';
import 'react-spinning-wheel/dist/style.css';

// Custom Icons Bootstrap
import { ArrowsCollapse, ReplyFill, X, XCircle } from 'react-bootstrap-icons';

// Local icons
import marketplaceOpenseaIcon from '../../assets/icons/marketplace-opensea.png';
import marketplaceLooksrareIcon from '../../assets/icons/marketplace-looksrare.png';
import marketplaceGemIcon from '../../assets/icons/marketplace-gem.png';


// Custom Icons FontAwesome (TODO: Migrate to bootsrap if available)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";

// Context modules
import { useSharedGlobalState } from "../../shared/GlobalState";
import { useSharedOpenseaFunctions } from "../../shared/OpenseaFunctions";
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedUIFunctions } from "../../shared/UIFunctions";

// Number formating
import { Button } from 'react-bootstrap';
import TraitBreadcrumb from './TraitBreadcrumb';

// Recharts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CollectionExplorer = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        HOPEANDSEA_SERVER_URL, HOPENANDSEA_API_KEY
    } = useSharedGlobalState();
    // UIFunctions functions
    const { 
        GenericBootstrapAccordionToggle
    } = useSharedUIFunctions();
    // Retrieve items from context --------------------------------------------------

    // Retrieve items from props ----------------------------------------------------
    const metadataAssetsArray = props.collectionMetadataAssets;
    const metadataTraitsArray = props.collectionMetadataTraits;
    const collectionFileDirectory = props.collectionAssetsRootDirectory;
    const collectionName = props.collectionName;
    
    // Retrieve items from props ----------------------------------------------------
        
    // ASSET DETAILS MODAL 
    const [assetDetailsInfo, setAssetDetailsInfo] = useState([]);
    const [assetModalShow, setAssetModalShow] = useState(false);
    const handleAssetModalClose = () => setAssetModalShow(false);
    const handleAssetModalShow = () => setAssetModalShow(true);

    // TOGGLES ITEMS
    const [toggleUIRaritySelector, setToggleUIRaritySelector] = useState(true);
    const [toggleUIGlobalSearchSelector, setToggleUIGlobalSearchSelector] = useState(true);
    const [toggleUITraitsSelector, setToggleUITraitsSelector] = useState(false);
    const [accordionTraitsDefaultActiveKey] = useState(1);          // Key for the UI to expland on traits pannel (integer as autogenerad in code)
    const [accordionRarityDefaultActiveKey] = useState("10");       // Key for the UI to expland on rarity pannel (string since hardcodded in code)

    // DATA FOR THE HTML 
    const [searchFilterTraitsNameList, setSearchFilterTraitsNameList] = useState([]);
    const [searchFilterTraitsValueList0, setSearchFilterTraitsValueList0] = useState(null);
    const [searchFilterTraitsValueList1, setSearchFilterTraitsValueList1] = useState(null);
    const [searchFilterTraitsValueList2, setSearchFilterTraitsValueList2] = useState(null);
    const [searchFilterTraitsValueList3, setSearchFilterTraitsValueList3] = useState(null);
    const [searchFilterTraitsValueList4, setSearchFilterTraitsValueList4] = useState(null);
    const [searchFilterTraitsValueList5, setSearchFilterTraitsValueList5] = useState(null);
    const [searchFilterTraitsValueList6, setSearchFilterTraitsValueList6] = useState(null);
    const [searchFilterTraitsValueList7, setSearchFilterTraitsValueList7] = useState(null);
    const [searchFilterTraitsValueList8, setSearchFilterTraitsValueList8] = useState(null);
    const [searchFilterTraitsValueList9, setSearchFilterTraitsValueList9] = useState(null);
    
    const [searchFilterRarityRank, setSearchFilterRarityRank] = useState({min: null, max: null});
    const [searchFilterRarityScore, setSearchFilterRarityScore] = useState({min: null, max: null});
    const [searchResultAssetList, setSearchResultAssetList] = useState([]);
    const [searchFilterSelected, setSearchFilterSelected] = useState(false);
    
    const [searchFilterGlobalTrait, setSearchFilterGlobalTrait] = useState("");
    

    // Recharts library 
    const [chartData, setChartData] = useState(null);
    
    // State to track when we need to refresh the search results
    const [forceRefreshSearchResults, setForceRefreshSearchResults] = useState(0);
    

    // Initialize the state array for the first time    
    useEffect(() => { 
        // Initialize the state array for traits list
        let tempTraitsList = [];
        for (var h=0; h<metadataTraitsArray.traits_list.length; h++) {
            const traitName = metadataTraitsArray.traits_list[h];
            const newTrait = {
                name: traitName,
                selected: false
            }
            tempTraitsList[h] = newTrait;
        }
        setSearchFilterTraitsNameList(tempTraitsList);
        //console.log("CollectionExplorer traitsList INIT: ", tempTraitsList);
    }, []);

    useEffect(() => { 
        console.log("Triggering update search result function...");

        // Perform search function
        refreshSearchResults();

        if (searchFilterTraitsNameList.length >0 ) {
            // Check if we've selected at least one filter
            let noFilterSelected = true; 
            for (var x=0; x<metadataTraitsArray.traits_list.length; x++) {
                noFilterSelected = noFilterSelected && !searchFilterTraitsNameList[x].selected;
            }
            console.log("NO FILTER SELECTED? = " + noFilterSelected);
            setSearchFilterSelected(!noFilterSelected);
        }

        return () => {
            
        }
    }, [searchFilterRarityRank, searchFilterRarityScore, 
        searchFilterTraitsNameList,
        searchFilterTraitsValueList0, searchFilterTraitsValueList1, searchFilterTraitsValueList2, searchFilterTraitsValueList3, searchFilterTraitsValueList4,
        searchFilterTraitsValueList5, searchFilterTraitsValueList6, searchFilterTraitsValueList7, searchFilterTraitsValueList8, searchFilterTraitsValueList9,
        searchFilterGlobalTrait
    ]);

    // useEffect(() => { 
    //     // Initialize the state array for traits value checkboxes 
    //     let tempFilterTraitsValueListV2 = [];
    //     for (var h=0; h<metadataTraitsArray.traits_list.length; h++) {
    //         tempFilterTraitsValueListV2[h] = [];
    //         const traitName = metadataTraitsArray.traits_list[h];
    //         for (var i=0; i<metadataTraitsArray["trait_" + traitName.toLowerCase() + "_values"].length; i++) {
    //             tempFilterTraitsValueListV2[h][i] = false;
    //         }
    //     }
    //     setSearchFilterTraitsValueListV2(tempFilterTraitsValueListV2);
    //     console.log("searchFilterTraitsValueListV2 INIT: ", tempFilterTraitsValueListV2);
    // }, []);
    

    // -------------------------
    // DATA MANAGEMENT FUNCTIONS
    // -------------------------

    /**
     * Refresh the search results with the latest filters selected 
     */
    function refreshSearchResults() {

        const filterRankinkRankMin = searchFilterRarityRank.min;
        const filterRankinkRankMax = searchFilterRarityRank.max;

        console.log("refreshSearchResults: START Asset search based on selection...");
        console.log("refreshSearchResults: rankingRankMin:" + filterRankinkRankMin + " Max:" + filterRankinkRankMax);

        // Reset the search results 
        setSearchResultAssetList([]);

        for (var i=0; i<metadataAssetsArray.length; i++) {
            const item = metadataAssetsArray[i];
            const propertyList = item.properties;
            const assetName = item.name;
            const assetTraitsList = propertyList[0];

            //console.log("==== PROCESSING ASSET: " + assetName);

            // Expected content from an item of metadataAssetsArray
            // {
            //     "arrayIndex":9,
            //     "fileName":"kc_collection_1006.png",
            //     "name":"Kitty Cone #1006",
            //     "assetIndexNumber":"1006",
            //     "tokenId":"1006",
            //     "properties":[
            //         {"Kitty":           "Coffee",
            //          "Background":       "Blue",
            //          "Cone":             "Pink",
            //          "Face_1":           "Blue Glasses 1", 
            //          "Face_2:            "Blue Glasses 2",
            //          "Accessory":        "Pirate Hat and Parrot Captain Flint" }
            //     ],
            //     "rarityRank":1044
            //
            //
            // Note for traits like Face_1, Face_2 that represent the same trait, we build an array with both value together
            //
            // }

            // Search on rarity ========================================================================================================
            const rarityRank = item.rarityRank;
            if ((filterRankinkRankMin >0 && rarityRank < filterRankinkRankMin) || (filterRankinkRankMax >0 && rarityRank > filterRankinkRankMax)) {
                // Rarity does not match
                console.log("RarityRank:" + rarityRank + " does not match expecting rarityRank between min: " + filterRankinkRankMin + " and max: " + filterRankinkRankMax);
                continue;
            } else {
                //console.log("====> Process asset: " + assetName);
            }
            
            let matchGlobal = false;
            let matchOnAllTraits = false;
            
            // Lookup traits using global search ======================================================================================
            if (searchFilterGlobalTrait !== "") {
                //console.log("Global search by trait value=" + searchFilterGlobalTrait);
                const queryString = searchFilterGlobalTrait;
                
                // We get the JSON object of the propertyList (which is the first element of the array)
                // And parse through each value to see if they match what we are looking for 
                Object.keys(assetTraitsList).forEach(function(key) {
                    const propertyValue = assetTraitsList[key].toLowerCase();
                    const searchValue = queryString.toLowerCase();
                    if (propertyValue.includes(searchValue)) {
                        //console.log("[traitLookup] Match queryString=" + (i+1) + " on textToSearchArray=" + propertyValue + " Text=" + searchValue);
                        //console.log(assetTraitsList[key])
                        matchGlobal = true;
                    } else {
                        //console.log("[traitLookup] No match on:" + propertyValue);
                    }
                    
                });

                // If we don't match on trait, we try on asset name 
                if (matchGlobal == false) {

                    //console.log("Global search by asset name=");

                    const assetName = item.name.toLowerCase();
                    const searchValue = queryString.toLowerCase();
                    if (assetName.includes(searchValue)) {
                        //console.log("[assetNamelookup] Match queryString=" + (i+1) + " on textToSearchArray=" + assetName + " Text=" + searchValue);
                        //console.log(assetTraitsList[key])
                        matchGlobal = true;
                    } else {
                        //console.log("[assetNamelookup] No match on:" + assetName);
                    }

                }

            }

            // If we are doign a global search, we only process the asset that match that search
            if (searchFilterGlobalTrait === "") {

                // We reset match global as we want to only match on matchOnAllTraits
                matchGlobal = false; matchOnAllTraits = true;
                //console.log("Search by specific trait value=" + searchFilterGlobalTrait);

                // Lookup all the traits ==================================================================================================
                
                // Go through the list of available trait and merge similar traits together 
                let assetTraitListConsolidated = [];
                Object.keys(assetTraitsList).forEach(function(key) {

                    let currentAssetTraitName = key.toLowerCase();
                    const currentAssetTraitNameValue = assetTraitsList[key].toLowerCase();

                    // Extract anything besides '_' followed by a number only at the end of the string
                    // Create regexp
                    // matchAll return an array of matches. We expect only one so match[0]
                    // then each item of the array is a group. Index = 1 is the matching trait without the '_+digit' suffix 
                    const regexp = /^(.*?)_(\d+)(?!.*\d)$/gm
                    const match = [...currentAssetTraitName.matchAll(regexp)];
                    // Check if we have a match 
                    if (match.length > 0) {
                        //console.log(match)
                        // Return the update traitName 
                        currentAssetTraitName = match[0][1];
                        //console.log("We detected a traitName with multiple value - traitName detected = ", currentAssetTraitName);
                    } else {
                        //console.log("Detect regular traitName=" + currentAssetTraitName);
                    }

                    if (!assetTraitListConsolidated.hasOwnProperty(currentAssetTraitName)) {
                        assetTraitListConsolidated[currentAssetTraitName] = [];
                    }
                    assetTraitListConsolidated[currentAssetTraitName].push(currentAssetTraitNameValue);

                });

                // Go through the list of available trait for the current asset being processed 
                Object.keys(assetTraitListConsolidated).forEach(function(key) {

                    const currentAssetTrait = key;
                    const currentAssetTraitName = currentAssetTrait.toLowerCase();
                    const currentAssetTraitNameArray = assetTraitListConsolidated[key];

                    //console.log("** Processing asset TraitName: " + currentAssetTraitName + " | val=", currentAssetTraitNameArray);

                    // If we have not selected a trait, we ignore it entirely
                    let didUserSelectTrait = false;
                    let userSelectedTraitIndex = null;
                    for (var b=0; b<searchFilterTraitsNameList.length; b++) {
                        const filterTrait = searchFilterTraitsNameList[b];
                        const filterTraitName = filterTrait.name.toLowerCase();
                        const filterTraitNameIsSelected = filterTrait.selected;
                        if (currentAssetTraitName.startsWith(filterTraitName) && filterTraitNameIsSelected) {
                            didUserSelectTrait = true;
                            userSelectedTraitIndex = b;
                            break;
                        }
                    }

                    if (didUserSelectTrait) {
                        let matchOnAtLeastOneValues = false;

                        const filterTrait = searchFilterTraitsNameList[userSelectedTraitIndex];
                        if (userSelectedTraitIndex === 0) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList0, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 1) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList1, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 2) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList2, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 3) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList3, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 4) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList4, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 5) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList5, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 6) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList6, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 7) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList7, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 8) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList8, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }
                        if (userSelectedTraitIndex === 9) {
                            matchOnAtLeastOneValues = processSearchOnTraitValueSegment(assetName, filterTrait, searchFilterTraitsValueList9, userSelectedTraitIndex, a, currentAssetTraitName, currentAssetTraitNameArray);
                        }

                        if (!matchOnAtLeastOneValues) {
                            // console.log("assetName=" + assetName + " | userSelectedTraitIndex=" + userSelectedTraitIndex + " DO NOT MATCH"
                            //      +  "(traitNameIndex=" + userSelectedTraitIndex + " | value=" + currentAssetTraitName  
                            //      +  ") on assetTraitValue=" + currentAssetTraitNameValue);
                            matchOnAllTraits = false; 
                        } else {

                        }

                    } else {

                        // console.log("WE DID NOT SELECT TRAIT: " + currentAssetTraitName)

                    }

                });
            }

            //console.log ("matchOnAllTraits=" + matchOnAllTraits + ", matchGlobal=" + matchGlobal + " for name=" + item.name);

            // PROCESS MATCH  
            if (matchOnAllTraits || matchGlobal) {

                // Also need to exclude all asset that do not have the selected property 
                let didAssetDoNotHaveSelectTrait = true;
                let nbTraitNotSelected = 0; 
                for (var a=0; a<searchFilterTraitsNameList.length; a++) {
                    const filterTrait = searchFilterTraitsNameList[a];
                    const filterTraitName = filterTrait.name.toLowerCase();
                    const filterTraitNameIsSelected = filterTrait.selected;
                    if (filterTraitNameIsSelected) {
                        // We need to find that trait name into the asset being processed 
                        Object.keys(assetTraitsList).forEach(function(key) {
                            const currentAssetTrait = key;
                            const currentAssetTraitName = currentAssetTrait.toLowerCase();
                            const currentAssetTraitNameValue = assetTraitsList[key].toLowerCase();
                            if (currentAssetTraitName.startsWith(filterTraitName)) {
                                //console.log("Asset trait:" + currentAssetTraitName + " match userSelected Trait=" + filterTraitName);
                                didAssetDoNotHaveSelectTrait = false;
                            }
                        });
                        if (didAssetDoNotHaveSelectTrait) {
                            //console.log("Could not find a match for asset trait: " + filterTraitName);
                            break;
                        }
                    } else {
                        nbTraitNotSelected++;
                    }
                }

                if (!didAssetDoNotHaveSelectTrait || nbTraitNotSelected === searchFilterTraitsNameList.length) {
                    // We add to the search result 
                    //console.log("Adding asset: " + assetName);
                    setSearchResultAssetList( searchResultAssetList => [...searchResultAssetList, item.arrayIndex ]);
                }
            } else {
                //console.log("Skipping asset: " + assetName);
            }

        }
   
        // console.log("Search results returned: ", searchResults);
        // setSearchResultAssetList(searchResults);
        //updateSearchFilterCriteria(result);

    }

    /**
     * Process the traits selected for a given trait 
     * 
     * @param {*} dataSetOfValuesToProcess the state variable that contain the traits to  process 
     * @param {*} indexForTrait the index of that trait 
     * @param {*} traitName the name of the trait being processed 
     * @param {*} traitValueArray an array of value for the trait being processed 
     * 
     * @return {*} true if we match on all value for the trait, or the value passed in paramters otherwise 
     */
    function processSearchOnTraitValueSegment(assetName, filterTrait, dataSetOfValuesToProcess, indexForAssetTrait, indexForFilterTrait, traitName, traitValueArray) {

        if (indexForAssetTrait >= metadataTraitsArray.traits_list.length) {
            //console.log("processSearchOnTraitValueSegment: traitIndex=" +  indexForFilterTrait +  " is not a valid trait");
            return true;
        } else {
            //console.log("processSearchOnTraitValueSegment: processing traitName=" + traitName + " with values=", traitValueArray);
        }

        let matchOnAtLeastOneValues = false;
        for (var v=0; v<dataSetOfValuesToProcess.length; v++) {
            const filterTraitValue = dataSetOfValuesToProcess[v];
            const filterTraitValueName = filterTraitValue.name.toLowerCase();
            const filterTraitValueIsSelected = filterTraitValue.selected;
            if (!filterTraitValueIsSelected) {
                continue;
            } else {
                // Let's see if this is one of the traitsNameValue of the asset selected
                for (var x=0; x<traitValueArray.length; x++) {
                    const traitValue = traitValueArray[x].toLowerCase();
                    if (traitValue === filterTraitValueName) {
                        //matchOnAllValuesBool = true;
                        console.log("Match assetName = " + assetName  + " (traitNameIndex=" + indexForAssetTrait + " | value=" + traitName  
                            + ") on assetTraitValue=" + traitValue + "=== filterTraitValue=" + filterTraitValueName);
                        matchOnAtLeastOneValues = true;
                        // We stop search and returh a valid match 
                        return matchOnAtLeastOneValues;
                    }
                }
            }
        }
        return matchOnAtLeastOneValues; 
    }







    function handleRemoveTraitList(traitName) {
        setSearchFilterTraitsNameList(searchFilterTraitsNameList.filter(item => item !== traitName));
    }

    function removeRankingRank(key, value) {
        console.log("in removeRankingRank with key=" + key + " and value=" + value);
        if (key === "min") {
            setSearchFilterRarityRank(
                {min: null,
                max: searchFilterRarityRank.max} 
            );
        } else { // assuming max 
            setSearchFilterRarityRank(
                {min: searchFilterRarityRank.min,
                max: null} 
            );
        }
    }

    /**
     * Update ranking rank from user input 
     * @param {} e event of the UI element that trigger the change 
     */
    function onChangeFilterRankingRank(e) {
        console.log("onChangeFilterRankingRank: name=" + e.target.name + " value=" + e.target.value );
        setSearchFilterRarityRank({...searchFilterRarityRank,
            [e.target.name] : e.target.value});        
    }
    
    /**
     * Update ranking score from user input 
     * @param {} e event of the UI element that trigger the change 
     */
     function onChangeFilterRankingScore(e) {
        console.log("onChangeFilterRankingScore: name=" + e.target.name + " value=" + e.target.value );
        setSearchFilterRarityScore({...searchFilterRarityScore,
            [e.target.name] : e.target.value});        
    }

    /**
     * Update global trait property search 
     * @param {} e event of the UI element that trigger the change 
     */
    function onChangeFilterGlobalTraitSearch(e) {
        console.log("onChangeFilterGlobalTraitSearch: name=" + e.target.name + " value=" + e.target.value );
        setSearchFilterGlobalTrait(e.target.value);  
    }

    



    /**
     * Display the details about modals 
     * 
     * @param {} assetTokenId 
     */
    function openAssetDetailsModel(assetTokenId) {
        //console.log("INSINE openAssetDetailsModel");
        setAssetDetailsInfo(null);
        handleAssetModalShow();
        fetchAssetDetails(assetTokenId);
    }

    async function fetchAssetDetails(assetTokenId) {

        const hostname = HOPEANDSEA_SERVER_URL;
        let fetchUrl = null; let jsonData = null

        const formData = new FormData();
        formData.append("X-API-KEY", HOPENANDSEA_API_KEY);

        fetchUrl = hostname + "/api/v1/getAssetDetailsForCollection.php?collection=" + collectionName + "&tokenId=" + assetTokenId;
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            data.openseaLastRefreshDate = jsonData.data.openseaLastRefreshDate;
            data.feedGenerationDate = jsonData.data.feedGenerationDate;
            data.serverAppVersion = jsonData.data.appVersion;
            //console.log(jsonData);
            setAssetDetailsInfo(data);

            let chartData = [];
            if (data.assetSale_history) {
                for (var i=0; i<data.assetSale_history.length; i++) {
                    const salesData = data.assetSale_history[i];
                    // The first entry will be the mint entry, for which the price is not known 
                    if (i === 0) {
                        chartData[i] = {
                            name: salesData.created_date.substr(0, 10),
                            price: data.collection_mintPrice,
                            amt: data.collection_mintPrice,
                        }
                    } else {
                        chartData[i] = {
                            name: salesData.created_date.substr(0, 10),
                            price: salesData.transaction_price,
                            amt: salesData.transaction_price,
                        }
                    }
                }
            }
            setChartData(chartData);
            //console.log("chartData=", chartData);
        }
    }




    // -----------------
    // UTILITY FUNCTIONS
    // -----------------

    function debugData() {
        console.log("debugData searchListTraitsr=", searchFilterTraitsNameList);
        console.log("debugData searchTraitsValueList0=", searchFilterTraitsValueList0);
        console.log("debugData searchTraitsValueList1=", searchFilterTraitsValueList1);
        console.log("debugData searchResultAssetList=", searchResultAssetList);
    }

    return (

    <>

        <div className="collectionExplorerTopContainer row overflow-hidden">
            {/* 

                FILTER PANE  

            */}
            <div className="col-3 mh-100 overflow-auto">
                
                <Container className="mt-2">

                    <Card className="mb-2">
                    <Card.Header 
                            className="text-left"
                            onClick={() => setToggleUIRaritySelector(toggleUIRaritySelector => !toggleUIRaritySelector)}
                            aria-controls="collapseUIRaritySelector"
                            aria-expanded={toggleUIRaritySelector}>Filter Per Rarity  
                            <ArrowsCollapse className="float-right"/>
                    </Card.Header>

                    <Collapse in={toggleUIRaritySelector}>
                        <div className="collapse" id="collapseUITraitsSelector">
                            <Accordion flish defaultActiveKey={accordionRarityDefaultActiveKey} flush>
                                    <Card>
                                        <Card.Header>
                                        <GenericBootstrapAccordionToggle eventKey="10">RANKING (Absolute) </GenericBootstrapAccordionToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="10">
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="mb-3 text-left" controlId="rankingRanking">
                                                    <Row>
                                                    <Col>
                                                    <Form.Label>Min</Form.Label>
                                                    <Form.Control onChange={onChangeFilterRankingRank} type="text" name="min" value={searchFilterRarityRank.min}/>
                                                    </Col>
                                                    <Col>
                                                    <Form.Label>Max</Form.Label>
                                                    <Form.Control onChange={onChangeFilterRankingRank} type="text" name="max" value={searchFilterRarityRank.max}/>
                                                    </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <GenericBootstrapAccordionToggle eventKey="11">SCORE (Relative)</GenericBootstrapAccordionToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="11">
                                        <Card.Body>
                                            <Row><Col>Coming soon...</Col></Row>
                                            {/* <Form>
                                                <Form.Group className="mb-3 text-left" controlId="rankingRanking">
                                                    <Row>
                                                    <Col>
                                                    <Form.Label>Min</Form.Label>
                                                    <Form.Control onChange={onChangeFilterRankingScore} type="text" name="min" value={searchFilterRarityScore.min}/>
                                                    </Col>
                                                    <Col>
                                                    <Form.Label>Max</Form.Label>
                                                    <Form.Control onChange={onChangeFilterRankingScore} type="text" name="max" value={searchFilterRarityScore.max}/>
                                                    </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form> */}
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                        </div>
                    </Collapse>
                    </Card>   



                    <Card className="mb-2">
                    <Card.Header 
                            className="text-left"
                            onClick={() => setToggleUIGlobalSearchSelector(toggleUIGlobalSearchSelector => !toggleUIGlobalSearchSelector)}
                            aria-controls="collapseUIGlobalSearchSelector"
                            aria-expanded={toggleUIGlobalSearchSelector}>Search By Trait Property  
                            <ArrowsCollapse className="float-right"/>
                    </Card.Header>

                    <Collapse in={toggleUIGlobalSearchSelector}>
                        <div className="collapse" id="collapseUITraitsSelector">
                            <Container>
                            <Form>
                                <Form.Group className="mt-3 mb-3 text-left" controlId="rankingRanking">
                                    <Row>
                                        <Col>
                                        <Form.Label>Global Search Terms</Form.Label>
                                        <Form.Control onChange={onChangeFilterGlobalTraitSearch} type="text" name="search" value={searchFilterGlobalTrait}/>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                            </Container>
                        </div>
                    </Collapse>
                    </Card>              

                    <Card className="mb-2">
                    <Card.Header 
                            className="text-left"
                            onClick={() => setToggleUITraitsSelector(toggleUITraitsSelector => !toggleUITraitsSelector)}
                            aria-controls="collapseUITraitsSelector"
                            aria-expanded={toggleUITraitsSelector}>Filter Per Traits 
                            <ArrowsCollapse className="float-right"/>
                    </Card.Header>
                    <Collapse in={toggleUITraitsSelector}>
                        <div className="collapse" id="collapseUITraitsSelector">
                            <Accordion flish defaultActiveKey={accordionTraitsDefaultActiveKey} flush>
                                    {/* {metadataTraitsArray.traits_list.map((traitName, indexTrait) => ( */}

                                        { metadataTraitsArray.traits_list[0] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={0}
                                                traitName={metadataTraitsArray.traits_list[0]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList0}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList0(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[1] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={1}
                                                traitName={metadataTraitsArray.traits_list[1]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList1}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList1(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[2] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={2}
                                                traitName={metadataTraitsArray.traits_list[2]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList2}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList2(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[3] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={3}
                                                traitName={metadataTraitsArray.traits_list[3]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList3}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList3(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[4] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={4}
                                                traitName={metadataTraitsArray.traits_list[4]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList4}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList4(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[5] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={5}
                                                traitName={metadataTraitsArray.traits_list[5]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList5}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList5(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[6] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={6}
                                                traitName={metadataTraitsArray.traits_list[6]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList6}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList6(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[7] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={7}
                                                traitName={metadataTraitsArray.traits_list[7]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList7}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList7(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[8] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={8}
                                                traitName={metadataTraitsArray.traits_list[8]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList8}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList8(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}
                                        { metadataTraitsArray.traits_list[9] !== undefined && (
                                            <TraitSelector 
                                                indexTrait={9}
                                                traitName={metadataTraitsArray.traits_list[9]}
                                                metadataTraitsArray={metadataTraitsArray}

                                                traitValuesStatus={searchFilterTraitsValueList9}
                                                setTraitValuesStatus={updatedState => setSearchFilterTraitsValueList9(updatedState)}
                                                traitsList={searchFilterTraitsNameList}
                                                setTraitsList={updatedState => setSearchFilterTraitsNameList(updatedState)}
                                            />
                                        )}

                                    {/* ))} */}
                                    {/* <Card>
                                        <Card.Header>
                                        <GenericBootstrapAccordionToggle eventKey="10">FILTERS</GenericBootstrapAccordionToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="10">
                                        <Card.Body>

                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <GenericBootstrapAccordionToggle eventKey="11">PANNEL</GenericBootstrapAccordionToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="11">
                                        <Card.Body>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                </Accordion>
                        </div>
                    </Collapse>
                    </Card>
                </Container>

                <Container className="mt-2 mb-2">
                    <Button className="mr-2" onClick={() => debugData()}>🐞 Debug</Button>
                    <Button onClick={() => refreshSearchResults()}>🐭 Refresh data</Button><br/>
                </Container>

            </div>



            {/* 

            RESULT PANE  

            */}

            <div className="col-9 mh-100 overflow-auto">

                {/* SEARCH QUERY BAR */}
                <Row>
                    <Container fluid className="mt-2 mb-2">
                        <Alert>
                        <strong>We found {searchResultAssetList.length} result(s)</strong><span> </span>

                        { (searchFilterSelected) ? (
                            <>
                            | FILTERS: <span> </span>
                            </>
                        ) : (
                            <>
                            | (No Filters selected) <span> </span>
                            </>
                        )} 

                        {/* { searchFilterTraitsNameList.length > 0 && (
                        <> */}

                        { searchFilterTraitsValueList0 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[0]}
                                traitIndex={0}
                                traitsValueList={searchFilterTraitsValueList0}
                                updateTraitsValueListFunction={updatedValue => setSearchFilterTraitsValueList0(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList1 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[1]}
                                traitIndex={1}
                                traitsValueList={searchFilterTraitsValueList1}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList1(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList2 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[2]}
                                traitIndex={2}
                                traitsValueList={searchFilterTraitsValueList2}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList2(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList3 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[3]}
                                traitIndex={3}
                                traitsValueList={searchFilterTraitsValueList3}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList3(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList4 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[4]}
                                traitIndex={4}
                                traitsValueList={searchFilterTraitsValueList4}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList4(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList5 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[5]}
                                traitIndex={5}
                                traitsValueList={searchFilterTraitsValueList5}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList5(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList6 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[6]}
                                traitIndex={6}
                                traitsValueList={searchFilterTraitsValueList6}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList6(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList7 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[7]}
                                traitIndex={7}
                                traitsValueList={searchFilterTraitsValueList7}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList7(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList8 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[8]}
                                traitIndex={8}
                                traitsValueList={searchFilterTraitsValueList8}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList8(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}
                        { searchFilterTraitsValueList9 !== null && (
                            <TraitBreadcrumb
                                traitName={metadataTraitsArray.traits_list[9]}
                                traitIndex={9}
                                traitsValueList={searchFilterTraitsValueList9}
                                updateTraitsValueListFunction={(updatedValue) => setSearchFilterTraitsValueList9(updatedValue)}
                                metadataTraitsArray={metadataTraitsArray}
                                traitNameList={searchFilterTraitsNameList}
                                traitNameListFunction={(updatedTraits) => setSearchFilterTraitsNameList(updatedTraits)}
                            />
                        )}


                        { (searchFilterRarityRank.min > 0 || searchFilterRarityRank.max > 0) && (
                        <>
                            <span><strong>Rank:</strong><span> </span>
                                <>
                                { searchFilterRarityRank.min > 0 && (
                                    <>
                                        <Badge className="filterBadge">&gt; {searchFilterRarityRank.min} <a onClick={() => removeRankingRank("min", searchFilterRarityRank.min)} href="#"><XCircle/></a></Badge>
                                        <span> </span>
                                    </>
                                )}
                                { searchFilterRarityRank.max > 0 && (
                                    <>
                                        <Badge className="filterBadge">&lt; {searchFilterRarityRank.max} <a onClick={() => removeRankingRank("max", searchFilterRarityRank.max)} href="#"><XCircle/></a></Badge>
                                    </>
                                )}
                                </>
                            </span>
                        </>
                        )}

                        </Alert>
                    </Container>
                </Row>

                <div className="row align-items-center">
                    { searchResultAssetList !== null ? (
                        <>
                            <Col className="justify-content-center">
                            {searchResultAssetList.map((d, index) => (
                                <>
                                    {/* { index < 50 && ( */}

                                        
                                            <img
                                                className="btn"
                                                alt={metadataAssetsArray[d].name}
                                                title={metadataAssetsArray[d].name}
                                                src={collectionFileDirectory + metadataAssetsArray[d].fileName}
                                                height={200} 
                                                onClick={() => openAssetDetailsModel(metadataAssetsArray[d].tokenId)}
                                                />
                                        
                                    {/* )} */}
                                </>
                            ))}
                            </Col>
                        </>
                    ) : (
                        <Col className="d-flex flex-column align-items-center col-lg-12">
                            <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                        </Col>
                    )}
                </div> 

            </div>
        </div>

        {/* ASSET DETAILS MODAL */}
        <Modal size="xl" show={assetModalShow} onHide={handleAssetModalClose}>

        {assetDetailsInfo !== null ? (

            <>

                <Modal.Header>
                <Modal.Title>{assetDetailsInfo.asset_name} (from {assetDetailsInfo.collection_name})</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                        
                        <Alert variant={"warning"} className="mt-2">The information on this page is based on Opensea data and is up to date as of {assetDetailsInfo.openseaLastRefreshDate} UTC</Alert>

                        <Tabs defaultActiveKey="story" className="mb-3">

                            <Tab eventKey="story" title="Asset Story">

                            <Row>
                                <Col className="col-3">
                                    <a target="_blank" href={assetDetailsInfo.asset_openseaLink}>
                                        <img
                                            className="responsive"
                                            alt={assetDetailsInfo.asset_name}
                                            title={assetDetailsInfo.asset_name}
                                            src={assetDetailsInfo.assetImage_preview}/>
                                    </a>
                                </Col>
                                <Col className="col-9">

                                    <Row><Col>
                                    👋 <strong>My name is {assetDetailsInfo.asset_name}</strong> and it is nice to meet you!
                                    </Col></Row>

                                    <br/>
                                    <Row><Col>🎂 Today I am {assetDetailsInfo.assetAge_years} year(s), {assetDetailsInfo.assetAge_months} month(s), and {assetDetailsInfo.assetAge_days} day(s) old!
                                    My birthday is coming up in {assetDetailsInfo.assetNextBirthday_years}, year(s), {assetDetailsInfo.assetNextBirthday_months} month(s), and {assetDetailsInfo.assetNextBirthday_days} day(s) and I am super excited about it!
                                    </Col></Row>
                                    
                                    <br/>
                                    { assetDetailsInfo.traits_details !== undefined && (
                                        <Row><Col><span>😂 Here are some fun facts about me:</span>
                                            <ul>
                                                {assetDetailsInfo.traits_details.map((traitData, index) => (
                                                    <>
                                                        { traitData.name === "background" && (<li>My favorite color is <strong>{traitData.value}</strong>.</li>)}
                                                        { traitData.name === "colour" && (<li>My second favorite color is <strong>{traitData.value}</strong>.</li>)}
                                                        { traitData.name === "mood" && (<li>I love <strong>{traitData.value}</strong>.</li>)}
                                                        { traitData.name === "clothing" && (<li>I often wear <strong>{traitData.value}</strong>.</li>)}
                                                        { traitData.name === "object" && (<li>Did you noticed my <strong>{traitData.value}</strong>?</li>)}
                                                    </>
                                                ))}
                                            </ul>
                                        </Col></Row>
                                    )}
                                </Col>
                            </Row>

                            </Tab>

                            <Tab eventKey="rarity" title="Asset Rarity">
                                
                                <Row>
                                    <Col className="col-3">
                                        <a target="_blank" href={assetDetailsInfo.asset_openseaLink}>
                                            <img
                                                className="responsive"
                                                alt={assetDetailsInfo.asset_name}
                                                title={assetDetailsInfo.asset_name}
                                                src={assetDetailsInfo.assetImage_preview}/>
                                        </a>
                                    </Col>
                                    <Col className="col-9">
                                        <Row>
                                            <Col className="col-12">
                                                <strong>{assetDetailsInfo.asset_name}</strong> 
                                            </Col>
                                        
                                        </Row>
                                        <Row className="mb-4">
                                            <Col className="col-12">
                                                <strong>Rank #{assetDetailsInfo.rarity_rank}</strong> (Rarity score: {assetDetailsInfo.rarity_score})
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="col-3"><strong>Trait Name</strong></Col>
                                            <Col className="col-3"><strong>Trait Value</strong></Col>
                                            <Col className="col-3"><strong>Trait Score</strong></Col>
                                            <Col className="col-3"><strong>Trait Occurences</strong></Col>
                                        </Row>

                                        { assetDetailsInfo.traits_details !== undefined && (

                                        <>
                                            {assetDetailsInfo.traits_details.map((traitData, index) => (
                                                <Row>
                                                    <Col className="col-3">{traitData.name}</Col>
                                                    <Col className="col-3">{traitData.value}</Col>
                                                    <Col className="col-3">{traitData.score}</Col>
                                                    <Col className="col-3">{traitData.occurences}</Col>
                                                </Row>
                                            ))}
                                        

                                            <Row>
                                                <Col className="col-3"></Col>
                                                <Col className="col-3"><strong>Total Rarity Score</strong></Col>
                                                <Col className="col-3"><strong>{assetDetailsInfo.rarity_score}</strong></Col>
                                                <Col className="col-3"></Col>
                                            </Row>

                                        </>

                                        )}
                                    
                                    </Col>

                                </Row>

                            </Tab>
                            <Tab eventKey="sales" title="Market Sales">

                            <Row>
                                    <Col className="col-3">
                                        <a target="_blank" href={assetDetailsInfo.asset_openseaLink}>
                                            <img
                                                className="responsive"
                                                alt={assetDetailsInfo.asset_name}
                                                title={assetDetailsInfo.asset_name}
                                                src={assetDetailsInfo.assetImage_preview}/>
                                        </a>
                                    </Col>
                                    <Col className="col-9">
                                        <Row>
                                            <Col className="col-9">
                                                {(assetDetailsInfo.assetSale_date === null) ? (
                                                    <>Can you believe that this asset has never been sold!!!</>
                                                ) : (
                                                    <>Last sold on {assetDetailsInfo.assetSale_date}, 
                                                    from <a target="_blank" href={assetDetailsInfo.assetSale_toUser_openseaProfileUrl}>{assetDetailsInfo.assetSale_toUser_name}</a>
                                                    <span> </span>to <a target="_blank" href={assetDetailsInfo.assetSale_fromUser_openseaProfileUrl}>{assetDetailsInfo.assetSale_fromUser_name}</a> for {assetDetailsInfo.assetSale_price} ETH</>
                                                )}
                                            </Col>
                                            <Col className="col-3">
                                                Buy on: <a target="_blank" href={assetDetailsInfo.asset_openseaLink} className=""><img height={30} src={marketplaceOpenseaIcon} title="Opensea marketplace"/></a> <a target="_blank" href={assetDetailsInfo.asset_looksrareLink} className=""><img height={30} src={marketplaceLooksrareIcon} title="LooksRare marketplace"/></a> <a target="_blank" href={assetDetailsInfo.asset_gemxyzLink} className=""><img height={30} src={marketplaceGemIcon} title="Gem marketplace"/></a>
                                            </Col>
                                        
                                        </Row>
                                        <Row>
                                            <Col>
                                            Sales history:
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Container width="100%" height="100%">
                                                <LineChart
                                                    width={800}
                                                    height={200}
                                                    data={chartData}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5,
                                                    }}
                                                    >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis dataKey="price" />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line type="monotone" dataKey="price" stroke="purple" />
                                                </LineChart>
                                            </Container>

                                        </Row>

                                    </Col>
                                </Row>
                                
                            </Tab>
                            
                        </Tabs>

                        </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleAssetModalClose}>OK</Button>
                    </Modal.Footer>

                </>

                ) : (

                    <>

                    <Modal.Header>
                    <Modal.Title>Loading...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <Row className="justify-content-center">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Row>

                            </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleAssetModalClose}>OK</Button>
                    </Modal.Footer>

                    </>

                )}

            
        </Modal>

    </>
    
    )

}

export default CollectionExplorer;