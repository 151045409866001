import React, {useEffect, useState} from 'react'

// Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';


// Context modules
import { useSharedGlobalState } from "../../shared/GlobalState";
import { useSharedOpenseaFunctions } from "../../shared/OpenseaFunctions";
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedUIFunctions } from "../../shared/UIFunctions";

const TraitSelector = (props) => {

    const indexTrait = props.indexTrait;
    const traitName = props.traitName;
    const metadataTraitsArray = props.metadataTraitsArray;
    const traitValuesStatus = props.traitValuesStatus;
    const setTraitValuesStatus = props.setTraitValuesStatus;
    const traitsList = props.traitsList;
    const setTraitsList = props.setTraitsList;

    //console.log(props);
    

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        userIsAdmin,
        HOPEANDSEA_SERVER_URL, HOPENANDSEA_API_KEY
    } = useSharedGlobalState();
    // UIFunctions functions
    const { 
        GenericBootstrapAccordionToggle
    } = useSharedUIFunctions();
    // Retrieve items from context --------------------------------------------------


    // Initialize the traitValue configuration array for the first time 
    useEffect(() => { 
        if (traitValuesStatus === null && traitName !== undefined) {
            // Initialize the state array for traits value checkboxes 
            let tempTraitValueList = [];
            //for (var h=0; h<metadataTraitsArray.traits_list.length; h++) {
                const traitName = metadataTraitsArray.traits_list[indexTrait];
                for (var i=0; i<metadataTraitsArray["trait_" + traitName.toLowerCase() + "_values"].length; i++) {
                    tempTraitValueList[i] = { name: metadataTraitsArray["trait_" + traitName.toLowerCase() + "_values"][i], selected: false };
                }
            //}
            setTraitValuesStatus(tempTraitValueList);
            //console.log("TraitSelector traitValuesList INIT: ", tempTraitValueList);
        } else {
            //console.log("TraitSelector traitValuesList ALREADY INIT!");
        }
    }, []);

    
    // -------------------
    // Component functions
    // -------------------

    /**
     * Update ranking rank from user input 
     * @param {} e event of the UI element that trigger the change 
     */
    function onChangeFilterTraitValue(e, indexTrait, indexValue) {

        const traitName = metadataTraitsArray.traits_list[indexTrait];
        const doAdd = e.target.checked;
        console.log("onChangeFilterTraitValue: indexTrait=" + indexTrait + " indexValue=" +indexValue + " | traitName=" + traitName +  " value=" + doAdd );
        
        // Update value state for the given traitName 
        console.log("onChangeFilterTraitValue: valueArray=",  traitValuesStatus);
        let newArr = [...traitValuesStatus]; // copying the old datas array

        // Make sure we have an array for the trait we are adding value to 
        // And initialize it's value to false by default 
        //console.log("old array:", newArr);
        newArr[indexValue].selected = doAdd;
        //console.log("new array:", newArr);
        setTraitValuesStatus(newArr); 
        
        // Update the list of traits 
        if (doAdd) {
            handleAddTraitList(traitName);
        } else {
            handleRemoveTraitListIfEmpty(traitName, newArr);
        }

    }

    /**
     * Add a trait to the list of trait selected 
     * @param {*} traitName the new trait to add 
     */
    function handleAddTraitList(traitName) {
        const tlist = traitsList;
        for (var h=0; h<tlist.length; h++) {

            const localTraitName = tlist[h];
            console.log("handleAddTraitList: processing traitName=", localTraitName, " looking to map with TraitName=" + traitName) ;
            if (localTraitName.name === traitName) {
                console.log("Found a match!");
                localTraitName.selected = true;
            }

        }

        setTraitsList(tlist);
        console.log("handleAddTraitList setSearchFilterTraitsNameList UPD=", tlist);
        
    }

    /**
     * Remove a trait from the traitList list if and only if this trait has all its value unchecked 
     * @param {*} traitName the trait to remove 
     * @param {*} valuesList the list of checkbox status for this trait (we do not use the state value as it seems to be behind the latest update)
     */
    function handleRemoveTraitListIfEmpty(traitName, valuesList) {

        // Check if all the checkbox for a given trait are unchecked
        // if so, remove the trait from the list of traits 
        // const valuesList = traitValuesStatus;

        let isAllFalse = true;
        for (var h=0; h<valuesList.length; h++) {
            const currentValue = valuesList[h];
            // console.log("handleRemoveTraitListIfEmpty processsing index = " + h + " with value = " + currentValue);
            // As soon as we found a value that is true, we invalidate isAllFalse
            // and stop the loop 
            if (currentValue == true) {
                console.log("handleRemoveTraitListIfEmpty traitName = " + traitName + " is partially checked for valueIndex=" + h);
                isAllFalse = false;
                break;
            }
        }

        if (!isAllFalse) {
            // Do nothing 
        } else {

            removeTrait(traitName);
            
        }
    }

    /**
     * Remove a trait 
     * @param {*} traitName the trait to remove 
     */
     function removeTrait(traitName) {

        let traitNameList = traitsList;
        let updatedTraitsList = [];

        traitNameList.forEach(localTraitName => {
            if (localTraitName.name !== traitName) {
                updatedTraitsList.push({ name: localTraitName.name, selected: localTraitName.selected }); 
                console.log("handleRemoveTraitListIfEmpty: DO NOT removeTrait deleting " + localTraitName.name + " from traitNameList");
            } else {
                updatedTraitsList.push({ name: localTraitName.name, selected: false }); 
                console.log("handleRemoveTraitListIfEmpty: removeTrait deleting " + localTraitName.name + " from traitNameList");
            }
        });
        setTraitsList(updatedTraitsList);

    }

    /**
     * Update the search Query by deselecting all the element of a given traitType
     *  
     * @param {*} evt the event that triggers to update 
     * @param {*} traitType the traitType to update 
     */
    function updateSearchQueryResetTraitSelection(evt, traitType) {
        
        let updatedTraitValues = [];
        
        // Go over all the value of the selected trait
        for (var h=0; h<traitValuesStatus.length; h++) {
            const valueName = traitValuesStatus[h].name;
            updatedTraitValues.push({ name: valueName, selected: false }); 
        }

        // Force trait selection removal 
        removeTrait(traitName);

        console.log("updateSearchQuerySelectAll SearchFilter UPD=", updatedTraitValues);
        setTraitValuesStatus(updatedTraitValues);

    }





    return (

        <>

            { (traitName !== undefined) && (
        
            <Card>
                <Card.Header>
                    <GenericBootstrapAccordionToggle eventKey={indexTrait}>{traitName} ({metadataTraitsArray["trait_" + traitName.toLowerCase() + "_values"].length})</GenericBootstrapAccordionToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={indexTrait}>
                    <Card.Body>

                    <strong><Button 
                        className="text-left"
                        label={"RESET SELECTION"}
                        onClick={(e) => updateSearchQueryResetTraitSelection(e, traitName)}
                    >Reset Selection</Button></strong>

                    {/* {traitValuesStatus !== null && metadataTraitsArray["trait_" + traitName.toLowerCase() + "_selectALL"] === undefined ? (
                        <> */}

                        {traitValuesStatus != null && (

                            <>
                            {metadataTraitsArray["trait_" + traitName.toLowerCase() + "_values"].map((traitValue, indexValue) => (
                                <Form.Check 
                                    className="text-left"
                                    name={`${traitValue}`}
                                    type={"checkbox"}
                                    id={`${traitName}-${traitValue}`}
                                    label={`${traitValue}`}
                                    checked={traitValuesStatus[indexValue].selected}
                                    //onChange={(e) => addTraitToSearch(e, traitName, traitValue)}
                                    onChange={(e) => onChangeFilterTraitValue(e, indexTrait, indexValue)}
                                />
                            ))}
                            </>

                        )}
                        
                        {/* </>
                        ) : (
                        <>
                        {metadataTraitsArray["trait_" + traitName.toLowerCase() + "_values"].map((traitValue) => (
                            <Form.Check 
                                disabled
                                className="text-left"
                                type={"checkbox"}
                                id={`${traitName}-${traitValue}`}
                                label={`${traitValue}`}
                                onChange={(e) => addTraitToSearch(e, traitName, traitValue)}
                            />
                        ))}
                        </>
                    )} */}


                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            )}



        </>

    )

}


export default TraitSelector;