// Bootstrap declarations
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Demo
import demoHeaderLogo from "./assets/Demo_Header_Logo.png"
//import demoBanner from "./assets/Demo_Banner.png"
import tchoupiCow from "./assets/HL21.png";

// Bringing up the additional content to add to the app modal
import AboutModalCommonNote from "../../components/shared/AboutModalCommonNote"

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";

const Header = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------

    return (

    <>

        <header className="container-fluid header">
            
            <div className="d-flex justify-content-between align-items-center mt-2 mb-2 mx-5">
                
                <div className="ml-2">
                    <a href="#" onClick={() => window.open('https://www.google.com')}>Back to Website</a>    
                </div>

                <div className="headerLogo justify-content-between">
                    <img src={demoHeaderLogo}
                    height={80} 
                    alt="App Logo" 
                    onClick={handleModalShow} />&nbsp;Banner Maker
                </div> 

                <div className="mr-2">
                    <a href="#" onClick={handleModalShow}>About</a>
                </div>

            </div>
 
        </header>

        {/* ABOUT PAGE MODAL  */}
        <Modal show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to this demonstration App, where you can get a glance at the feature we provide.</p> 
                    <p><strong>Coded and designed with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>, proud owner of <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/81086769033880357206596084476994515861067324006954129146728570266889022865409" target="_blank" rel="noreferrer">Marcel, the French Hihgland Cow #21.</a></p>
                    <p><img alt="Tchoupi cow" src={tchoupiCow} /></p>

                    <AboutModalCommonNote />

                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                OK
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>

    </>

    );

}

export default Header;