// Bootstrap declarations
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Demo
import demoHeaderLogo from "./assets/Demo_Header_Logo.png"
import tchoupiCow from "./assets/HL21.png";

// Bringing up the additional content to add to the app modal
import AboutModalCommonNote from "../../components/shared/AboutModalCommonNote"

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";

const Header = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------

    return (

    <>

        <header className="container-fluid header pb-4">
        <Container>
            {/* <Row className="justify-content-center">
                <img className="d-none d-sm-block" src={demoBanner} 
                    alt="Demo Storefront" 
                    />
            </Row> */}
            <Row className="justify-content-center">
                {/* Navigation menu row */}
                <Col className="headerLogo col-sm-12 col-md-3">
                
                    <img src={demoHeaderLogo} height={100}
                    alt="Demo Logo" 
                    onClick={handleModalShow} />
                
                </Col>
                <Col className="align-items-center col-sm-6 col-md-3">
                    {selectedNavItem === "BACKGROUND_GENERATOR_NOT_GATED" ? ( 
                        <button className="navButtonSelected" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_NOT_GATED")}>Meme Creator</button>
                    ) : (
                        <button className="navButton" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_NOT_GATED")}>Meme Creator</button>
                    )}
                </Col>
                <Col className="align-items-cente col-sm-6 col-md-3">
                    {selectedNavItem === "STATS_COLLECTION_CHUBBY" ? (
                        <button className="navButtonSelected" onClick={() => selectGenericLink("STATS_COLLECTION_CHUBBY")}>Collection Statistics</button>
                    ) : (
                        <button className="navButton" onClick={() => selectGenericLink("STATS_COLLECTION_CHUBBY")}>Collection Statistics</button>
                    )}
                </Col>
                <Col className="d-flex align-items-center justify-content-center col-sm-12 col-md-3">
                    <button className="navButton" onClick={handleModalShow}>About</button>
                    {/* <a className="navButton" href="" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_GATED")}>Background Generator</a>
                    <a className="navButton" href="" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_GATED")}>Background Generator</a> */}
                </Col>
            </Row>
        </Container>
        </header>

        {/* ABOUT PAGE MODAL  */}
        <Modal show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to this demonstration App, where you can get a glance at the feature we provide.</p> 
                    <p><strong>Coded and designed with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>, proud owner of <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/81086769033880357206596084476994515861067324006954129146728570266889022865409" target="_blank" rel="noreferrer">Marcel, the French Hihgland Cow #21.</a></p>
                    <p><img alt="Tchoupi cow" src={tchoupiCow} /></p>
                    <AboutModalCommonNote />
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                🐮 Moo!
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>

    </>

    );

}

export default Header;