import FooterWalletConnector from "../../../components/shared/FooterWalletConnect"

const Footer = (props) => {

    return (

    <>
        <footer className="footer container-fluid">
            <FooterWalletConnector nftEntityName="Moonbirds" doConnectWallet={false} />
        </footer>
    </>

    );

}

export default Footer;