// Bootstrap declarations
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Demo
import moonbirdsHeaderLogo from "./assets/moonbird-official-pfp.png"
import tchoupiOddities from "../Tchoupi_Oddities_3781.png";

// Bringing up the additional content to add to the app modal
import AboutModalCommonNote from "../../../components/shared/AboutModalCommonNote"

// Context modules
import { useSharedNavigationFunctions } from "../../../shared/NavigationFunctions";

const Header = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------

    return (

    <>

        <header className="container-fluid header">
            
            <div className="d-flex justify-content-between align-items-center mt-3 mb-3 mx-2">
                
                <div className="ml-2">
                    <a href="#" onClick={() => window.open('https://moonbirds.xyz/')}>Back to Website</a>    
                </div>

                <div className="headerLogo justify-content-between">
                    <img src={moonbirdsHeaderLogo}
                    height={80} 
                    alt="App Logo" 
                    onClick={handleModalShow} />&nbsp;MB Banner Maker
                </div> 

                <div className="mr-2">
                    <a href="#" onClick={handleModalShow}>About</a>
                </div>

            </div>
 
        </header>

        {/* ABOUT PAGE MODAL  */}
        <Modal show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to the Moonbirds/Oddities Banner Generator, the place to bring together your favorite Moonbirds/Oddities and share them with the rest of the world.</p> 
                    <p><strong>Don't own a Moonbird?</strong> No worries, head over to the official <a target="_blank" href="https://opensea.io/collection/proof-moonbirds">Opensea collection</a> to grab one and join the fun.</p>
                    <p><strong>Coded and designed with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>
                    <br/>proud owner of:</p>
                    <p>
                    <a href="https://opensea.io/assets/ethereum/0x1792a96e5668ad7c167ab804a100ce42395ce54d/3781" target="_blank" rel="noreferrer">Oddities #3781<img className="responsive" height="150" alt="WonderPal #8104" src={tchoupiOddities} /></a>                   
                    </p> 
                    <AboutModalCommonNote />
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                OK
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>

    </>

    );

}

export default Header;