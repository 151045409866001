import { useState } from "react";
import { useBetween } from "use-between";

// Context modules
import { useSharedGlobalState } from "./GlobalState";
import { useSharedOpenseaFunctions } from "./OpenseaFunctions";
import { useSharedCollectionsMetadata } from "../partner/CollectionsMetadata";

const useNavigationFunctions = () => {

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        env_APP_MODE,
    } = useSharedGlobalState();
    // Opensea functions
    const { 
        openseaCollectionStatistics,
        openseaCollectionStatisticsFromBackend
    } = useSharedOpenseaFunctions();
    // Collections metadata
    const { 

        GMC_OG_COLLECTION_DETAILS,
        GMC_OG_COLLECTION_METADATA_ASSETS,
        GMC_OG_COLLECTION_METADATA_TRAITS,

        KC_OG_COLLECTION_DETAILS,
        KC_OG_COLLECTION_METADATA_ASSETS,
        KC_OG_COLLECTION_METADATA_TRAITS,

        KC_5K_COLLECTION_DETAILS,
        KC_5K_COLLECTION_METADATA_ASSETS,
        KC_5K_COLLECTION_METADATA_TRAITS,

    } = useSharedCollectionsMetadata();

    // Collection UI data -------------------------------------------
    const [collectionStatisticsLabels, setCollectionStatisticsLabels] = useState([]);

    // For about modal ----------------------------------------------
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    // For background asset selection state -------------------------
    const [isNavItemSelected, setIsNavItemSelected] = useState(false);
    
    // For wallet state ---------------------------------------------
    const [userHasCow, setUserHasCow] = useState(false);

    const [collectionMetadataAssets, setCollectionMetadataAssets] = useState(null);
    const [collectionMetadataTraits, setCollectionMetadataTraits] = useState(null);

    
    // For overall navigation state ---------------------------------
    let defaultNavigationView = null;
    if (env_APP_MODE === "GMC-EXPLORER") {
        defaultNavigationView = "EXPLORER_COLLECTION_GMC";
    } else {
        defaultNavigationView = "BACKGROUND_GENERATOR_NOT_GATED";
    }
    const [selectedNavItem, setSelectedNavItem] = useState(defaultNavigationView);         // Default page
    // Did we select something on the navigation menu menu?
    
    const [isSelectedCowOnCanvas, setIsSelectedCowOnCanvas] = useState(false);

    const selectGenericLink = (linkString) => {

        //console.log("Calling selectGenericLink with arg:" + linkString);

        // Are we unselecting the navigation
        // To reset to the home page? 
        if (linkString == null) {
            setSelectedNavItem(null);
            setIsNavItemSelected(false);
            setIsSelectedCowOnCanvas(false);
            return;
        }

        setSelectedNavItem(linkString);
        setIsNavItemSelected(true);
        setIsSelectedCowOnCanvas(false);

        switch(linkString) {
            case 'BACKGROUND_GENERATOR_NOT_GATED':
                return null;

            // Good Morning Cafe
            // -----------------    
            case 'STATS_COLLECTION_GMC':
            case 'EXPLORER_COLLECTION_GMC':
                openseaCollectionStatisticsFromBackend(GMC_OG_COLLECTION_DETAILS.collectionName);
                //openseaCollectionStatistics("goodmorningcafe", "0xb3457c2065fd1f384e9f05495251f2894d1659b6", 333, false, 0.099, 2, "BenColefax", 300);
                setCollectionStatisticsLabels(GMC_OG_COLLECTION_DETAILS);
                setCollectionMetadataAssets(GMC_OG_COLLECTION_METADATA_ASSETS);
                setCollectionMetadataTraits(GMC_OG_COLLECTION_METADATA_TRAITS);
                //console.log(GMC_OG_COLLETION_METADATA_TRAITS);
                return null;

            // Kitty Cones OG
            // --------------
            case 'STATS_COLLECTION_KITTYCONES':
            case 'EXPLORER_COLLECTION_KITTYCONES':
                openseaCollectionStatisticsFromBackend(KC_OG_COLLECTION_DETAILS.collectionName);
                //openseaCollectionStatistics("kitty-cones-classic-collection", "0xa5c2e07f663936ba5f40cce63e3bba14241ef03a", 100, true, 0.01, 2, "Funboy", 140);
                setCollectionStatisticsLabels( KC_OG_COLLECTION_DETAILS);
                setCollectionMetadataAssets(KC_OG_COLLECTION_METADATA_ASSETS);
                setCollectionMetadataTraits(KC_OG_COLLECTION_METADATA_TRAITS);
                return null;

            // Kitty Cones 5K
            // --------------
            case 'STATS_COLLECTION_KITTYCONES_5K':
            case 'EXPLORER_COLLECTION_KITTYCONES_5K':
                openseaCollectionStatisticsFromBackend(KC_5K_COLLECTION_DETAILS.collectionName);
                setCollectionStatisticsLabels(KC_5K_COLLECTION_DETAILS);
                setCollectionMetadataAssets(KC_5K_COLLECTION_METADATA_ASSETS);
                setCollectionMetadataTraits(KC_5K_COLLECTION_METADATA_TRAITS);
                return null;



            // THE FOLLOWING COLLETIONS ARE NOT IN PRODUCTION YET 
            // ==================================================

            // ChubbyLand NFT
            // --------------    
            case 'STATS_COLLECTION_CHUBBY':
            case 'EXPLORER_COLLECTION_CHUBBY':
                openseaCollectionStatisticsFromBackend("chubbylandnft");
                //openseaCollectionStatistics("chubbylandnft", "0xbd50be594a25acccdddc0c6a6a0e3c8453ced397", 777, false, 0.06, 2, "ChubbyLand", 200);
                setCollectionStatisticsLabels( {
                    itemName: "ChubbyLand NFT",
                    isCollectionOver: false,
                    maxCollectionSize: 200
                })
                return null;


            // WonderPals
            // ----------
            case 'STATS_COLLECTION_WONDERPALS':
            case 'EXPLORER_COLLECTION_WONDERPALS':
                openseaCollectionStatisticsFromBackend("wonderpals");
                setCollectionStatisticsLabels( {
                    itemName: "WonderPals",
                    isCollectionOver: true,
                    maxCollectionSize: 10000
                })
                return null;


            // Kumo Residents
            // --------------
            case 'STATS_COLLECTION_KUMORESIDENTS':
            case 'EXPLORER_COLLECTION_KUMORESIDENTS':
                openseaCollectionStatisticsFromBackend("kumo-x-world-residents");
                setCollectionStatisticsLabels( {
                    itemName: "Kumo Residents",
                    isCollectionOver: true,
                    maxCollectionSize: 6666
                })
                return null;

            // Moonbirds
            // ---------
            case 'STATS_COLLECTION_MOONBIRDS_DBS':
                openseaCollectionStatisticsFromBackend("dbs-moonbirds");
                setCollectionStatisticsLabels( {
                    itemName: "Deadbirds",
                    isCollectionOver: true,
                    maxCollectionSize: 379
                })
                return null;






            default:
                console.error("In switch link... NO MATCH");
                return null;

        }

    };

    return {
        handleModalShow, handleModalClose, modalShow,                   // About modal
        setSelectedNavItem, selectedNavItem, selectGenericLink,
        isNavItemSelected, setIsNavItemSelected,                        // Navigation state
        isSelectedCowOnCanvas, setIsSelectedCowOnCanvas,                // Are items selected on background canvas?
        collectionStatisticsLabels, setCollectionStatisticsLabels,      // Collection Statistics (to move to UI)
        userHasCow, setUserHasCow,                                      // Wallet information 
        collectionMetadataAssets, collectionMetadataTraits              // Storing metadata (json local file) about the collection 
    };

};

export const useSharedNavigationFunctions = () => useBetween(useNavigationFunctions);