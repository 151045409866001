
// App Components
import BackgroundGenerator from '../../components/bannerGenerator/BackgroundGenerator.js';

// Use-wallet for account management 
import { useWallet } from 'use-wallet';

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "../../shared/NavigationPageGlobalState";
import { useSharedDataManipulationFunctions } from "../../shared/DataManipulationFunctions";
import { useSharedCollectionsMetadata } from "../CollectionsMetadata";

// Toast management 
import { toast } from 'react-toastify';






// ------------------------
// BACKGROUND CUSTOMISATION
// ------------------------

const BackgroundGeneratorConfig = (props) => {

    // Handle Metamask connection 
    const wallet = useWallet();
    const activate = (connector) => wallet.connect(connector);
    const deActivate = () => wallet.reset();

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        isSelectedCowOnCanvas,                                  // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        ownerAvailableCows,
        generateAvailableAsset,
    } = useSharedNavigationPageGlobalState();    
    // Collections metadata
    const { 
        KC_OG_COLLECTION_DETAILS,
        KC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        KC_OG_COLLECTION_ASSET_DIRECTORY,
        KC_OG_COLLECTION_METADATA_ASSETS,
        KC_OG_COLLECTION_METADATA_TRAITS,
        KC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        KC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        KC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllKittyConesClassicAssets,
        generateAllGoodMorningCafeAssets
    } = useSharedCollectionsMetadata();
    // Data Manipulation
    const {
        performGenericAssetSearchFromQueryString
    } = useSharedDataManipulationFunctions();
    // Retrieve items from context --------------------------------------------------
    



    // Backgrounds for Collection
    // **************************

    function generateBackgroundItemsForCollection() {

        let imageArray = [];
        let filePrefixDir = KC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY;


        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Blue background",  
                src:filePrefixDir + "Kitty_Cones_blue_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Pink background",  
                src:filePrefixDir + "Kitty_Cones_pink_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Purple background",  
                src:filePrefixDir + "Kitty_Cones_purple_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Teal background",  
                src:filePrefixDir + "Kitty_Cones_teal_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Yellow background",  
                src:filePrefixDir + "Kitty_Cones_yellow_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Town background",  
                src:filePrefixDir + "Kitty_Cones_town_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "White background",  
                src:filePrefixDir + "Kitty_Cones_white_background.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Selfie background",  
                src:filePrefixDir + "Kitty_Cones_background_selfies.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );
        // [] Simple Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Music background",  
                src:filePrefixDir + "Kitty_Cones_background_music.jpg", 
                width: 2054, height: 684, x:0, y:0}]
        );

        

        // EMPTY TEMPLATES
        // ---------------

        filePrefixDir = KC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY;

        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Twitter banner", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_twitter_banner.png", 
            width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Instagram Post",
            backgroundColor: "#ff7dbd",    
            src:filePrefixDir + "tpl_instagram_post.png", 
            width: 1080, height: 1080, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone X", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_iphoneX.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XR", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_iphoneXR.png", 
            width: 828, height: 1792, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XS", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_iphoneXS.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Google Pixel 3", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_pixel3.png", 
            width: 1080, height: 2160, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Samsung Note 10", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_samsungNote10.png", 
            width: 1080, height: 2280, x:0, y:0}]
        );

        return imageArray;

    };





    // -------------------------
    // ACCESSORIES CUSTOMISATION
    // -------------------------

    // Accessories 1
    // *************

    function generateAccessories1ItemsForCollection() {

        let availableAssets = [];
        let itemId = 0;
        const filePrefixDir = KC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY;

        // Butterfly
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "butterfly.png",
                image_local_file_thumbnail: filePrefixDir + "butterfly.png",
                name: "Butterfly",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 134, height: 136, x:0, y:0
            }
        );
        // Heart
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "heart.png",
                image_local_file_thumbnail: filePrefixDir + "heart.png",
                name: "Heart",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 133, height: 107, x:0, y:0
            }
        );
        // Mouse
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mouseLeft.png",
                image_local_file_thumbnail: filePrefixDir + "mouseLeft.png",
                name: "Mouse",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 171, height: 222, x:0, y:0
            }
        );
        // Purrr
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "purrr.png",
                image_local_file_thumbnail: filePrefixDir + "purrr.png",
                name: "Purrr",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 268, height: 194, x:0, y:0
            }
        );

        // CONES
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "cone_yellow.png",
                image_local_file_thumbnail: filePrefixDir + "cone_yellow.png",
                name: "Yellow Cone",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "coneSugar.png",
                image_local_file_thumbnail: filePrefixDir + "coneSugar.png",
                name: "Sugar Cone",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 412, height: 351, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "coneWaffle.png",
                image_local_file_thumbnail: filePrefixDir + "coneWaffle.png",
                name: "Waffle Cone",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 412, height: 351, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "taiyakiCone.png",
                image_local_file_thumbnail: filePrefixDir + "taiyakiCone.png",
                name: "Taiyaki Cone",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 530, height: 365, x:0, y:0
            }
        );

        // SCOOPS
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopChocolate.png",
                image_local_file_thumbnail: filePrefixDir + "scoopChocolate.png",
                name: "Chocolate Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 453, height: 427, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopLightBlue.png",
                image_local_file_thumbnail: filePrefixDir + "scoopLightBlue.png",
                name: "Blue Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 451, height: 426, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopLightPink.png",
                image_local_file_thumbnail: filePrefixDir + "scoopLightPink.png",
                name: "Pink Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 451, height: 426, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopMint.png",
                image_local_file_thumbnail: filePrefixDir + "scoopMint.png",
                name: "Mint Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 453, height: 427, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopOrange.png",
                image_local_file_thumbnail: filePrefixDir + "scoopOrange.png",
                name: "Orange Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 451, height: 426, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopStrawberry.png",
                image_local_file_thumbnail: filePrefixDir + "scoopStrawberry.png",
                name: "Stawberry Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 453, height: 427, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "scoopVanilla.png",
                image_local_file_thumbnail: filePrefixDir + "scoopVanilla.png",
                name: "Vanilla Scoop",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 453, height: 427, x:0, y:0
            }
        );

        // SPRIKLES
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "sprinklesChocolate.png",
                image_local_file_thumbnail: filePrefixDir + "sprinklesChocolate.png",
                name: "Chocolate Sprikles",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 313, height: 184, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "sprinklesGirl.png",
                image_local_file_transparent: filePrefixDir + "sprinklesGirl.png",
                name: "Pink & White Sprinkles",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 313, height: 184, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "sprinklesHearts.png",
                image_local_file_thumbnail: filePrefixDir + "sprinklesHearts.png",
                name: "Heart Sprinkles",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 279, height: 157, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "sprinklesMixed.png",
                image_local_file_thumbnail: filePrefixDir + "sprinklesMixed.png",
                name: "Mixed Sprinkles",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 312, height: 184, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "sprinklesPink.png",
                image_local_file_thumbnail: filePrefixDir + "sprinklesPink.png",
                name: "Pink Sprinkles",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 313, height: 184, x:0, y:0
            }
        );

        // SYRUPS
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "syrupBlueberry.png",
                image_local_file_thumbnail: filePrefixDir + "syrupBlueberry.png",
                name: "Bluberry Syrup",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 400, height: 332, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "syrupButterscotch.png",
                image_local_file_thumbnail: filePrefixDir + "syrupButterscotch.png",
                name: "Butterscotch Syrup",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 400, height: 330, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "syrupCherry.png",
                image_local_file_thumbnail: filePrefixDir + "syrupCherry.png",
                name: "Cherry Syrup",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 400, height: 330, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "syrupChocolate.png",
                image_local_file_thumbnail: filePrefixDir + "syrupChocolate.png",
                name: "Chocolate Syrup",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 399, height: 334, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "syrupGrey.png",
                image_local_file_thumbnail: filePrefixDir + "syrupGrey.png",
                name: "Grey Syrup",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 380, height: 320, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "syrupLime.png",
                image_local_file_thumbnail: filePrefixDir + "syrupLime.png",
                name: "Lime Syrup",
                scale: 1,                // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 380, height: 320, x:0, y:0
            }
        );

        // BLUE BIRDS
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdBlue_downLeft.png",
                image_local_file_thumbnail: filePrefixDir + "birdBlue_downLeft.png",
                name: "Blue Bird (Down/Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 130, height: 123, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdBlue_downRight.png",
                image_local_file_thumbnail: filePrefixDir + "birdBlue_downRight.png",
                name: "Blue Bird (Down/Right)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 139, height: 117, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdBlue_flying.png",
                image_local_file_thumbnail: filePrefixDir + "birdBlue_flying.png",
                name: "Blue Bird Flying",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 154, height: 150, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdBlue_left.png",
                image_local_file_thumbnail: filePrefixDir + "birdBlue_left.png",
                name: "Blue Bird (Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 152, height: 145, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdBlue_leftUp.png",
                image_local_file_thumbnail: filePrefixDir + "birdBlue_leftUp.png",
                name: "Blue Bird (Up/Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 113, height: 156, x:0, y:0
            }
        );

        // ORANGE BIRDS
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdOrange_downLeft.png",
                image_local_file_thumbnail: filePrefixDir + "birdOrange_downLeft.png",
                name: "Orange Bird (Down/Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 130, height: 123, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdOrange_downRight.png",
                image_local_file_thumbnail: filePrefixDir + "birdOrange_downRight.png",
                name: "Orange Bird (Down/Right)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 139, height: 117, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdOrange_flying.png",
                image_local_file_thumbnail: filePrefixDir + "birdOrange_flying.png",
                name: "Orange Bird Flying",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 154, height: 150, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdOrange_left.png",
                image_local_file_thumbnail: filePrefixDir + "birdOrange_left.png",
                name: "Orange Bird (Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 152, height: 145, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "birdOrange_leftUp.png",
                image_local_file_thumbnail: filePrefixDir + "birdOrange_leftUp.png",
                name: "Orange Bird (Up/Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 113, height: 156, x:0, y:0
            }
        );

        return availableAssets;

    };

    // Accessories 2
    // *************

    function generateAccessories2ForCollection() {
        let availableAssets = [];
        
        let filePrefixDir = KC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY;
        let itemId = 0;

        return availableAssets;

    };

    // Search for an asset based on either a number of 
    // based on some trait 
    function assetSearch(queryString, assetList) {
        return performGenericAssetSearchFromQueryString(KC_OG_COLLECTION_ASSET_DIRECTORY, queryString, assetList);
    }

    // // Search for an asset 
    // function assetSearch(queryString, assetList) {

    //     for (var i=0; i<assetList.length; i++) {
    //         const item = assetList[i];
    //         if (item.name.toUpperCase().includes(queryString.toUpperCase())) {
    //             //console.log("We found a match", item);

    //             const localFile = KC_OG_COLLECTION_ASSET_DIRECTORY + item.file + ".png";
    //             return [{
    //                 id: 'kittyConeId' + item.index,
    //                 image_local_file_transparent: localFile,
    //                 name: item.name,
    //                 scale: 1,              // -1 to flip the cow
    //                 addedToCanvas: false  // Allow to only add the cow once to the canvas
    //             }]
    //         }
    //     }
        
    //     toast.error("We have not find any matching asset for your search query: '" + queryString + "'. Wanna try something different?");
    //     //console.log("assetSearchFunction: Invalid string provided: " + queryString);
    //     return null;

    // }

    return (

        <BackgroundGenerator 

            // Wallet connector
            wallet={wallet}
            walletConnect={activate}
            walletDisconnect={deActivate}

            // List of available asset to select 
            collectionAssets={generateAllKittyConesClassicAssets}                       // We should pass functions, not results 
            myAssets={ownerAvailableCows} 
            isCowOwner={userHasCow}

            // Global variable to track if a cow is selected on the canvas
            isSelectedCowOnCanvas={isSelectedCowOnCanvas}

            // List of available partner asset to select 
            partnerAssets={[() => { return [] }, () => { return [] } ]}    // We should pass functions, not results 
            // List of available background to select 
            availableBackground={generateBackgroundItemsForCollection()}
            availableAccessories1={generateAccessories1ItemsForCollection()}
            availableAccessories2={null}

            allowAssetReRollConfig={
                {   
                    collection: true,
                    partner1: true,
                    partner2: true
                }
            }

            // Generator behavior's config flags
            allowCheckUserWallet = {true}           // Do we want to lookup the user wallet to search its asset?
            allowAssetSelection={true}              // Do we want the user to pick a specific collection asset? 
            allowAssetUploadAsAssets={true}         // Do we want the user to upload custom assets?
            allowAssetUploadAsBackground={true}     // Do we want the user to upload custom background?

            // Asset search function
            assetSearchFunction={assetSearch}
            assetCollectionMetadata={KC_OG_COLLECTION_METADATA_ASSETS}   // Metadata for the collection

            // Configure which panels to display
            // Collection assets + Collection backgrouns are mandatory 
            displayItemConfig={
                {
                    // Value are "always" for always
                    //           "walletOnly" for only when user is connect and has assetDirectory
                    //            "never" for never
                    partner1: "never",
                    partner2: "never",
                    accessories1: "always",
                    accessories2: "never"
                }
            }

            // Display Items legend (below each asset, background, accessories)
            displayItemLegendConfig={
                {
                    assets: true,
                    partner1: true,
                    partner2: true,
                    background: true,
                    accessories1: true,
                    accessories2: true
                }
            }

            // Configure labels 
            labelsConfig={
                { 
                    nftYouOwnTitle: 'Kitty Cones Classic You Own',          // Module title on the left hand side (When wallet is connected)
                    
                    availableItems: 'Assets',                               //         
                    allAssetTitlePannel: "Kitty Cones",                     // Asset panel title (all collections items)
                    myAssetTitlePannel: "My Kitty Cones",                   // Asset panel title (my items)
                    partner1TitlePannel: "Partner Assets (TBD)",            // (Partner) Asset panel 1 title
                    partner2TitlePannel: null,                              // (Partner) Asset panel 2 title

                    accessories1TitlePannel: "Kitty Cones Extras & Art",    // Accessories panel 1 title 
                    accessories1MessageToConnectWallet:                     // Message to let user connect wallet to unlock this panel (if wallet gated)
                        "You can unlock delightful items from the collection if you own at least one Kitty Cones NFT.",
                    accessories1MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any Kitty Cones NFT in the wallet you connected to let you unlock this section...",   
                    accessories2TitlePannel: null,                          // Accessories panel 2 title 
                    accessories2MessageToConnectWallet:                     // Message to let user connect wallet to unlock accessories module 1 (if wallet gated)
                        "You can unlock exclusive items from the collection if you own at least one Kitty Cones NFT.",
                    accessories2MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any Kitty Cones NFT in the wallet you connected to let you unlock this section...", 

                    yourUploadedAsset: "Make Your Own Kitty Cone", // Upload your own asset panel title
                    
                    availableBackgrounds: 'Available backgrounds',          // 
                    officialBackgrounds: 'Official backgrounds',            // Available background panel title 
                    templateBackgrounds: 'Template backgrounds',            // Available template panel title 
                    yourUploadedBackgrounds: 'Upload your own',             // Upload your own background panel title 

                    defaultTextAddedToCanvas: 
                            'Welcome to the World of Kitty Cones!',         // Default text being added to canvas 
                    
                    okButton: "MEW",                                        // OK Button
                    collectionShortName: "KC",                              // Collection short name
                    assetName: "Kitty Cone",                                // Label to describe an asset
                    assetSearchButtonText: "Kitty Cone Search",             // Search button to lookup new asset   
                    assetSearchLabelText: "Looking for a specific Kitty Cone? Search by name" ,    
                                                                            // Search button to lookup new asset  
                    partner1AssetSearchButtonText: "Partners 1 Search",     // Search button to lookup partner 1 asset   
                                                                            // Search label to lookup partner 1 asset  
                    partner1AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",
                    partner2AssetSearchButtonText: "Partner 2 Search",      // Search button to lookup partner 2 asset   
                                                                            // Search label to lookup partner 2 asset  
                    partner2AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits", 

                    instructionsTitle: "Kitty Cones Meme Creator!",         // Title of the instructions popup 

                    paletteColor1Text: "Koko brown",                        // #1 color for text - Label
                    paletteColor1HexValue: "#964A46",                       // #1 color for text - HexValue
                    paletteColor2Text: "Miyu Pink",                         // #2 color for text - Label
                    paletteColor2HexValue: "#FF8AA2",                       // #2 color for text - HexValue
                    paletteColor3Text: "Sherbet Orange",                    // #3 color for text - Label
                    paletteColor3HexValue: "#FF9B43",                       // #3 color for text - HexValue
                    paletteColor4Text: "Sourpuss Yellow",                   // #4 color for text - Label
                    paletteColor4HexValue: "#FFDA51",                       // #4 color for text - HexValue
                    paletteColor5Text: "Purrstachio Green",                 // #5 color for text - Label
                    paletteColor5HexValue: "#B9DA70",                       // #5 color for text - HexValue
                    paletteColor6Text: "Blackbarry Grey",                   // #6 color for text - Label
                    paletteColor6HexValue: "#877DB7",                       // #6 color for text - HexValue
                    paletteColor7Text: "Chip Blue",                         // #7 color for text - Label
                    paletteColor7HexValue: "#88D0AD",                       // #7 color for text - HexValue  
                    
                    templateolor1Text: "Koko brown",                        // #1 color for template - Label
                    templateColor1HexValue: "#964A46",                       // #1 color for template - HexValue
                    templateColor2Text: "Miyu Pink",                         // #2 color for template - Label
                    templateColor2HexValue: "#FF8AA2",                       // #2 color for template - HexValue
                    templateColor3Text: "Sherbet Orange",                    // #3 color for template - Label
                    templateColor3HexValue: "#FF9B43",                       // #3 color for template - HexValue
                    templateColor4Text: "Sourpuss Yellow",                   // #4 color for template - Label
                    templateColor4HexValue: "#FFDA51",                       // #4 color for template - HexValue
                    templateColor5Text: "Purrstachio Green",                 // #5 color for template - Label
                    templateColor5HexValue: "#B9DA70",                       // #5 color for template - HexValue
                    templateColor6Text: "Blackbarry Grey",                   // #6 color for template - Label
                    templateColor6HexValue: "#877DB7",                       // #6 color for template - HexValue
                    templateColor7Text: "Chip Blue",                         // #7 color for template - Label
                    templateColor7HexValue: "#88D0AD",                       // #7 color for template - HexValue  
                    // templateColor8Text: "Chip Blue",                         // #7 color for template - Label
                    // templateColor8HexValue: "#88D0AD",                       // #7 color for template - HexValue  
                }
            }

        />

    )

}

export default BackgroundGeneratorConfig;