// Bootstrap declarations
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// GMC
import goodMorningCafeHeaderLogo from "./assets/GMC_Header_Logo.png"
import goodMorningCafeStoreFrontBanner from "./assets/GMC_Storefront_banner_shorter.png"
import tchoupiCow from "./assets/HL21.png";

// Audio
import mooSoundMP3 from "./audio/Cow-moo-sound.mp3";

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";

const Header = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------
    
    // Playing sound
    var AudioPlay = new Audio (mooSoundMP3);
    const mooSoundAudioPLayerHandler = () => {
        AudioPlay.play();
    }

    return (

    <>

        <header className="container-fluid header">

        <Row>

            <Col className="col-md-auto">
                <Col className="headerLogo col-sm-12 col-md-3 h-100 pt-4">
                
                    <img src={goodMorningCafeHeaderLogo} 
                    alt="Good Morning Cafe Logo" 
                    onClick={handleModalShow} />
                
                </Col>
            </Col>

            <Col className="">

                <Row className="justify-content-center">
                    {/* Storefront row */}
                    <img className="d-none d-sm-block" src={goodMorningCafeStoreFrontBanner} 
                        alt="Goold Morning Cafe Storefront" />
                </Row>
                <Row className="">
                    {/* Navigation menu row */}
                    
                    <Col className="">
                        {selectedNavItem === "EXPLORER_COLLECTION_GMC" ? (
                            <button className="navButtonSelected" onClick={() => selectGenericLink("EXPLORER_COLLECTION_GMC")}>Collection Explorer</button>
                        ) : (
                            <button className="navButton" onClick={() => selectGenericLink("EXPLORER_COLLECTION_GMC")}>Collection Explorer</button>
                        )}
                    </Col>
                    <Col className="">
                        {selectedNavItem === "STATS_COLLECTION_GMC" ? (
                            <button className="navButtonSelected" onClick={() => selectGenericLink("STATS_COLLECTION_GMC")}>Collection Statistics</button>
                        ) : (
                            <button className="navButton" onClick={() => selectGenericLink("STATS_COLLECTION_GMC")}>Collection Statistics</button>
                        )}
                    </Col>
                    <Col className="">
                        <button className="navButton" onClick={handleModalShow}>About</button>
                        {/* <a className="navButton" href="" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_GATED")}>Background Generator</a>
                        <a className="navButton" href="" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_GATED")}>Background Generator</a> */}
                    </Col>
                </Row>

            </Col>

        </Row>
        </header>

        {/* ABOUT PAGE MODAL  */}
        <Modal show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to the Good Morning Cafe App, the virtual space where all cow lovers are chilling and enjoying good vibes!</p> 
                    {/* <p><img alt="Genesis cow" src={genesisCow} onClick={mooSoundAudioPLayerHandler}/></p> */}
                    <p><strong>Don't own a cow?</strong> No worries, head over to our <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/goodmorningcafe">Opensea collection</a> to grab one and join the fun.</p>
                    <p><strong>Coded and designed with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>, proud owner of <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/81086769033880357206596084476994515861067324006954129146728570266889022865409" target="_blank" rel="noreferrer">Marcel, the French Hihgland Cow #21.</a></p>
                    <p><img alt="Tchoupi cow" src={tchoupiCow} onClick={mooSoundAudioPLayerHandler}/></p>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                🐮 Moo!
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>

    </>

    );

}

export default Header;