
// App Components
import BackgroundGenerator from '../../components/bannerGenerator/BackgroundGenerator.js';

// Use-wallet for account management 
import { useWallet } from 'use-wallet';

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "../../shared/NavigationPageGlobalState";
import { useSharedDataManipulationFunctions } from "../../shared/DataManipulationFunctions";
import { useSharedCollectionsMetadata } from "../CollectionsMetadata";









const BackgroundGeneratorConfig = (props) => {

    // Handle Metamask connection 
    const wallet = useWallet();
    const activate = (connector) => wallet.connect(connector);
    const deActivate = () => wallet.reset();

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        isSelectedCowOnCanvas,                                  // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        ownerAvailableCows,
        generateAvailableAsset,
    } = useSharedNavigationPageGlobalState();
    // Collections metadata
    const { 
        GMC_OG_COLLECTION_DETAILS,
        GMC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        GMC_OG_COLLECTION_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_METADATA_ASSETS,
        GMC_OG_COLLECTION_METADATA_TRAITS,
        GMC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllGoodMorningCafeAssets,
        generateAllChubbyLandAssets
    } = useSharedCollectionsMetadata();
    // Data Manipulation
    const {
        performGenericAssetSearchFromQueryString
    } = useSharedDataManipulationFunctions();
    // Retrieve items from context --------------------------------------------------

    // Search for an asset based on either a number of 
    // based on some trait 
    function assetSearch(queryString, assetList) {
        return performGenericAssetSearchFromQueryString(GMC_OG_COLLECTION_ASSET_DIRECTORY, queryString, assetList);
    }


    // Backgrounds for Collection
    // **************************

    function generateBackgroundItemsForCollection() {
        let imageArray = [];
        
        let filePrefixDir = GMC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY;

        // BACKGROUNDS
        // -----------

        // [] Simple Good Morning Background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Light Purple",  
                src:filePrefixDir + "Cup_Artwork_8.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Light Pink",  
                src:filePrefixDir + "Cup_Artwork_7.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Coffee Mug Pattern",  
        //         src:filePrefixDir + "Cup_Artwork_5.png", 
        //         width: 1500, height: 500, x:0, y:0}]
        // );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Moo Pattern 1",  
                src:filePrefixDir + "MooPattern_Artwork_1.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Moo Pattern 2",  
                src:filePrefixDir + "MooPattern_Artwork_2.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Moo Mosaic 1",  
                src:filePrefixDir + "Moo_Mosaic_1.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Moo Mosaic 2",  
                src:filePrefixDir + "Moo_Mosaic_2.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Moo Spots",  
                src:filePrefixDir + "CowSpots_Artwork_1.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Plain Light Pink",  
                src:filePrefixDir + "Plain_Artwork_1.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "GM Plain Light Pink",  
                src:filePrefixDir + "Plain_Artwork_2.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        

        // // [1] Complex Twitter Banner GMC #1 (with Cup)
        // tempArray = [];
        // tempArray.push({ index: 0, mode: "BACKGROUND", category: "background", name: "Twitter Banner #1",  
        //                     src:filePrefixDir + "fan_arts/GM_Banner.jpeg", 
        //                     width: 640, height: 214, x:0, y:0});
        // tempArray.push({ index: 1, mode: "BACKGROUND_LAYER", 
        //                     src:filePrefixDir + "fan_arts/GM_Banner_table.png", 
        //                     width: 413, height: 122, x:433, y:152, rotation: 0});
        // imageArray.push(tempArray);

        // // [1] Complex Twitter Banner GMC #2
        // tempArray = [];
        // tempArray.push({ index: 0, mode: "BACKGROUND", category: "background", name: "Twitter Banner #2",  
        //                     src:filePrefixDir + "fan_arts/GM_Banner_2.png", 
        //                     width: 640, height: 214, x:0, y:0});
        // tempArray.push({ index: 1, mode: "BACKGROUND_LAYER", 
        //                     src:filePrefixDir + "fan_arts/GM_Banner_2_table_noCup2.png", 
        //                     width: 696, height: 199, x:323, y:151, rotation: 1});
        // imageArray.push(tempArray);

        // // [2] Simple GMOO Background
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Good MOOORning",  
        //         src:filePrefixDir + "Cadocado_GoodMorning.jpg", 
        //         height: 512, width: 512, x:0, y:0}]
        // );

        // // [3] Simple Paris Background
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Paris",  
        //        src:filePrefixDir + "Cadocado_Paris.jpg", 
        //        width: 512, height: 512, x:0, y:0}]
        // );

        // // [4] Simple Beach with Chair and Sea Background
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Beach Time",  
        //         src:filePrefixDir + "Cadocado_Beach.jpg", 
        //         width: 512, height: 512, x:0, y:0}]
        // );

        // // [3] Simple Love Background
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Love",  
        //        src:filePrefixDir + "Cadocado_Love.jpg", 
        //        width: 512, height: 512, x:0, y:0}]
        // );

        // // [5] Complex Wen Lambo Background
        // tempArray = [];
        // tempArray.push({ index: 0, mode: "BACKGROUND", category: "background", name: "Wen Lambo?",  
        //                 src:filePrefixDir + "Cadocado_WenLambo_backgroundOnly.png", 
        //                 width: 512, height: 512, x:0, y:0});
        // tempArray.push({ index: 1, mode: "BACKGROUND_LAYER", 
        //                 src:filePrefixDir + "Cadocado_WenLambo_money.png", 
        //                 width: 100, height: 100, y:130, x:78, rotation: 230});
        // // tempArray.push({ index: 2, mode: "BACKGROUND_LAYER", 
        // //                 src:filePrefixDir + "fan_arts/Cadocado_WenLambo_money.png", 
        // //                 width: 100, height: 100, x:262, y:150, rotation: 70});
        // tempArray.push({ index: 2, mode: "BACKGROUND_LAYER", 
        //                 src:filePrefixDir + "Cadocado_WenLambo_money.png", 
        //                 width: 100, height: 100, x:455, y:246, rotation: 80});
        // tempArray.push({ index: 3, mode: "BACKGROUND_LAYER", 
        //                 src:filePrefixDir + "Cadocado_WenLambo_money.png", 
        //                 width: 100, height: 100, x:249, y:450, rotation: 30});
        // imageArray.push(tempArray);

        // // [2] Simple To The Moon Background
        // imageArray.push(
        //     [{ index: 0, mode: "BACKGROUND", category: "background", name: "Moon",  
        //         src:filePrefixDir + "ToTheMoon.jpg", 
        //         height: 518, width: 290, x:0, y:0}]
        // );

        // // [1] Complex Twitter Banner GMC #1 (without Cup)
        // tempArray = [];
        // tempArray.push({ index: 0, mode: "BACKGROUND", category: "background", name: "Twitter Banner",  
        //                     src:filePrefixDir + "GM_Banner noCup.jpeg", 
        //                     width: 640, height: 214, x:0, y:0});
        // tempArray.push({ index: 1, mode: "BACKGROUND_LAYER", 
        //                     src:filePrefixDir + "GM_Banner noCup_table.png", 
        //                     width: 413, height: 122, x:433, y:152, rotation: 0});
        // imageArray.push(tempArray);

        // EMPTY TEMPLATES
        // ---------------

        filePrefixDir = GMC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY;

        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Twitter banner", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_twitter_banner.png", 
            width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Instagram Post",
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_instagram_post.png", 
            width: 1080, height: 1080, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone X", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_iphoneX.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XR", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_iphoneXR.png", 
            width: 828, height: 1792, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XS", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_iphoneXS.png", 
            width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Google Pixel 3",
            backgroundColor: "#ff7dbd",   
            src:filePrefixDir + "tpl_pixel3.png", 
            width: 1080, height: 2160, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Samsung Note 10", 
            backgroundColor: "#ff7dbd",  
            src:filePrefixDir + "tpl_samsungNote10.png", 
            width: 1080, height: 2280, x:0, y:0}]
        );

        return imageArray;

    };





    // -------------------------
    // ACCESSORIES CUSTOMISATION
    // -------------------------

    // Menu Items for GMC
    // ******************

    function generateAccessories1ItemsForCollection() {
        let availableAssets = [];
        
        let filePrefixDir = GMC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY;
        let itemId = 0;

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "MooBar.png",
                image_local_file_thumbnail: filePrefixDir + "MooBar.png",
                name: "Moo Bar",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "MooBurger.png",
                image_local_file_thumbnail: filePrefixDir + "MooBurger.png",
                name: "Vegan Burger",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "MooStrawberyMilkBox.png",
                image_local_file_thumbnail: filePrefixDir + "MooStrawberyMilkBox.png",
                name: "Moo Milk Box",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "TipsJar.png",
                image_local_file_thumbnail: filePrefixDir + "TipsJar.png",
                name: "Tips Jar",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GMCGMLeft.png",
                image_local_file_thumbnail: filePrefixDir + "GMCGMLeft.png",
                name: "GoodMorning (Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GMCGMRight.png",
                image_local_file_thumbnail: filePrefixDir + "GMCGMRight.png",
                name: "GoodMorning (right)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 174, height: 168, x:0, y:0
            }
        );

        return availableAssets;

    };

    // Meme Items for GMC
    // ******************

    function generateAccessories2ItemsForCollection() {
        let availableAssets = [];
        
        let filePrefixDir = GMC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY;
        let itemId = 0;

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "EmptyBubbleLeft.png",
                image_local_file_thumbnail: filePrefixDir + "EmptyBubbleLeft.png",
                name: "Square Bubble (Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 231, height: 225, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "EmptyBubbleRight.png",
                image_local_file_thumbnail: filePrefixDir + "EmptyBubbleRight.png",
                name: "Square Bubble (right)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 231, height: 225, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GMCBubbleLeft.png",
                image_local_file_thumbnail: filePrefixDir + "GMCBubbleLeft.png",
                name: "Round Bubble (Left)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 354, height: 320, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GMCBubbleRight.png",
                image_local_file_thumbnail: filePrefixDir + "GMCBubbleRight.png",
                name: "Round Bubble (right)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 354, height: 320, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GMCBubbleLoveRight.png",
                image_local_file_thumbnail: filePrefixDir + "GMCBubbleLoveRight.png",
                name: "Round GM Love Bubble (right)",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 354, height: 320, x:0, y:0
            }
        );

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "GMCTwitterHandle.png",
                image_local_file_thumbnail: filePrefixDir + "GMCTwitterHandle.png",
                name: "GMC Twitter Handle",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 414, height: 355, x:0, y:0
            }
        );
        
        return availableAssets;

    };






    return (

    <BackgroundGenerator 
        
        // Wallet connector
        wallet={wallet}
        walletConnect={activate}
        walletDisconnect={deActivate}

        // Global variable to track if a cow is selected on the canvas
        isSelectedCowOnCanvas={isSelectedCowOnCanvas}

        // List of available asset to select 
        collectionAssets={generateAllGoodMorningCafeAssets}     // We should pass functions, not results
        myAssets={ownerAvailableCows} 
        isCowOwner={userHasCow}

        // List of available partner asset to select 
        // Up to two partners currently allowed 
        partnerAssets={[() => { return [] }, () => { return [] }]}       // We should pass functions, not results
        
        // List of available background to select 
        availableBackground={generateBackgroundItemsForCollection()}
        availableAccessories1={generateAccessories1ItemsForCollection()}
        availableAccessories2={generateAccessories2ItemsForCollection()}
        
        allowAssetReRollConfig={
            {   
                collection: true,
                partner1: true,
                partner2: true
            }
        }

        // Generator behavior's config flags
        allowCheckUserWallet = {true}           // Do we want to lookup the user wallet to search its asset?
        allowAssetSelection={true}              // Do we want the user to pick a specific collection asset? 
        allowAssetUploadAsAssets={true}         // Do we want the user to upload custom assets?
        allowAssetUploadAsBackground={true}     // Do we want the user to upload custom background?

        // Asset search function
        assetSearchFunction={assetSearch}
        assetCollectionMetadata={GMC_OG_COLLECTION_METADATA_ASSETS}                 // Metadata for the collection

        // Configure which panels to display
        // Collection assets + Collection backgrouns are mandatory 
        displayItemConfig={
            {
                // Value are "always" for always
                //           "walletOnly" for only when user is connect and has assetDirectory
                //            "never" for never
                partner1: "never",
                partner2: "never",
                accessories1: "walletOnly",
                accessories2: "always"
            }
        }

        // Display Items legend (below each asset, background, accessories)
        displayItemLegendConfig={
            {
                assets: true,
                partner1: true,
                partner2: true,
                background: true,
                accessories1: true,
                accessories2: true
            }
        }

        // Configure labels 
        labelsConfig={
            { 
                nftYouOwnTitle: 'Available Assets. Click or Drag',      // Module title on the left hand side (When wallet is connected)
                
                availableItems: 'Assets',                               // Module title on the left hand side (When wallet is NOT connected)
                allAssetTitlePannel: "Rest Of The Herd",                // Asset panel title (all collections items)
                myAssetTitlePannel: "Cow(s) You Own",                   // Asset panel title (my items)
                partner1TitlePannel: "Partner Assets (#1)",    // (Partner) Asset panel 1 title
                partner2TitlePannel: "Partner Assets (#2)",            // (Partner) Asset panel 2 title

                accessories1TitlePannel: "Menu Items",                  // Special items panel title
                accessories1MessageToConnectWallet:                     // Message to let user connect wallet to unlock this panel (if wallet gated)
                    "You can unlock delightful menu items from the GMC Café if you own at least one Highland Cow. ",
                accessories1MessageNoNFTFoundInWallet:                  // Message when wallet is connected but no NFT match the collection
                    "Unfortunately we can't find any Highland Cows in the wallet you connected...",   
                accessories2TitlePannel: "Meme Items",                  // Special items panel title
                accessories2MessageToConnectWallet:                     // Message to let user connect wallet to unlock this panel (if wallet gated)
                    "You can unlock delightful menu items from the GMC Café if you own at least one Highland Cow. ",
                accessories2MessageNoNFTFoundInWallet:                  // Message when wallet is connected but no NFT match the collection
                    "Unfortunately we can't find any Highland Cows in the wallet you connected...",  

                yourUploadedAsset: "Upload Your Own Assets",            // Upload your own asset panel title

                availableBackgrounds: 'Available backgrounds',          // Background module title 
                officialBackgrounds: 'Preloaded',                       // Available background panel title 
                templateBackgrounds: 'Templates',                       // Available template panel title 
                yourUploadedBackgrounds: 'Upload Your Own',             // Upload your own background panel title 

                defaultTextAddedToCanvas: 
                        'Welcome to the Good Morning Cafe',             // Default text being added to canvas 
                
                okButton: "🐮 Moo!",                                    // OK Button
                collectionShortName: "GMC",                             // Collection short name
                assetName: "Cow",                                       // Label to describe an asset
                assetSearchButtonText: "Search",                        // Search button to lookup new asset   
                assetSearchLabelText: "Looking for a specific Cow? Search by number or traits" ,    
                                                                        // Search button to lookup new asset  
                partner1AssetSearchButtonText: "Partners 1 Search",     // Search button to lookup partner 1 asset   
                                                                        // Search label to lookup partner 1 asset  
                partner1AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",
                partner2AssetSearchButtonText: "Partner 2 Search",      // Search button to lookup partner 2 asset   
                                                                        // Search label to lookup partner 2 asset  
                partner2AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits", 

                instructionsTitle: "GMCafé MooMeme Creator!",           // Title of the instructions popup

                paletteColor1Text: "Pink",                              // #1 color for text - Label
                paletteColor1HexValue: "#ff7dbd",                       // #1 color for text - HexValue
                paletteColor2Text: "Limework",                          // #2 color for text - Label
                paletteColor2HexValue: "#8946ab",                       // #2 color for text - HexValue
                paletteColor3Text: "Light Purple",                      // #3 color for text - Label
                paletteColor3HexValue: "#dfb8f0",                       // #3 color for text - HexValue
                paletteColor4Text: "Pale Yellow",                       // #4 color for text - Label
                paletteColor4HexValue: "#fffecf",                       // #4 color for text - HexValue    
                paletteColor5Text: "Teal",                              // #5 color for text - Label
                paletteColor5HexValue: "#a2fedf",                       // #5 color for text - HexValue
                paletteColor6Text: "Black",                             // #6 color for text - Label
                paletteColor6HexValue: "#000000",                       // #6 color for text - HexValue
                paletteColor7Text: "White",                             // #7 color for text - Label
                paletteColor7HexValue: "#ffffff",                       // #7 color for text - HexValue

                templateColor1Text: "Pink",                              // #1 color for template - Label
                templateColor1HexValue: "#ff7dbd",                       // #1 color for template - HexValue
                templateColor2Text: "Limework",                          // #2 color for template - Label
                templateColor2HexValue: "#8946ab",                       // #2 color for template - HexValue
                templateColor3Text: "Light Purple",                      // #3 color for template - Label
                templateColor3HexValue: "#dfb8f0",                       // #3 color for template - HexValue
                templateColor4Text: "Pale Yellow",                       // #4 color for template - Label
                templateColor4HexValue: "#fffecf",                       // #4 color for template - HexValue    
                templateColor5Text: "Teal",                              // #5 color for template - Label
                templateColor5HexValue: "#a2fedf",                       // #5 color for template - HexValue
                templateColor6Text: "Black",                             // #6 color for template - Label
                templateColor6HexValue: "#000000",                       // #6 color for template - HexValue
                templateColor7Text: "White",                             // #7 color for template - Label
                templateColor7HexValue: "#ffffff",                       // #7 color for template - HexValue
                // templateColor8Text: "White",                             // #7 color for template - Label
                // templateColor8HexValue: "#ffffff",                       // #7 color for template - HexValue
            }
        }
    
    />

    )

}

export default BackgroundGeneratorConfig;