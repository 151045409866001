import { useState } from "react";
import { useBetween } from "use-between";

// Context modules
import { useSharedGlobalState } from "../shared/GlobalState";
import { useSharedDataManipulationFunctions } from "./DataManipulationFunctions";
import { useSharedCollectionsMetadata } from "../partner/CollectionsMetadata.js";

const useOpenseaFunctions = () => {

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        userIsAdmin,
        HOPEANDSEA_SERVER_URL, HOPENANDSEA_API_KEY
    } = useSharedGlobalState();
    // DataManipulation functions
    const { 
        getAssetIdFromGoodMorningCafeCollectionTokenName,
        getAssetIdFromKittyConeClassicCollectionTokenId,
        getAssetIdFromChubbyLandNFTCollectionTokenName
    } = useSharedDataManipulationFunctions();
    // Collections metadata
    const { 
        GMC_COLLETION_ASSET_DIRECTORY
    } = useSharedCollectionsMetadata();
    // Retrieve items from context --------------------------------------------------



    // States ------------------------------------------------------------------------
    const [openseaCollectionTopHolders, setOpenseaCollectionTopHolders] = useState(null);
    const [openseaDataCollectionStats, setOpenseaDataCollectionStats] = useState(null);
    const [openseaDataCollectionAssetsListFromArtist, setOpenseaDataCollectionAssetsListFromArtist] = useState(null);
    const [openseaCollectionOwnAssetDetails, setOpenseaCollectionOwnAssetDetails] = useState(null);
    const [openseaDataCollectionTopSales, setOpenseaDataCollectionTopSales] = useState(null);
    const [openseaDataCollectionMostExpensiveSales, setOpenseaDataCollectionMostExpensiveSales] = useState(null);
    const [openseaDataCollectionHistoricalSales, setOpenseaDataCollectionHistoricalSales] = useState(null);
    const [openseaDataCollectionOldestHolders, setOpenseaDataCollectionOldestHolders] = useState(null);
    const [openseaDataCollectionLoyalAssets, setOpenseaDataCollectionLoyalAssets] = useState(null);
    const [openseaCollectionSnapshotHolders, setOpenseaCollectionSnapshotHolders] = useState(null);

    // Track state of opensea report (without using hopeandsee BE)
    const [openseaLegacyReportEnabled, setOpenseaLegacyReportEnabled] = useState(true);
    
    
    // States ------------------------------------------------------------------------
    
    async function openseaCollectionStatisticsFromBackend (collectionName, ownerAddress, maxCollectionSize, isCollectionSoldOut, mintPrice, minNbSales, collectionArtistOpenseaUsername) {

        // Set legagy report mode OFF
        setOpenseaLegacyReportEnabled(false);

        const hostname = HOPEANDSEA_SERVER_URL;
        let fetchUrl = null; let jsonData = null

        // fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?slug=" + collectionName + "&report=collection_statistics";
        // jsonData = await fetch(fetchUrl).then(response => response.json());
        // // NOTE - We are assuming fetch return results!
        // if (jsonData.length === 0) {
        //     // TODO Handle error here
        //     console.err("No data retrieve from fetch call: " + fetchUrl);
        // } else {
        //     const data = jsonData.data.data;
        //     //console.log(jsonData);
        //     setOpenseaDataCollectionStats(data.collection_statistics);
        //     setOpenseaDataCollectionAssetsListFromArtist(data.assets_hold_by_creator);
        //     setOpenseaCollectionOwnAssetDetails(data.assets_adopted);
        //     setOpenseaDataCollectionTopSales(data.assets_rankedBy_nbSales);
        //     setOpenseaDataCollectionMostExpensiveSales(data.assets_rankedBy_priceSold);
        //     setOpenseaDataCollectionHistoricalSales(data.assets_historicalSales);
        //     setOpenseaCollectionTopHolders(data.assets_rankedBy_topHolders);
        // }

        const formData = new FormData();
        formData.append("X-API-KEY", HOPENANDSEA_API_KEY);

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=collection_statistics";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionStats(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_hold_by_creator";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionAssetsListFromArtist(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_adopted";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaCollectionOwnAssetDetails(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_rankedBy_nbSales";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionTopSales(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_rankedBy_priceSold";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionMostExpensiveSales(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_historicalSales";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionHistoricalSales(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_rankedBy_topHolders";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaCollectionTopHolders(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_rankedBy_oldestHolders";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionOldestHolders(data);
        }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_loyal";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaDataCollectionLoyalAssets(data);
        }

        // fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=assets_traits";
        // jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
        //     .then(response => response.json());
        // // NOTE - We are assuming fetch return results!
        // if (jsonData.length === 0) {
        //     // TODO Handle error here
        //     console.err("No data retrieve from fetch call: " + fetchUrl);
        // } else {
        //     const data = jsonData.data.data;
        //     //console.log(jsonData);
        //     // setOpenseaDataCollectionLoyalAssets(data);
        // }

        fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=holders_snapshot";
        jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
            .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (jsonData.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        } else {
            const data = jsonData.data.data;
            //console.log(jsonData);
            setOpenseaCollectionSnapshotHolders(data);
        }

        


        // ONLY FOR COLLETION EXPLORER 

        // fetchUrl = hostname + "/api/v1/getOpenseaReportForCollection.php?collection=" + collectionName + "&report=collection_traits";
        // jsonData = await fetch(fetchUrl, {method: 'POST', body: formData})
        //     .then(response => response.json());
        // NOTE - We are assuming fetch return results!
        // if (jsonData.length === 0) {
        //     // TODO Handle error here
        //     console.err("No data retrieve from fetch call: " + fetchUrl);
        // } else {
        //     const data = jsonData.data.data;
        //     //console.log(jsonData);
        //     // setOpenseaDataCollectionLoyalAssets(data);
        // }
        
    }
    

    // Function that retrive all statistics information for an opensea collection
    // Includes
    // - Overall statistics (floor price, collection supply...)
    // - Items that have not been sold yet (listed basically)
    // - Items that have changed hands multiple times
    // - Owners with the most items
    // - Snapshot feature for collection admin(s)
    // - Most expensives items
    // - Collection items available 

    // API: https://api.opensea.io/api/v1/collection/goodmorningcafe/stats
    async function openseaCollectionStatistics (collectionName, ownerAddress, maxCollectionSize, isCollectionSoldOut, mintPrice, minNbSales, collectionArtistOpenseaUsername, maxFetch) {
         
        // --------------------------------
        // PART 1 - Fetch collections stats
        // -------------------------------- 
        const resultArray = []; let fetchUrl = null;
        fetchUrl = "https://api.opensea.io/api/v1/collection/" + collectionName + "/stats";
        const nftArray = await fetch(fetchUrl).then(response => response.json());
        // NOTE - We are assuming fetch return results!
        if (nftArray.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        }
        
        // We can't do this anymore since Ben pre-minted all the cow on 1/27/2022
        // nftArray.stats.total_left_to_draw = maxCollectionSize - nftArray.stats.total_supply;
        // Instead we need to go through all the cow and exclude the one that are not created yet
        // Moving this code in the PART 2 section
        // setOpenseaDataCollectionStats(nftArray.stats);
        // resultArray["stats"] = nftArray.stats;
        
        
        // --------------------------------------------------------
        // PART 2 - Fetch collections assets not sold by Artist yet
        // --------------------------------------------------------
        fetchUrl = "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&owner=" + ownerAddress + "&limit=50";
        const nftArray2 = await fetch(fetchUrl)
        .then(response => response.json())
        
        let myCowNftCollectionArray = [];
        // NOTE - We are assuming fetch return results!
        if (nftArray2.length === 0) {
            // TODO Handle error here
            console.err("No data retrieve from fetch call: " + fetchUrl);
        }

        let nbPreMintedAssetCustomLeft = 0;
        let nbPreMintedAssetRegularLeft = 0;
        nftArray2.assets.forEach(eachNFT => {
            const cowId = getAssetIdFromGoodMorningCafeCollectionTokenName(eachNFT.name);
            const localFile = GMC_COLLETION_ASSET_DIRECTORY + cowId + ".png";
            eachNFT.image_local_file_transparent = localFile;
            // Exclusde special cows that have been reserved by Ben
            // console.log("=> PROCESSING CowId = " + cowId);
            // TODO: Customize this function to be partner specific, not GMC specific
            if (cowsThatBelongToBen(cowId) === true) {
                //console.log(" - BELONG TO BEN CowId = " + cowId);
            } else {
                if (cowId > 300) {
                    //console.log(" - ADD CUSTOM CowId = " + cowId + " file=" + localFile);
                    nbPreMintedAssetCustomLeft++;
                } else {
                    //console.log(" - ADD REGULAR CowId = " + cowId + " file=" + localFile);
                    nbPreMintedAssetRegularLeft++;
                }  
                myCowNftCollectionArray.push(eachNFT);
            }
        })

        //console.log("Left custom: " + nbPreMintedAssetCustomLeft + " | Left regular: " +nbPreMintedAssetRegularLeft + " | Total left: " + (nbPreMintedAssetRegularLeft + nbPreMintedAssetCustomLeft))
        nftArray.stats.total_left_to_draw = nbPreMintedAssetCustomLeft + nbPreMintedAssetRegularLeft;
        nftArray.stats.total_adopted = nftArray.stats.total_supply - nftArray.stats.total_left_to_draw;
        nftArray.stats.isCollectionSoldOut = isCollectionSoldOut;

        // Translation to support new opensea backend format 
        //nftArray.stats.count_allAssets = nftArray.stats.count;
        nftArray.stats.count_holdersOnlyAssets = nftArray.stats.total_adopted

        setOpenseaDataCollectionStats(nftArray.stats);
        resultArray["stats"] = nftArray.stats;

        setOpenseaDataCollectionAssetsListFromArtist(myCowNftCollectionArray);
        resultArray["creator_assets"] = myCowNftCollectionArray;
        
        

        // -----------------------------------------
        // PART 3 - Fetch collections assets details
        // ----------------------------------------- 
        // API: https://api.opensea.io/api/v1/assets?collection=goodmorningcafe&offset=X&limit=Y
        let adoptedAssetsResult = [];

        //const waittingSeconds = 1000 ;
        const maxUrlPersecond = 1; 
        let urls = [
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=0&limit=50&order_direction=asc",
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=50&limit=50&order_direction=asc",
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=100&limit=50&order_direction=asc",
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=150&limit=50&order_direction=asc",
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=200&limit=50&order_direction=asc",
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=250&limit=50&order_direction=asc",
            "https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=300&limit=50&order_direction=asc"
        ];
        
        for(let start = 0; start < urls.length; start+= maxUrlPersecond){
            const urltoRequest = urls.slice(start, start+maxUrlPersecond);
            const promisesRequest = urltoRequest.map((endpoint) => fetch(endpoint, {
                method: 'GET',
                headers: {
                    'X-API-KEY': 'af4947ad93184086b679cef952cf6483'
                }
            }).then(response => response.json()));
            await Promise.all(promisesRequest).then((res) => {
                adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(res[0], adoptedAssetsResult);
            });
            //Uncomment if we get rate limited             
            //await setTimeout(waittingSeconds);
        }

        // const resultCallArray = await Promise.all([
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=0&limit=50&order_direction=asc").then(response => response.json()),
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=50&limit=50&order_direction=asc").then(response => response.json()),
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=100&limit=50&order_direction=asc").then(response => response.json()),
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=150&limit=50&order_direction=asc").then(response => response.json()),
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=200&limit=50&order_direction=asc").then(response => response.json()),
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=250&limit=50&order_direction=asc").then(response => response.json()),
        //     fetch("https://api.opensea.io/api/v1/assets?collection=" + collectionName + "&offset=300&limit=50&order_direction=asc").then(response => response.json())
        // ]);
        
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[0], adoptedAssetsResult); 
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[1], adoptedAssetsResult); 
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[2], adoptedAssetsResult);  
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[3], adoptedAssetsResult);  
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[4], adoptedAssetsResult);  
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[5], adoptedAssetsResult);  
        // adoptedAssetsResult = processResultsFromPartialOSCollectionResponse(resultCallArray[6], adoptedAssetsResult);  

        setOpenseaCollectionOwnAssetDetails(adoptedAssetsResult);
        resultArray["adopted_assets"] = adoptedAssetsResult;

        // UNCOMMENT TO GENERATE COLLETION METADATA FOR TEXT SEARCH
        // console.log("adoptedAssetsResult=", adoptedAssetsResult);
        // let result = "";
        // for(var i=0; i<adoptedAssetsResult.length; i++) {
        //     const cowId = getAssetIdFromGoodMorningCafeCollection(adoptedAssetsResult[i].name);
        //     result += '\n{ file: "HL' + cowId + '", name: "Highland #' + cowId + '", properties: [{'
        //     const traits = adoptedAssetsResult[i].traits;
        //     for (var j=0; j<traits.length; j++) {
        //         const trait = traits[j];
        //         result += '"' + trait.trait_type + '" : "' + trait.value + '",';
        //     }
        //     result += '}], index: ' + cowId + ', tokenId: "' + adoptedAssetsResult[i].token_id + '" },';
        // }
        // console.log(result);

        

        // ----------------------------------------------------------------
        // Sort the assets per total number of sales
        // Step 1: create a new array structured, organize by num_sales
        let topNbSalesArray = [];
        let totalSalesSecondary = 0;

        adoptedAssetsResult.forEach(item => {
            const totalSales = item.num_sales;

            //Track the max cowId drawn to detect last custom cow
            //const cowId = getAssetIdFromGoodMorningCafeCollectionTokenName(item.name);

            // Only incldues sales >0
            if (totalSales > 0) {

                const result = [];
                result["image_preview_url"] = item.image_preview_url;
                result["image_thumbnail_url"] = item.image_thumbnail_url;
                result["opensea_asset_url"] = item.permalink;
                result["asset_name"] = item.name;
                result["name_hashtag"] = getAssetIdFromGoodMorningCafeCollectionTokenName(item.name);
                result["num_sales"] = totalSales;

                // Only care avbout total number of sales 
                // Thare are about the minNbSales provided to the function 
                if (totalSales >= minNbSales) {
                    //console.log(n + " - Array: " + topNbSalesArray[totalSales] + " num_sales:" + totalSales);
                    if (!topNbSalesArray[totalSales]) {
                        topNbSalesArray[totalSales] = [result];
                    } else {
                        topNbSalesArray[totalSales].push(result);
                    }
                }

                // Total sales on Opensea are total sales >1
                // Since the first sale is from the owner (in a manually sold collection - ie. not minted on contract)
                if (totalSales > 1) {
                    totalSalesSecondary+=totalSales;
                }
            }
        });

        // Step 2: Sort that array in descending order
        topNbSalesArray.reverse();
        setOpenseaDataCollectionTopSales(topNbSalesArray);
        resultArray["topNbSales_assets"] = topNbSalesArray;
        
        // Update count of custom vs regular cows
        if (!isCollectionSoldOut) {
            nftArray.stats.total_left_custom_to_draw = nbPreMintedAssetCustomLeft;
            nftArray.stats.total_left_regular_to_draw = nbPreMintedAssetRegularLeft;
        } else {
            nftArray.stats.total_left_custom_to_draw = 0;
            nftArray.stats.total_left_regular_to_draw = 0;
        }
        nftArray.stats.total_sales_secondary = totalSalesSecondary;
        // Refresh that information into the state 
        setOpenseaDataCollectionStats(nftArray.stats);



        // ----------------------------------------------------------------
        // Sort the assets per last sale price
        // Step 1: create a new array structured, organize by num_sales
        let topSalesPriceArray = [];
        adoptedAssetsResult.forEach(item => {

            // Only include cow that have been sold once and for >0.1 ETH
            if (item.last_sale != null 
                && item.last_sale.total_price > mintPrice * 10000000000000000  // 0.1 ETH = 100000000000000000
                && item.num_sales >= minNbSales) {
                const totalSales = item.last_sale.total_price;
                const result = [];
                result["image_preview_url"] = item.image_preview_url;
                result["image_thumbnail_url"] = item.image_thumbnail_url;
                result["opensea_asset_url"] = item.permalink;
                result["asset_name"] = item.name;
                result["e_asset_name_hashtag"] = getAssetIdFromGoodMorningCafeCollectionTokenName(item.name);
                result["transaction_price"] = (totalSales / 1000000000000000000).toFixed(2);
                topSalesPriceArray.push(result);
            }
        });
        
        // Step 2: Sort that array in descending order
        topSalesPriceArray.sort(function (a,b) {
            if (a.last_sale_price > b.last_sale_price) {
                return -1;
            }
            if (a.last_sale_price < b.last_sale_price) {
                return 1;
            }
            return 0;
        });
        setOpenseaDataCollectionMostExpensiveSales(topSalesPriceArray);
        resultArray["topExpensiveCowSold_assets"] = topSalesPriceArray;
        


        
        // -----------------------------------------------
        // PART 4 - Fetch collections last owners details
        // ----------------------------------------------
        // Fetch all transfer order from the latest to the oldest
        // Everytime we found a cow that has been transfert we mark its owner and stop looking for it later on the list of events
        let eventAssetsResult = [];
        // API: https://api.opensea.io/api/v1/events?account_address=0xb3457c2065fd1f384e9f05495251f2894d1659b6&only_opensea=false&offset=X&limit=Y

        let nbResultsFetch = 0; let cursor = "";
        while (cursor !== null) {    // Return all the results 

            //const waittingSeconds = 1000 ;
            //const maxUrlPersecond = 1; 
            urls = [
                "https://api.opensea.io/api/v1/events?collection_slug=" + collectionName + "&event_type=transfer&only_opensea=false&cursor=" + cursor,
            ];
            
            for(let start = 0; start < urls.length; start+= maxUrlPersecond){
                const urltoRequest = urls.slice(start, start+maxUrlPersecond);
                const promisesRequest = urltoRequest.map((endpoint) => fetch(endpoint, {
                    method: 'GET',
                    headers: {
                        'X-API-KEY': 'af4947ad93184086b679cef952cf6483'
                    }
                }).then(response => response.json()));
                await Promise.all(promisesRequest).then((res) => {
                    eventAssetsResult = processResultsFromEventOSCollectionResponse(res, eventAssetsResult);
                    nbResultsFetch += res[0].asset_events.length;
                    cursor = res[0].next;
                    //console.log("Cursor=" + cursor + " nbResultsFetch=" + nbResultsFetch);
                });
                //Uncomment if we get rate limited             
                //await setTimeout(waittingSeconds);
            }

        }

        // ----------------------------------------------------------------
        // Parse the asset and build a map of 
        // owner to number of items own 
        const topHoldersArray = [];
        const foundCowFromTotalInventory = [];
        for (var i=0; i<maxCollectionSize; i++) {
            foundCowFromTotalInventory[i] = false;
        }
        
        // Process the results
        // Only looking at transfert with a valid transaction
        // For those valid entries, lookup for userName (could be missing in two different locations) + userAddress
        // Only track the item transfered once. Use to array above to keep track of assets being identified to their most recent owners
        // Check if assets have not been match, are keep track of them accordingly
        eventAssetsResult.forEach(item => {

            //console.log("EVENT ITEM=", item);

            // Only process event that have a transaction associated with them 
            if (item.transaction !== null) {

                const holderAddress = item.to_account.address;
                
                // Sometime people don't have a username attached to their Opensea account
                // So in this case we setup the username for them 
                let holderName;
                if (item.to_account.user === null || item.to_account.user.username === null) {
                    holderName = "Unnamed (" + holderAddress.slice(0,4) + "..." + holderAddress.slice(-4) + ")";
                } else {
                    holderName = item.to_account.user.username
                }
                const assetName = item.asset.name;
                const assetTokenId = item.asset.token_id; 

                let assetId = -1;
                if (collectionName === "goodmorningcafe") {
                    assetId = getAssetIdFromGoodMorningCafeCollectionTokenName(assetName);
                } else if (collectionName === "kitty-cones-classic-collection") {
                    assetId = getAssetIdFromKittyConeClassicCollectionTokenId(assetTokenId);
                } else if (collectionName === "chubbylandnft") {
                    assetId = getAssetIdFromChubbyLandNFTCollectionTokenName(assetTokenId);
                } else {
                    throw new Error("Unkown collection: " + collectionName + " please update implementation!");
                }

                if (!foundCowFromTotalInventory[assetId-1]) {
                    foundCowFromTotalInventory[assetId-1] = true;

                    // Add a nbCowOnwByUser to each cow entry
                    let nbCowOnwByUser = 1;
                    if (topHoldersArray[holderName]) {
                        // Increment value 
                        nbCowOnwByUser = topHoldersArray[holderName].length +1;
                        // Override previous value
                        for (var j=0; j<topHoldersArray[holderName].length; j++) {
                            topHoldersArray[holderName][j]["nbCow_owned"] = nbCowOnwByUser;
                        }
                    } 

                    const result = [];
                    result["image_preview_url"] = item.asset.image_preview_url;
                    result["image_thumbnail_url"] = item.asset.image_thumbnail_url;
                    result["permalink"] = item.asset.permalink;
                    result["asset_name"] = assetName;
                    result["assetName_hashtag"] = assetId;
                    result["nbCow_owned"] = nbCowOnwByUser;
                    result["winner_account_username"] = holderName;
                    result["winner_account_address"] = holderAddress;
                    result["e_userOpenseaSeaProfileLink"] = "https://opensea.io/" + holderAddress;
                    
                    if (!topHoldersArray[holderName]) {
                        topHoldersArray[holderName] = [result];
                    } else {
                        topHoldersArray[holderName].push(result);
                    }
                } else {
                    //console.log("We already found owner of assetId=" + assetId);
                }

            } else {
                //console.log("No transaction for transfert of item name=" + item.asset.name);
            }

        });


        // ----------------------------------
        // PART 5 - Fetching historical sales
        // ----------------------------------
        // Fetch all transfer order from the latest to the oldest
        // Everytime we found a cow that has been transfert we mark its owner and stop looking for it later on the list of events
        let historicalSalesEventAssetsResult = [];
        // API: https://api.opensea.io/api/v1/events?account_address=0xb3457c2065fd1f384e9f05495251f2894d1659b6&only_opensea=false&offset=X&limit=Y


        nbResultsFetch = 0; cursor = "";
        while (cursor !== null || nbResultsFetch < maxFetch) {

            // const waittingSeconds = 1000 ;
            // const maxUrlPersecond = 1; 
            urls = [
                "https://api.opensea.io/api/v1/events?collection_slug=" + collectionName + "&event_type=successful&only_opensea=false&cursor=" + cursor
                // "https://api.opensea.io/api/v1/events?collection_slug=" + collectionName + "&event_type=successful&only_opensea=false&offset=300&limit=300",
                // "https://api.opensea.io/api/v1/events?collection_slug=" + collectionName + "&event_type=successful&only_opensea=false&offset=600&limit=300"
            ];
            
            for(let start = 0; start <= urls.length; start+= maxUrlPersecond){
                const urltoRequest = urls.slice(start, start+maxUrlPersecond);
                const promisesRequest = urltoRequest.map((endpoint) => fetch(endpoint, {
                    method: 'GET',
                    headers: {
                        'X-API-KEY': 'af4947ad93184086b679cef952cf6483'
                    }
                }).then(response => response.json()));
                await Promise.all(promisesRequest).then((res) => {
                    historicalSalesEventAssetsResult = processResultsFromEventOSCollectionResponse(res, historicalSalesEventAssetsResult);
                    
                    //console.log("historicalSalesEventAssetsResult", historicalSalesEventAssetsResult);
                    //console.log("res", res);
                    
                    // TODO: Not sure why sometime the result return empty here!
                    if (res.length > 0) {
                        nbResultsFetch += res[0].asset_events.length;
                        cursor = res[0].next;
                        //console.log("Cursor=" + cursor + " nbResultsFetch=" + nbResultsFetch);
                    } 
                });
                
                //Uncomment if we get rate limited             
                //await setTimeout(waittingSeconds);
            }

        }

        // Collect list of all historical transactions
        // Exclude the event that are mints 
        const historicalSales = [];
        historicalSalesEventAssetsResult.forEach(item => {

            // Only process event that are not 
            // direct sales from the artist
            if (item.seller.user === null || item.seller.user.username !== collectionArtistOpenseaUsername) {
                
                //console.log("HISTORICAL EVENT ITEM=", item);
            
                const holderAddress = item.winner_account.address;
                // Sometime people don't have a username attached to their Opensea account
                // So in this case we setup the username for them 
                let holderAbbreviatedName;
                let holderFullName;
                
                if (item.winner_account.user === null || item.winner_account.user.username === null) {
                    holderAbbreviatedName = "" + holderAddress.slice(0,4) + "..." + holderAddress.slice(-4) + "";
                    holderFullName = holderAddress;
                } else {
                    holderAbbreviatedName = "" + item.winner_account.user.username.slice(0,4) + "..." + item.winner_account.user.username.slice(-4) + ""; 
                    holderFullName = item.winner_account.user.username;
                }
                const assetName = item.asset.name;
                const assetTokenId = item.asset.token_id; 

                let assetId = -1;
                if (collectionName === "goodmorningcafe") {
                    assetId = getAssetIdFromGoodMorningCafeCollectionTokenName(assetName);
                } else if (collectionName === "kitty-cones-classic-collection") {
                    assetId = getAssetIdFromKittyConeClassicCollectionTokenId(assetTokenId);
                } else if (collectionName === "chubbylandnft") {
                    assetId = getAssetIdFromChubbyLandNFTCollectionTokenName(assetTokenId);
                } else {
                    throw new Error("Unkown collection: " + collectionName + " please update implementation!");
                }

                const result = [];
                result["image_preview_url"] = item.asset.image_preview_url;
                result["image_thumbnail_url"] = item.asset.image_thumbnail_url;
                result["opensea_asset_url"] = item.asset.permalink;
                result["asset_name"] = assetName;
                result["assetName_hashtag"] = assetId;
                result["e_userAbbreviatedName"] = holderAbbreviatedName;
                result["winner_account_username"] = holderFullName;
                
                result["winner_account_address"] = holderAddress;
                result["e_userOpenseaSeaProfileLink"] = "https://opensea.io/" + holderAddress;
                
                // Purchase date
                //console.log("Timestamp = " + item.transaction.timestamp);
                const options = { timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const txDate = new Date(item.transaction.timestamp);
                const formattedDate = txDate.toLocaleDateString("en-US", options);
                result["e_transaction_date_short"] = formattedDate;

                // Purchase price
                const totalSales = item.total_price;
                const usdPrice = item.payment_token.usd_price;
                result["transaction_price"] = (totalSales / 1000000000000000000).toFixed(2);
                result["last_sale_price_usd"] = Math.round(usdPrice * result["last_sale_price_eth"], 2);

                result["name_hashtag"] = assetId;
                historicalSales.push(result);


            } else {
                //console.log("This sale was a direct sale");
            }
        });

        //console.log("EventArray", eventAssetsResult);
        setOpenseaDataCollectionHistoricalSales(historicalSales.slice(0, 50))
        resultArray["historicalSales"] = historicalSales;
        
        if (collectionName === "goodmorningcafe") {

            const maxCustomId =  maxCollectionSize - nftArray.stats.total_left_custom_to_draw ;
            const maxRegularId = 299 - nftArray.stats.total_left_regular_to_draw;

            // Add all unfound cow from the inventory in an empty list
            for (let i=0; i<maxCollectionSize-1; i++) {
                const assetId = i+1;
                if (!cowsThatBelongToBen(assetId)) {
                    if (validCowIdForCollection(assetId, maxRegularId, maxCustomId)) {
                        const unmatchCowHolders = "Mysterious owner(s)";
                        if (!foundCowFromTotalInventory[i]) {
                            const result = [];
                            const localImage = GMC_COLLETION_ASSET_DIRECTORY + assetId + ".png";
                            result["image_preview_url"] = localImage;
                            result["image_thumbnail_url"] = localImage;
                            result["permalink"] = "";
                            result["assetName"] = "Highland #" + assetId;
                            result["assetName_hashtag"] = assetId;
                            result["nbCow_owned"] = 0;
                            result["userName"] = unmatchCowHolders;
                            result["userAddress"] = "N/A";
                            if (!topHoldersArray[foundCowFromTotalInventory]) {
                                topHoldersArray[foundCowFromTotalInventory] = [result];
                            } else {
                                topHoldersArray[foundCowFromTotalInventory].push(result);
                            }
                        }
                    }
                }
            }
        } else if (collectionName === "kitty-cones-classic-collection") {
            //console.log("Do we need to implement logic here? ")
        } else if (collectionName === "chubbylandnft") {
            //console.log("Do we need to implement logic here? ")
        } else {
            throw new Error("Unkown collection: " + collectionName + " please update implementation!");
        }

        // Step 2: Convert the associate array into a temporary array
        // That we can sort 
        var tuples = [];
        for (var key in topHoldersArray) tuples.push([key, topHoldersArray[key]]);
        // Sort the temporary array per number of cow own 
        tuples.sort(function(a, b) {
            a = a[1].length;
            b = b[1].length;
            return a < b ? -1 : (a > b ? 1 : 0);
        });
        // Group per nbCow_owned (ie. lenght of each child array)
        const groupedTopHoldersArray = [];
        // Create a mapping array of nbCowOwn to index 
        let nbCowOwnToIndex = [];
        let nbCowToArrayIndex = 0;
        for (let i = 0; i < tuples.length; i++) {
            const value = tuples[i][1];
            var nbCow_owned = value.length;
            if (nbCow_owned in nbCowOwnToIndex) {
                // Do nothing
            } else {
                // nbCow_owned has alredy been sored in tuples
                // so index should go from highest nbCow_owned to lowers 
                nbCowOwnToIndex[nbCow_owned] = nbCowToArrayIndex++; 
            }
        }
        // Then we group together by nbCowOwnToIndex
        // By creating an array 
        for (let i = 0; i < tuples.length; i++) {
            //const key = tuples[i][0];
            const value = tuples[i][1];
            const nbCow_owned = value.length;
            const index = nbCowOwnToIndex[nbCow_owned];       // Return the index to store that group of nbCow_owned
            if (!groupedTopHoldersArray[index]) {
                groupedTopHoldersArray[index] = [value];
            } else {
                groupedTopHoldersArray[index].push(value);
            }
        }
        // Reverse sorting
        groupedTopHoldersArray.reverse();
        
        // Step 3: Store results
        resultArray["holders_ranked"] = groupedTopHoldersArray;
        setOpenseaCollectionTopHolders(groupedTopHoldersArray);

        // For debug purposes only 
        console.log("Final array...");
        console.log("OpenseaCollectionStats: ", resultArray);

    }



    // PRIVATE FUNCTIONS

    // Take a response from the fetch api.opensea.io/api/v1/assets? API
    // And process them to augment them with necessary value to 
    // be exposted to the UI
    function processResultsFromPartialOSCollectionResponse(response, sourceArrayToAugment) {
        
        //console.log("processResultsFromPartialOSCollectionResponse = RESPONSE", response);

        // Parse first result 
        const results = response.assets;
                        
        if (results.length === 0) {
            // No results
            // Array remain empty
        } else {
            results.forEach(eachNFT => {
                const localFile = GMC_COLLETION_ASSET_DIRECTORY + getAssetIdFromGoodMorningCafeCollectionTokenName(eachNFT.name) + ".png";
                eachNFT.image_local_file_transparent = localFile;
                sourceArrayToAugment.push(eachNFT);
            })
        }

        return sourceArrayToAugment;
    } 

        // Take a response from the fetch api.opensea.io/api/v1/asset_events? API
    // And process them to augment them with necessary value to 
    // be exposted to the UI
    function processResultsFromEventOSCollectionResponse(response, sourceArrayToAugment) {
        
        if (response.length === 0) {
            //TODO: This all should never return no results!
            return sourceArrayToAugment;
        }
        // Parse first result 
        const results = response[0].asset_events;                
        if (results.length === 0) {
            // No results
            // Array remain empty
        } else {
            results.forEach(eachNFT => {
                // const localFile = GMC_COLLETION_ASSET_DIRECTORY + getAssetIdFromGoodMorningCafeCollection(eachNFT.name) + ".png";
                // eachNFT.image_local_file_transparent = localFile;
                sourceArrayToAugment.push(eachNFT);
            })
        }

        //RETURN", sourceArrayToAugment);
        return sourceArrayToAugment;
    } 

    // Check if a cowId belong to Ben
    function cowsThatBelongToBen(cowId) {
        if (
                cowId == 1
            || cowId == 206
            || cowId == 223
            || cowId == 235
            || cowId == 238

            // // These value are tempoary until ben create them
            // || (cowId > 277 && cowId < 300)
            // || (cowId > 307)

            ) {
            return true;
        } else {
            return false; 
        }
    }

    // Check if a cowId is a valid cowId
    // based on the total number of regular mad and custom cow made
    // Since custom starts at 300 and regular are <=300
    function validCowIdForCollection(cowId, maxRegularId, maxCustomId) {
        //console.log("validCowIdForCollection - cowId" + cowId + " maxRegularId=" + maxRegularId + " maxCustomId " + maxCustomId);
        if (cowId === 248) { // This cow was never created to compensation for the double #96
            return false;
        }
        if (cowId<=maxRegularId) {
            return true;
        }
        if (cowId>=301 && cowId<=maxCustomId) {
            return true; 
        }
        return false;
    }





    return {
        openseaCollectionTopHolders, setOpenseaCollectionTopHolders,
        openseaDataCollectionStats, setOpenseaDataCollectionStats,
        openseaDataCollectionAssetsListFromArtist, setOpenseaDataCollectionAssetsListFromArtist,
        openseaCollectionOwnAssetDetails, setOpenseaCollectionOwnAssetDetails,
        openseaDataCollectionTopSales, setOpenseaDataCollectionTopSales,
        openseaDataCollectionMostExpensiveSales, setOpenseaDataCollectionMostExpensiveSales,
        openseaDataCollectionHistoricalSales, setOpenseaDataCollectionHistoricalSales,
        openseaDataCollectionOldestHolders, setOpenseaDataCollectionOldestHolders,
        openseaDataCollectionLoyalAssets, setOpenseaDataCollectionLoyalAssets,
        openseaCollectionSnapshotHolders, setOpenseaCollectionSnapshotHolders,
        openseaCollectionStatistics,
        openseaCollectionStatisticsFromBackend,
        openseaLegacyReportEnabled
    };

};

export const useSharedOpenseaFunctions = () => useBetween(useOpenseaFunctions);