
// App Components
import BackgroundGenerator from '../../components/bannerGenerator/BackgroundGenerator.js';

// Use-wallet for account management 
import { useWallet } from 'use-wallet';

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";
import { useSharedNavigationPageGlobalState } from "../../shared/NavigationPageGlobalState";
import { useSharedDataManipulationFunctions } from "../../shared/DataManipulationFunctions";
import { useSharedCollectionsMetadata } from "../CollectionsMetadata";




// ------------------------
// BACKGROUND CUSTOMISATION
// ------------------------

const BackgroundGeneratorConfig = (props) => {

    // Handle Metamask connection 
    const wallet = useWallet();
    const activate = (connector) => wallet.connect(connector);
    const deActivate = () => wallet.reset();

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        isSelectedCowOnCanvas,                                  // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Global nav
    const { 
        ownerAvailableCows,
        generateAvailableAsset,
    } = useSharedNavigationPageGlobalState();    
    // Collections metadata
    const { 
        WHISKERS_OG_COLLECTION_DETAILS,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_METADATA_ASSETS,
        WHISKERS_OG_COLLECTION_METADATA_TRAITS,
        WHISKERS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        WHISKERS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllWhiskersAssets,
        generateAllKittyConesClassicAssets,
        generateAllGoodMorningCafeAssets
    } = useSharedCollectionsMetadata();
    // Data Manipulation
    const {
        performGenericAssetSearchFromQueryString
    } = useSharedDataManipulationFunctions();
    // Retrieve items from context --------------------------------------------------

    

    // Backgrounds for Collection
    // **************************

    function generateBackgroundItemsForCollection() {
        
        let imageArray = [];
        let filePrefixDir = WHISKERS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY;
        let itemId = 0;
    
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Sunlight",  
                src:filePrefixDir + "sunlight.PNG", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Abyss",  
                src:filePrefixDir + "abyss.PNG", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Cyan",  
                src:filePrefixDir + "cyan.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Midnight",  
                src:filePrefixDir + "midnight.PNG", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Lime",  
                src:filePrefixDir + "lime.png", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Pixker",  
                src:filePrefixDir + "pixker.PNG", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Trenches",  
                src:filePrefixDir + "trenches.PNG", 
                width: 1500, height: 500, x:0, y:0}]
        );
        // [] Custom background
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "background", name: "Twilight",  
                src:filePrefixDir + "twilight.PNG", 
                width: 1500, height: 500, x:0, y:0}]
        );
    
    
        // EMPTY TEMPLATES
        // ---------------
    
        filePrefixDir = WHISKERS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY;

        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Twitter banner", 
               backgroundColor: "#eecaa3",  
               src:filePrefixDir + "tpl_twitter_banner.png", 
               width: 1500, height: 500, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Instagram Post",
               backgroundColor: "#eecaa3",    
               src:filePrefixDir + "tpl_instagram_post.png", 
               width: 1080, height: 1080, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone X", 
               backgroundColor: "#eecaa3",  
               src:filePrefixDir + "tpl_iphoneX.png", 
               width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XR", 
               backgroundColor: "#eecaa3",  
               src:filePrefixDir + "tpl_iphoneXR.png", 
               width: 828, height: 1792, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "iPhone XS", 
               backgroundColor: "#eecaa3",  
               src:filePrefixDir + "tpl_iphoneXS.png", 
               width: 1125, height: 2436, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Google Pixel 3", 
               backgroundColor: "#eecaa3",  
               src:filePrefixDir + "tpl_pixel3.png", 
               width: 1080, height: 2160, x:0, y:0}]
        );
        imageArray.push(
            [{ index: 0, mode: "BACKGROUND", category: "template", name: "Samsung Note 10", 
               backgroundColor: "#eecaa3",  
               src:filePrefixDir + "tpl_samsungNote10.png", 
               width: 1080, height: 2280, x:0, y:0}]
        );
    
        return imageArray;

    };





    // -------------------------
    // ACCESSORIES CUSTOMISATION
    // -------------------------

    // Accessories 1
    // *************

    function generateAccessories1ForCollection() {

        let availableAssets = [];
        
        let filePrefixDir = WHISKERS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY;
        let itemId = 0;

        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "dagger.png",
                image_local_file_thumbnail: filePrefixDir + "dagger.png",
                name: "Dagger",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "dark_spirit.png",
                image_local_file_thumbnail: filePrefixDir + "dark_spirit.png",
                name: "Dark Spirit",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "earth_spirit.png",
                image_local_file_thumbnail: filePrefixDir + "earth_spirit.png",
                name: "Earth Spirit",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "fire_spirit.png",
                image_local_file_thumbnail: filePrefixDir + "fire_spirit.png",
                name: "Fire Spirit",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "lightning_bug.png",
                image_local_file_thumbnail: filePrefixDir + "lightning_bug.png",
                name: "Lightning Bug",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "mushroom.png",
                image_local_file_thumbnail: filePrefixDir + "mushroom.png",
                name: "Mushroom",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "shamrock.png",
                image_local_file_thumbnail: filePrefixDir + "shamrock.png",
                name: "Shamrock",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "water_spirit.png",
                image_local_file_thumbnail: filePrefixDir + "water_spirit.png",
                name: "Water Spirit",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );
        availableAssets.push(
            {
                type: "ACCESSORIES",
                id: 'accessoriesItem' + itemId++,
                image_local_file_transparent: filePrefixDir + "wind_spirit.png",
                image_local_file_thumbnail: filePrefixDir + "wind_spirit.png",
                name: "Wind Spirit",
                scale: 1,               // -1 to flip the element
                addedToCanvas: false,    // Allow to only add the cow once to the canvas
                width: 300, height: 300, x:0, y:0
            }
        );


        return availableAssets;

    };

    // Accessories 2
    // *************

    function generateAccessories2ForCollection() {
        let availableAssets = [];
        //let filePrefixDir = WHISKERS_ACCESSORIES_ASSET_DIRECTORY;
        return availableAssets;
    };



    // Search for an asset based on either a number of 
    // based on some trait 
    function assetSearch(queryString, assetList) {
        return performGenericAssetSearchFromQueryString(WHISKERS_OG_COLLECTION_ASSET_DIRECTORY, queryString, assetList, WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }

    return (

        <BackgroundGenerator 
        
            // Wallet connector
            wallet={wallet}
            walletConnect={activate}
            walletDisconnect={deActivate}

            // List of available asset to select 
            collectionAssets={generateAllWhiskersAssets}                     // We should pass functions, not results 
            myAssets={ownerAvailableCows} 
            isCowOwner={userHasCow}

            // Global variable to track if a cow is selected on the canvas
            isSelectedCowOnCanvas={isSelectedCowOnCanvas}

            // List of available partner asset to select 
            partnerAssets={[() => { return [] }, () => { return [] } ]}      // We should pass functions, not results 
            // List of available background to select 
            availableBackground={generateBackgroundItemsForCollection()}
            availableAccessories1={generateAccessories1ForCollection()}
            availableAccessories2={generateAccessories2ForCollection()}

            allowAssetReRollConfig={
                {   
                    collection: true,
                    partner1: true,
                    partner2: true
                }
            }

            // Generator behavior's config flags
            allowCheckUserWallet = {true}           // Do we want to lookup the user wallet to search its asset?
            allowAssetSelection={true}              // Do we want the user to pick a specific collection asset? (ie. search)
            allowAssetUploadAsAssets={true}         // Do we want the user to upload custom assets?
            allowAssetUploadAsBackground={true}     // Do we want the user to upload custom background?

            assetSearchFunction={assetSearch}
            assetCollectionMetadata={WHISKERS_OG_COLLECTION_METADATA_ASSETS}                          // Metadata for the collection

            // Configure which panels to display
            // Collection assets + Collection backgrouns are mandatory 
            displayItemConfig={
                {
                    // Value are "always" for always
                    //           "walletOnly" for only when user is connect and has assetDirectory
                    //            "never" for never
                    partner1: "never",
                    partner2: "never",
                    accessories1: "always",
                    accessories2: "never"
                }
            }

            // Display Items legend (below each asset, background, accessories)
            displayItemLegendConfig={
                {
                    assets: true,
                    partner1: true,
                    partner2: true,
                    background: true,
                    accessories1: true,
                    accessories2: true
                }
            }

            // Configure labels 
            labelsConfig={
                { 
                    nftYouOwnTitle: 'Whisker(s) You Own',                   // Module title on the left hand side (When wallet is connected)
                    
                    availableItems: 'Assets',                               //         
                    allAssetTitlePannel: "Whiskers",                        // Asset panel title (all collections items)
                    myAssetTitlePannel: "My Whiskers",                      // Asset panel title (my items)
                    partner1TitlePannel: "Partner Assets (TBD)",            // (Partner) Asset panel 1 title
                    partner2TitlePannel: "Partner Assets (TBD)",            // (Partner) Asset panel 2 title

                    accessories1TitlePannel: "Whiskers Accessories Items",  // Accessories panel 1 title 
                    accessories1MessageToConnectWallet:                     // Message to let user connect wallet to unlock this panel (if wallet gated)
                        "You can unlock delightful items from the collection if you own at least one Whiskers NFT.",
                    accessories1MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any Whiskers NFT in the wallet you connected to let you unlock this section...",   
                    accessories2TitlePannel: null,                          // Accessories panel 2 title 
                    accessories2MessageToConnectWallet:                     // Message to let user connect wallet to unlock accessories module 1 (if wallet gated)
                        "You can unlock exclusive items from the collection if you own at least one Whiskers NFT.",
                    accessories2MessageNoNFTFoundInWallet:                  // Message when wallet isn't compatible to unlock this section
                        "Unfortunately we can't find any Whiskers NFT in the wallet you connected to let you unlock this section...", 

                    yourUploadedAsset: "Upload your own asset",             // Upload your own asset panel title 
                    
                    availableBackgrounds: 'Available backgrounds',          // 
                    officialBackgrounds: 'Official backgrounds',            // Available background panel title 
                    templateBackgrounds: 'Template backgrounds',            // Available template panel title 
                    yourUploadedBackgrounds: 'Upload your own',             // Upload your own background panel title 

                    defaultTextAddedToCanvas: 
                            'The Great Pond',                               // Default text being added to canvas 
                    
                    okButton: "OK",                                         // OK Button
                    collectionShortName: "WHISKERS",                        // Collection short name
                    assetName: "Whisker",                                   // Label to describe an asset
                    assetSearchButtonText: "Whiskers Search" ,              // Search button to lookup new asset  
                    assetSearchLabelText: "Looking for a specific Whisker? Search by number/properties" ,    
                                                                            // Search button to lookup new asset 
                    partner1AssetSearchButtonText: "Partners 1 Search",     // Search button to lookup partner 1 asset   
                                                                            // Search label to lookup partner 1 asset  
                    partner1AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",
                    partner2AssetSearchButtonText: "Partner 2 Search",      // Search button to lookup partner 2 asset   
                                                                            // Search label to lookup partner 2 asset  
                    partner2AssetSearchLabelText: "Looking for a specific Partner NFT? Search by token ID or traits",  
                     

                    instructionsTitle: "Whiskers Banner Generator!",        // Title of the instructions popup

                    paletteColor1Text: "Black",
                    paletteColor1HexValue: "#272827", 
                    paletteColor2Text: "White",
                    paletteColor2HexValue: "#fefbf4",
                    paletteColor3Text: "Brown",
                    paletteColor3HexValue: "#a87f55", 
                    // paletteColor4Text: "Brown",
                    // paletteColor4HexValue: "#a87f55", 
                    // paletteColor5Text: "Turqoise",
                    // paletteColor5HexValue: "#539f8d",
                    // paletteColor6Text: "Black",
                    // paletteColor6HexValue: "#000000",  
                    // paletteColor7Text: "White",
                    // paletteColor7HexValue: "#ffffff",

                    templateColor1Text: "Sunlight",
                    templateColor1HexValue: "#eecaa3",  
                    templateColor2Text: "Cyan",
                    templateColor2HexValue: "#95e6d6",
                    templateColor3Text: "Lime",
                    templateColor3HexValue: "#cadb99",
                    templateColor4Text: "Midnight",
                    templateColor4HexValue: "#d5c9fe", 
                    templateColor5Text: "Pixker",
                    templateColor5HexValue: "#31622f",
                    templateColor6Text: "Abyss",
                    templateColor6HexValue: "#6a6870", 
                    templateColor7Text: "Trenches",
                    templateColor7HexValue: "#dd5d61",
                    templateColor8Text: "Twilight",
                    templateColor8HexValue: "#edacb9",

                }
            }
    
    />

    )

}

export default BackgroundGeneratorConfig;