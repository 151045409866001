import { useBetween } from "use-between";

// JSON FILES 

    // Good morning cafe OG
    import GMC_OG_COLLETION_METADATA_ASSETS_FEED from "./GMC/json/og_assets_traits.json";
    import GMC_OG_COLLETION_METADATA_TRAITS_FEED from "./GMC/json/og_collection_traits.json";

    // Kitty Cones OG
    import KC_OG_COLLETION_METADATA_ASSETS_FEED from "./KC/json/og_assets_traits.json";
    import KC_OG_COLLETION_METADATA_TRAITS_FEED from "./KC/json/og_collection_traits.json";
    // Kitty Cones 5K 
    import KC_5K_COLLECTION_METADATA_ASSETS_FEED from "./KC/json/5k_assets_traits.json";
    import KC_5K_COLLECTION_METADATA_TRAITS_FEED from "./KC/json/5k_collection_traits.json";

    // Chubby Land 
    import CHUBBY_OG_COLLECTION_METADATA_ASSETS_FEED from "./CHUBBY/json/assets_traits.json";
    import CHUBBY_OG_COLLECTION_METADATA_TRAITS_FEED from "./CHUBBY/json/collection_traits.json";

    // WonderPals 
    import WONDERPALS_OG_COLLECTION_METADATA_ASSETS_FEED from "./WP/json/assets_traits.json";
    import WONDERPALS_OG_COLLECTION_METADATA_TRAITS_FEED from "./WP/json/collection_traits.json";

    // Whiskers 
    import WHISKERS_OG_COLLECTION_METADATA_ASSETS_FEED from "./WHISKERS/json/assets_traits.json";
    import WHISKERS_OG_COLLECTION_METADATA_TRAITS_FEED from "./WHISKERS/json/collection_traits.json";

    // PROOF Moonbirds 
    import PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS_FEED from "./MOONBIRDS/PROOF/json/moonbirds_assets_traits.json";
    import PROOF_MOONBIRDS_COLLECTION_METADATA_TRAITS_FEED from "./MOONBIRDS/PROOF/json/moonbirds_collection_traits.json";
    import PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS_FEED from "./MOONBIRDS/PROOF/json/oddities_assets_traits.json";
    import PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS_FEED from "./MOONBIRDS/PROOF/json/oddities_collection_traits.json";
    // DBS Moonbirds
    import DBS_MOONBIRDS_COLLECTION_METADATA_ASSETS_FEED from "./MOONBIRDS/DBS/json/dbs_moonbirds_assets_traits.json";
    import DBS_MOONBIRDS_COLLECTION_METADATA_TRAITS_FEED from "./MOONBIRDS/PROOF/json/moonbirds_collection_traits.json";
    import DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS_FEED from "./MOONBIRDS/DBS/json/dbs_oddities_assets_traits.json";
    import DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS_FEED from "./MOONBIRDS/PROOF/json/oddities_collection_traits.json";

    // Ressources root URL 
    const STATIC_FILE_SERVER_ROOT_URL = "https://lfg-wagmi.xyz/bannermaker/static_images/";
    const LOCAL_FILE_SERVER_ROOT_URL = "images/";

const useCollectionsMetadata = () => {

    // General Configuation
    const MAX_ASSETS_PER_PANNEL = 8;

    // Location of the collection's assets

    // DEMO Collection 
    const DEMO_COLLETION_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/DEMO/lowRes_transparentBackground/";
    const DEMO_WORLD_COLLETION_METADATA = null;



    // GOOD MORNING CAFE Collection 
    const GMC_OG_COLLECTION_DETAILS = {
        collectionName: "goodmorningcafe",
        collectionFileNamePrefix: "HL_",
        itemName: "Highland Cows",
        isCollectionOver: false,
        maxCollectionSize: 333
    }
    const GMC_OG_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/goodMorningCafe/highRes_transparentBackground/";
    const GMC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/goodMorningCafe/highRes_original/";
    const GMC_OG_COLLECTION_METADATA_ASSETS = GMC_OG_COLLETION_METADATA_ASSETS_FEED.data;
    const GMC_OG_COLLECTION_METADATA_TRAITS = GMC_OG_COLLETION_METADATA_TRAITS_FEED.data;
    const GMC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/goodMorningCafe/official_backgrounds/";
    const GMC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const GMC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/goodMorningCafe/accessories/";



    // KITTY CONES OG Collection 
    const KC_OG_COLLECTION_DETAILS = {
        collectionName: "kitty-cones-classic-collection",
        collectionFileNamePrefix: "kc_classic_",
        itemName: "Kitty Cones OG",
        isCollectionOver: true,
        maxCollectionSize: 100
    }
    const KC_OG_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/kittyCones/og_highRes_transparentBackground/";
    const KC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/og_kittyCones/highRes_original/";
    const KC_OG_COLLECTION_METADATA_ASSETS = KC_OG_COLLETION_METADATA_ASSETS_FEED.data;
    const KC_OG_COLLECTION_METADATA_TRAITS = KC_OG_COLLETION_METADATA_TRAITS_FEED.data; 
    const KC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/kittyCones/official_backgrounds/";
    const KC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const KC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/kittyCones/accessories/";

    // Kitty Cones 5K Collection 
    const KC_5K_COLLECTION_DETAILS = {
        collectionName: "kitty-cones-collection",
        collectionFileNamePrefix: "kc_collection_",
        itemName: "Kitty Cones",
        isCollectionOver: true,
        maxCollectionSize: 5500
    }
    const KC_5K_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/kittyConesCollection/5k_highRes_transparentBackground/";
    const KC_5K_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/kittyConesCollection/5k_highRes_original/";
    const KC_5K_COLLECTION_METADATA_ASSETS = KC_5K_COLLECTION_METADATA_ASSETS_FEED.data;
    const KC_5K_COLLECTION_METADATA_TRAITS = KC_5K_COLLECTION_METADATA_TRAITS_FEED.data;
    const KC_5K_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/kittyCones/official_backgrounds/";
    const KC_5K_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const KC_5K_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/kittyCones/accessories/";



    // WONDERPALS Collection 
    const WONDERPALS_OG_COLLECTION_DETAILS = {
        collectionName: "wonderpals",
        collectionFileNamePrefix: "wonderpals_",
        itemName: "WonderPals",
        isCollectionOver: true,
        maxCollectionSize: 10000
    }
    const WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/wonderPals/highRes_transparentBackground/";
    const WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS = STATIC_FILE_SERVER_ROOT_URL + "partners/wonderPals/lowRes_original/";
    const WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/wonderPals/highRes_original/";
    const WONDERPALS_OG_COLLECTION_METADATA_ASSETS = WONDERPALS_OG_COLLECTION_METADATA_ASSETS_FEED.data;
    const WONDERPALS_OG_COLLECTION_METADATA_TRAITS = WONDERPALS_OG_COLLECTION_METADATA_TRAITS_FEED.data;
    const WONDERPALS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/wonderPals/backgrounds/";
    const WONDERPALS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const WONDERPALS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/wonderPals/accessories/";



    // WHISKERS Collection 
    const WHISKERS_OG_COLLECTION_DETAILS = {
        collectionName: "whiskersnft",
        collectionFileNamePrefix: "whiskers_",
        itemName: "whiskers",
        isCollectionOver: true,
        maxCollectionSize: 5555
    }
    const WHISKERS_OG_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/whiskers/highRes_transparentBackground/";
    const WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS = STATIC_FILE_SERVER_ROOT_URL + "partners/whiskers/lowRes_original/";
    const WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/whiskers/highRes_original/";
    const WHISKERS_OG_COLLECTION_METADATA_ASSETS = WHISKERS_OG_COLLECTION_METADATA_ASSETS_FEED.data;
    const WHISKERS_OG_COLLECTION_METADATA_TRAITS = WHISKERS_OG_COLLECTION_METADATA_TRAITS_FEED.data;
    const WHISKERS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/whiskers/backgrounds/";
    const WHISKERS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const WHISKERS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/whiskers/accessories/";
    




    // THE FOLLOWING COLLETION ARE NOT IN PRODUCTION YET 

    // MOONBIRDS Collection 
    const PROOF_MOONBIRDS_COLLECTION_DETAILS = {
        collectionName: "proof-moonbirds",
        collectionFileNamePrefix: "moonbirds_",
        itemName: "moonbirds",
        isCollectionOver: true,
        maxCollectionSize: 10000
    }
    const PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/moonbirds/assets_transparentBackground/";
    const PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS = STATIC_FILE_SERVER_ROOT_URL + "partners/moonbirds/assets_thumbnails/";
    const PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/moonbirds/assets_original/";
    const PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS = PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS_FEED.data;
    const PROOF_MOONBIRDS_COLLECTION_METADATA_TRAITS = PROOF_MOONBIRDS_COLLECTION_METADATA_TRAITS_FEED.data;
    const PROOF_MOONBIRDS_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/moonbirds/backgrounds/";
    const PROOF_MOONBIRDS_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const PROOF_MOONBIRDS_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/moonbirds/accessories/";
    const DBS_MOONBIRDS_COLLECTION_METADATA_ASSETS = DBS_MOONBIRDS_COLLECTION_METADATA_ASSETS_FEED.data;
    const DBS_MOONBIRDS_COLLECTION_METADATA_TRAITS = DBS_MOONBIRDS_COLLECTION_METADATA_TRAITS_FEED.data;
    
    // MOONBIRDS ODDITIES Collection
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_DETAILS = {
        collectionName: "moonbirds-oddities",
        collectionFileNamePrefix: "moonbirds-oddities_",
        itemName: "moonbirds-oddities",
        isCollectionOver: true,
        maxCollectionSize: 10000
    }
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/moonbirds-oddities/assets_transparentBackground/";
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS = STATIC_FILE_SERVER_ROOT_URL + "partners/moonbirds-oddities/assets_thumbnails/";
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND = STATIC_FILE_SERVER_ROOT_URL + "partners/moonbirds-oddities/assets_original/";
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS = PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS_FEED.data;
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS = PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS_FEED.data;
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/moonbirds-oddities/backgrounds/";
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const PROOF_MOONBIRDS_ODDITIES_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/moonbirds-oddities/accessories/";
    const DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS = DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS_FEED.data;
    const DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS = DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS_FEED.data;
    

    // ChubbyLand NFT - Collection's metadata
    const CHUBBY_OG_COLLECTION_DETAILS = {
        collectionName: "chubbylandnft",
        collectionFileNamePrefix: "chubbylandOG_",
        itemName: "ChubbyLand NFT",
        isCollectionOver: true,
        maxCollectionSize: 200
    }
    const CHUBBY_OG_COLLECTION_ASSET_DIRECTORY = STATIC_FILE_SERVER_ROOT_URL + "partners/chubbylandNFT/highRes_transparentBackground/";
    const CHUBBY_OG_COLLECTION_METADATA_ASSETS = CHUBBY_OG_COLLECTION_METADATA_ASSETS_FEED.data;
    const CHUBBY_OG_COLLECTION_METADATA_TRAITS = CHUBBY_OG_COLLECTION_METADATA_TRAITS_FEED.data;
    const CHUBBY_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/chubbylandNFT/backgrounds/";
    const CHUBBY_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "templates/";
    const CHUBBY_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY = LOCAL_FILE_SERVER_ROOT_URL + "partners/chubbylandNFT/accessories/";



    // Kumo x World - Collection's metadata
    // const KUMO_WORLD_COLLETION_METADATA = KUMO_WORLD_COLLETION_METADATA_FEED.data;
    // const KUMOWORLD_COLLETION_ASSET_DIRECTORY = "images/partners/kumoWorld/assets_transparentBackground/";




    // Generate a fix set of asset for the collection 
    // randomly from the netadata 
    function generateAllAssetsFromMetadata(metadataArray, assetDirectory, thumbnailDirectory = null, minArg = null, maxArg = null) { 

        // Asset numbers from min to max
        let min = 1;
        let max = metadataArray.length;

        // Overrides 
        if (minArg !== null) {
            console.log ("custom minArg=" + minArg);
            min = minArg;
        }
        if (maxArg !== null) {
            console.log ("custom maxArg=" + maxArg);
            max = maxArg;
        }

        //console.log("minArg=" + minArg + " , maxArg=" + maxArg + "min=" + min + " , max=" + max);
        
        let itemIds = [];

        // Create a local version of the max per pannel limit
        // That can be overriden if the list of items is below that limit 
        let maxItemsPerPannel = MAX_ASSETS_PER_PANNEL;
        if (max < MAX_ASSETS_PER_PANNEL) {
            maxItemsPerPannel = max-1;
        }
        //console.log("MAX_ASSETS_PER_PANNEL=" + MAX_ASSETS_PER_PANNEL + " - maxItemsPerPannel=" + maxItemsPerPannel);

        while (itemIds.length < maxItemsPerPannel) {
            const rand = Math.round(min + Math.random() * (max - min));
            // Make sure we don't have duplicate cow
            // And also remove specific cow we don't want to get included 
            if (!itemIds.includes(rand) && metadataArray[rand]) { 
                itemIds.push(rand);
            }
        } 
        
        let availableAssets = [];        // Array of cows that can be selected and later placed on the canvas
        itemIds.forEach(itemId => {
            const metadataItem = metadataArray[itemId];
            //console.log("Processing itemId=", metadata);
            
            const localFile = assetDirectory + metadataItem.fileName; 
            let localThumbnail = null;
            if (thumbnailDirectory !== null) {
                localThumbnail = thumbnailDirectory + metadataItem.fileName; 
            } else {
                localThumbnail = localFile;
            }
            availableAssets.push(
                {
                    id: 'itemId' + metadataItem.assetIndexNumber,
                    image_local_file_thumbnail: localThumbnail,         // Thumbnail version of the asset (default to image_local_file_transparent if do not exist)
                    image_local_file_transparent: localFile,            // Fullsize version of the asset (with transparent background)
                    name: metadataItem.name,
                    scale: 1,                                           // -1 to flip the cow
                    addedToCanvas: false                                // Allow to only add the cow once to the canvas
                }
            );
        });

        //console.log("availableAssets=", availableAssets);
        return availableAssets;
    };     



    

    // Generate assets for Good Morning Cafe Collection
    function generateAllGoodMorningCafeAssets() { 
        return generateAllAssetsFromMetadata(GMC_OG_COLLECTION_METADATA_ASSETS, GMC_OG_COLLECTION_ASSET_DIRECTORY);
    };     

    // Generate all Kitty Cones Classic Assets
    function generateAllKittyConesClassicAssets() { 
        return generateAllAssetsFromMetadata(KC_OG_COLLECTION_METADATA_ASSETS, KC_OG_COLLECTION_ASSET_DIRECTORY);        
    }; 

    // Generate assets for Kitty Cones 5K Collection 
    function generateAllKittyConesCollectionAssets() {
        return generateAllAssetsFromMetadata(KC_5K_COLLECTION_METADATA_ASSETS, KC_5K_COLLECTION_ASSET_DIRECTORY);
    }

    // Generate assets for Chubby Land NFT Collection
    function generateAllChubbyLandAssets() {
        return generateAllAssetsFromMetadata(CHUBBY_OG_COLLECTION_METADATA_ASSETS, CHUBBY_OG_COLLECTION_ASSET_DIRECTORY);
    }

    // Generate assets for Wonderpals NFT Collection
    function generateAllWonderPalsAssets() {
        return generateAllAssetsFromMetadata(WONDERPALS_OG_COLLECTION_METADATA_ASSETS, WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY, WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }

    // Generate assets for Whiskers NFT Collection
    function generateAllWhiskersAssets() {
        return generateAllAssetsFromMetadata(WHISKERS_OG_COLLECTION_METADATA_ASSETS, WHISKERS_OG_COLLECTION_ASSET_DIRECTORY, WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }

    // Generate assets for Proof Moonbirds NFT Collection (incl. sub-parliament collections)
    function generateAllMoonbirdsAssets() {
        return generateAllAssetsFromMetadata(PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }
    function generateAllMoonbirdsDBSAssets() {
        return generateAllAssetsFromMetadata(DBS_MOONBIRDS_COLLECTION_METADATA_ASSETS, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY, PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }
    // Generate assets for Proof Moonbirds Oddities NFT Collection (incl. sub-parliament collections)
    function generateAllMBOdditiesAssets() {
        return generateAllAssetsFromMetadata(PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS, PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY, PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }
    function generateAllMBOdditiesDBSAssets() {
        return generateAllAssetsFromMetadata(DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS, PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY, PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS);
    }

    // Generate assets for Kumo World Collection
    // function generateAllKumoWorldAssets() {
    //     return generateAllAssetsFromMetadata(KUMO_WORLD_COLLETION_METADATA, KUMOWORLD_COLLETION_ASSET_DIRECTORY, 1, 100);
    // }

    // Generate all Kitty Cones Classic Assets
    function generateAllDemoAssets() { 
        let collectItems = [
            { file: "28", name: "Starcatcher #1" },
            { file: "108", name: "Starcatcher #2" },
            { file: "128", name: "Starcatcher #3" },
            { file: "160", name: "Starcatcher #4" },
            { file: "193", name: "Starcatcher #5" },
            { file: "207", name: "Starcatcher #6" },
            { file: "273", name: "Starcatcher #7" },
            { file: "904", name: "Starcatcher #8" },
            { file: "968", name: "Starcatcher #9" },
            { file: "1118", name: "Starcatcher #10" },
            { file: "1490", name: "Starcatcher #11" },
            { file: "1500", name: "Starcatcher #12" },
            { file: "1714", name: "Starcatcher Land #13" },
            { file: "1782", name: "Starcatcher Land #14" },
            { file: "1931", name: "Starcatcher #15" },
            { file: "2060", name: "Starcatcher #16" },
            { file: "2145",  name: "Starcatcher #17" },
            { file: "2188", name: "Starcatcher #18" },
            { file: "2215", name: "Starcatcher #19" },
            { file: "2342", name: "Starcatcher #20" },
            { file: "7427", name: "Starcatcher #21" },
        ];
        let availableAssets = [];        // Array of cows that can be selected and later placed on the canvas
        collectItems.forEach((item, index) => {
            //console.log("Processing cowId=" + cowId);
            const localFile = DEMO_COLLETION_ASSET_DIRECTORY + item.file + ".png";    
            availableAssets.push(
                {
                    id: 'demoAssets' + index,
                    image_local_file_thumbnail: localFile,
                    image_local_file_transparent: localFile,
                    name: item.name,
                    scale: 1,              // -1 to flip the cow
                    addedToCanvas: false  // Allow to only add the cow once to the canvas
                }
            );
        });
        // Shuffle array 
        const shuffled = availableAssets.sort(() => 0.5 - Math.random());
        //setAllPartnerAssets(shuffled.slice(0, MAX_ASSETS_PER_PANNEL));
        return shuffled.slice(0, MAX_ASSETS_PER_PANNEL);
    };

    return {

        generateAllAssetsFromMetadata,              // Do we need to export this function?

        // DEMO COLLECTION 
        DEMO_WORLD_COLLETION_METADATA,
        DEMO_COLLETION_ASSET_DIRECTORY,
        generateAllDemoAssets,

        // GOOD MORNING CAFE COLLECTION 
        GMC_OG_COLLECTION_DETAILS,
        GMC_OG_COLLECTION_METADATA_ASSETS,
        GMC_OG_COLLECTION_METADATA_TRAITS,
        GMC_OG_COLLECTION_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        GMC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        GMC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllGoodMorningCafeAssets,

        // KITTY CONES - OG COLLECTION 
        KC_OG_COLLECTION_DETAILS,
        KC_OG_COLLECTION_METADATA_ASSETS,
        KC_OG_COLLECTION_METADATA_TRAITS,
        KC_OG_COLLECTION_ASSET_DIRECTORY,
        KC_OG_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,  
        KC_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        KC_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        KC_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllKittyConesClassicAssets,

        // KITTY CONES - GO 5K COLLECTION 
        KC_5K_COLLECTION_DETAILS,
        KC_5K_COLLECTION_METADATA_ASSETS,
        KC_5K_COLLECTION_METADATA_TRAITS,
        KC_5K_COLLECTION_ASSET_DIRECTORY,
        KC_5K_COLLECTION_ASSET_DIRECTORY_WITHBACKGROUND,
        KC_5K_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        KC_5K_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        KC_5K_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllKittyConesCollectionAssets,

        // WONDERPALS OG 
        WONDERPALS_OG_COLLECTION_DETAILS,
        WONDERPALS_OG_COLLECTION_METADATA_ASSETS,
        WONDERPALS_OG_COLLECTION_METADATA_TRAITS,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        WONDERPALS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        WONDERPALS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllWonderPalsAssets,

        // WHISKERS OG 
        WHISKERS_OG_COLLECTION_DETAILS,
        WHISKERS_OG_COLLECTION_METADATA_ASSETS,
        WHISKERS_OG_COLLECTION_METADATA_TRAITS,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        WHISKERS_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        WHISKERS_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllWhiskersAssets,

        // PROOF MOONBIRDS 
        PROOF_MOONBIRDS_COLLECTION_DETAILS,
        PROOF_MOONBIRDS_COLLECTION_METADATA_ASSETS,
        PROOF_MOONBIRDS_COLLECTION_METADATA_TRAITS,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        PROOF_MOONBIRDS_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllMoonbirdsAssets,
        DBS_MOONBIRDS_COLLECTION_METADATA_ASSETS,
        DBS_MOONBIRDS_COLLECTION_METADATA_TRAITS,
        generateAllMoonbirdsDBSAssets,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_DETAILS,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_ASSET_DIRECTORY_THUMBNAILS,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        PROOF_MOONBIRDS_ODDITIES_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllMBOdditiesAssets,
        DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_ASSETS,
        DBS_MOONBIRDS_ODDITIES_COLLECTION_METADATA_TRAITS,
        generateAllMBOdditiesDBSAssets,


        // COLLETION BELOW ARE NOT IN PRODUCTION YET 

        // CHUBBY LAND OG 
        CHUBBY_OG_COLLECTION_DETAILS,
        CHUBBY_OG_COLLECTION_METADATA_ASSETS,
        CHUBBY_OG_COLLECTION_METADATA_TRAITS,
        CHUBBY_OG_COLLECTION_ASSET_DIRECTORY,
        CHUBBY_OG_COLLECTION_BACKGROUND_ASSET_DIRECTORY,
        CHUBBY_OG_COLLECTION_BACKGROUND_TEMPLATE_ASSET_DIRECTORY,
        CHUBBY_OG_COLLECTION_ACCESSORIES_ASSET_DIRECTORY,
        generateAllChubbyLandAssets,

        // KUMO WORLD 
        // KUMO_WORLD_COLLETION_METADATA,
        // KUMOWORLD_COLLETION_ASSET_DIRECTORY,
        // generateAllKumoWorldAssets,

    };

};

export const useSharedCollectionsMetadata = () => useBetween(useCollectionsMetadata);