import { useState } from "react";
import { useBetween } from "use-between";

const useGlobalState = () => {
   
    // Application mode
    const env_APP_MODE = process.env.REACT_APP_MODE;
    
    // For debug purposed
    //const env_APP_MODE = "DEMO";
    
    // ---- PRODUCTION INSTANCES 
    //const env_APP_MODE = "GMC";
    //const env_APP_MODE = "KC";
    //const env_APP_MODE = "WHISKERS";
    //const env_APP_MODE = "WP";
    //const env_APP_MODE = "MOONBIRDS-PROOF";
    //const env_APP_MODE = "MOONBIRDS-DBS";
    
    // ---- DEV INSTANCES 
    //const env_APP_MODE = "GMC-EXPLORER";
    //const env_APP_MODE = "CHUBBY";    
    //const env_APP_MODE = "KUMO";
    
    // HopeAndSea server  configuration
    const HOPEANDSEA_SERVER_URL = "https://lfg-wagmi.xyz/hopeandsee";
    //const HOPEANDSEA_SERVER_URL = "http://localhost/NFTOSBackend";
    const HOPENANDSEA_API_KEY = "TCHOUPI-90MW9qmJdDEHeTinwuYZJ9%RA7Hhj8#1&9d63EUXHotP33T@iQlTHo6c9Uq6Mxed4Jh%0";


    // User management 
    const [userIsAdmin, setUserIsAdmin] = useState(false);                      // Is user admin (ie. match specific wallet address)

    // Wallet management 
    const [errorMessage, setErrorMessage] = useState(null);                     // Is metamask available?
    const [defaultAccount, setDefaultAccount] = useState(null);                 // Connected accuoutn
    const [walletConnected, setWalletConnected] = useState(false);              // Is the wallet connected? 

    return {
        env_APP_MODE,
        userIsAdmin, setUserIsAdmin,
        errorMessage, setErrorMessage,
        defaultAccount, setDefaultAccount,
        walletConnected, setWalletConnected,
        HOPEANDSEA_SERVER_URL, HOPENANDSEA_API_KEY
    };
    
}

export const useSharedGlobalState = () => useBetween(useGlobalState);