// Bootstrap declarations
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import tchoupiWP1 from "./assets/wonderpals_8104.png";
import tchoupiWP2 from "./assets/wonderpals_8105.png";

// Bringing up the additional content to add to the app modal
import AboutModalCommonNote from "../../components/shared/AboutModalCommonNote"

import FooterWalletConnector from "../../components/shared/FooterWalletConnect"

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";

const Footer = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        userHasCow,                              // TODO: Move somewhere else
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------



    return (

    <>
        <footer className="footer container-fluid">
            <FooterWalletConnector nftEntityName="WonderPal" doConnectWallet={true} />
        </footer>

        <Modal show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to the Wonderpals Banner Builder, the place to bring together your favorite WonderPals and share your happy vibes with the rest of the world.</p> 
                    <p><strong>Don't own a WonderPal?</strong> No worries, head over to our <a target="_blank" href="https://opensea.io/collection/wonderpals"><strong>Opensea collection</strong></a> to grab one and join the fun.</p>
                    <p><strong>Coded with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>.
                    {/* <br/>proud owner of:</p>
                    <p>
                    <a href="https://opensea.io/assets/0x3acce66cd37518a6d77d9ea3039e00b3a2955460/8104" target="_blank" rel="noreferrer"><img className="responsive" height="150" alt="WonderPal #8104" src={tchoupiWP1} /></a>
                    <a href="https://opensea.io/assets/0x3acce66cd37518a6d77d9ea3039e00b3a2955460/8105" target="_blank" rel="noreferrer"><img className="responsive" height="150" alt="WonderPal #8104" src={tchoupiWP2} /></a>
                     */}
                    </p>

                    <AboutModalCommonNote />

                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                OK
            </Button>
            </Modal.Footer>
        </Modal>
    </>

    );

}

export default Footer;