// Bootstrap declarations
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Bringing up the additional content to add to the app modal
import AboutModalCommonNote from "../../components/shared/AboutModalCommonNote"

// WP
import headerLogo from "./assets/WHISKERS_Header_Logo.png"
// import tchoupiWP1 from "./assets/wonderpals_8104.png";
// import tchoupiWP2 from "./assets/wonderpals_8105.png";

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";

const Header = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------

    return (

    <>

        <header className="container-fluid header">
            
            {/* Storefront row */}
            {/* <Row className="d-flex justify-content-center">
                <img className="d-none d-md-block" src={wonderPalsFrontBanner} 
                    alt="WonderPals Front Banner" 
                    height={200}
                    />
            </Row> */}
            <div className="d-flex justify-content-between align-items-center mt-4 mb-4 mx-5 ">
                
                {/* Navigation menu row */}
                
                <div className="ml-2">
                    <a href="#" onClick={() => window.open('https://whiskersnft.xyz/')}>Back to Website</a>    
                </div>

                <div className="headerLogo justify-content-between">
                    <svg width="206" height="50" viewBox="0 0 1090 264" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M171 188L112.5 95L104 109L167.5 199.5L171 188Z" fill="#744A3A"/>
                        <path d="M171 188L112 96L226.5 50L289.5 136.5L171 188Z" fill="#9A684F"/>
                        <path d="M191 127.5L189 104L215 117L219.5 129.5L200.5 139L191 127.5Z" fill="#01BABB"/>
                        <path d="M206 125C209.2 120.2 207.333 112.333 206 109C207.333 109 211.1 110.3 215.5 115.5C221 122 218.5 125 215.5 132.5C213.1 138.5 200.833 137 195 135.5C197.333 134 202.8 129.8 206 125Z" fill="#0295A5"/>
                        <path d="M169 201V208.5L175 212L285 165.5V152L169 201Z" fill="#F0DBC8"/>
                        <path d="M168 190V197.5L174 201L284 154.5V141L168 190Z" fill="#DBBBA6"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M225.438 43.3358C228.095 42.4466 231.024 43.3611 232.704 45.6048L298.204 133.105C299.402 134.705 299.796 136.768 299.274 138.697C299.028 139.61 298.589 140.444 298 141.153C297.344 141.943 296.501 142.577 295.53 142.987L288.368 146.014L298 159.214L301.251 163.668C302.428 165.282 302.798 167.348 302.254 169.27C301.71 171.192 300.313 172.758 298.464 173.515L190.252 217.842C177.913 222.897 163.725 218.371 156.593 207.104L104.574 124.934C93.6364 107.657 101.803 84.7006 121.194 78.2128L225.438 43.3358ZM163.608 193.882L115.558 117.981C109.134 107.834 113.93 94.3514 125.319 90.5411L225.11 57.1538L282.819 134.246L175.632 179.542C171.215 181.409 167.595 184.769 165.406 189.035C164.581 190.644 163.989 192.267 163.608 193.882ZM179.1 85.8C181.972 83.6461 186.046 84.2281 188.2 87.1C188.224 87.1317 188.247 87.1636 188.271 87.1958L188.303 87.2402C189.255 88.5384 192.809 93.3873 209.407 101.686C220.046 107.006 226.316 114.904 227.652 123.351C228.992 131.826 225.083 139.976 217.407 143.814C210.172 147.431 201.007 147.55 193.515 142.897C185.782 138.096 181 128.994 181 116.5C181 109.689 180.295 104.278 179.461 100.443C179.043 98.5216 178.604 97.0439 178.228 96.0132C177.975 95.3165 177.798 94.957 177.739 94.8366C177.724 94.8053 177.717 94.7895 177.717 94.7895C177.717 94.7895 177.718 94.7902 177.72 94.7917C177.72 94.7917 177.722 94.7945 177.724 94.7967C175.66 91.9312 176.262 87.9282 179.1 85.8ZM193.653 107.921C193.872 110.555 194 113.419 194 116.5C194 125.599 197.309 129.951 200.373 131.853C203.677 133.905 208.011 133.977 211.593 132.186C213.917 131.024 215.317 128.578 214.811 125.381C214.301 122.155 211.571 117.303 203.593 113.314C199.719 111.377 196.439 109.579 193.653 107.921ZM186.397 205.246C180.009 207.855 173.822 201.11 176.972 194.971C177.769 193.419 179.085 192.196 180.692 191.517L276.345 151.095L284 147.86V162.082V165.38L186.397 205.246Z" fill="#272627"/>
                        <path d="M395.03 194.665C391.33 194.665 388.123 193 385.41 189.67C382.697 186.34 380.292 181.9 378.195 176.35C376.222 170.677 373.817 162.783 370.98 152.67C367.773 141.57 364.752 131.888 361.915 123.625C359.202 115.362 355.872 106.913 351.925 98.28C361.175 95.69 371.227 94.395 382.08 94.395C382.82 100.438 384.238 108.393 386.335 118.26C388.432 128.003 390.652 136.883 392.995 144.9C396.078 154.643 399.223 163.277 402.43 170.8C410.2 146.75 415.688 128.62 418.895 116.41L411.495 98.28C420.252 95.69 430.242 94.395 441.465 94.395C442.452 101.178 444.055 109.565 446.275 119.555C448.495 129.545 450.592 137.993 452.565 144.9C456.018 155.753 459.102 164.387 461.815 170.8C468.475 150.573 473.347 134.787 476.43 123.44C479.637 111.97 481.24 104.015 481.24 99.575C481.24 98.465 481.117 97.4167 480.87 96.43C486.79 95.0733 493.512 94.395 501.035 94.395C496.718 106.482 491.477 122.022 485.31 141.015C479.143 159.885 474.272 175.548 470.695 188.005L454.6 194.665C450.777 194.665 447.508 193 444.795 189.67C442.205 186.34 439.923 181.962 437.95 176.535C435.977 171.108 433.51 163.215 430.55 152.855L426.48 139.165C426.48 139.165 426.11 140.337 425.37 142.68C424.877 144.037 424.445 145.517 424.075 147.12C419.018 162.783 414.763 176.412 411.31 188.005L395.03 194.665Z" fill="#272627"/>
                        <path d="M518.685 194.11C514.615 194.11 509.805 193.74 504.255 193C505.118 177.213 505.55 161.488 505.55 145.825C505.55 124.365 504.933 107.592 503.7 95.505C511.84 93.0383 521.152 91.805 531.635 91.805C530.525 99.945 529.662 111.723 529.045 127.14C535.952 119.493 544.215 115.67 553.835 115.67C568.265 115.67 575.48 124.18 575.48 141.2L575.11 152.855L574.74 165.25C574.74 178.2 575.973 187.45 578.44 193C574.987 193.74 569.868 194.11 563.085 194.11C558.892 194.11 554.637 193.74 550.32 193C551.183 180.05 551.615 166.36 551.615 151.93C551.615 146.997 550.752 143.482 549.025 141.385C547.298 139.165 544.462 138.055 540.515 138.055C535.952 138.055 532.067 139.35 528.86 141.94L528.675 152.485C528.675 174.068 529.97 187.573 532.56 193C527.997 193.74 523.372 194.11 518.685 194.11Z" fill="#272627"/>
                        <path d="M605.343 194.665C600.41 194.665 596.895 193.555 594.798 191.335C592.825 189.115 591.838 185.168 591.838 179.495C591.838 174.932 591.962 168.457 592.208 160.07C592.455 152.177 592.578 146.38 592.578 142.68C592.578 137.747 592.27 133.122 591.653 128.805C591.037 124.488 590.173 121.405 589.063 119.555C593.627 118.198 598.067 117.273 602.383 116.78C606.7 116.163 611.51 115.855 616.813 115.855C616.197 119.432 615.642 124.982 615.148 132.505C614.778 139.905 614.593 148.23 614.593 157.48C614.593 164.263 614.778 169.443 615.148 173.02C615.642 176.597 616.32 179.433 617.183 181.53C618.17 183.627 619.712 185.785 621.808 188.005C619.218 190.348 616.382 192.013 613.298 193C610.338 194.11 607.687 194.665 605.343 194.665ZM603.678 107.9C599.238 102.597 594.243 97.54 588.693 92.73C593.627 86.9333 598.622 81.8767 603.678 77.56C608.242 83.11 613.298 88.1667 618.848 92.73C614.285 98.28 609.228 103.337 603.678 107.9Z" fill="#272627"/>
                        <path d="M657.341 195.035C644.145 195.035 633.723 192.815 626.076 188.375C626.076 185.168 626.323 182.27 626.816 179.68C627.433 177.09 628.358 174.253 629.591 171.17C632.058 174.13 635.388 176.535 639.581 178.385C643.898 180.112 648.708 180.975 654.011 180.975C662.768 180.975 667.146 178.94 667.146 174.87C667.146 173.02 666.16 171.54 664.186 170.43C662.213 169.32 658.883 167.84 654.196 165.99C648.77 163.893 644.206 161.858 640.506 159.885C636.93 157.912 633.785 155.137 631.071 151.56C628.481 147.983 627.186 143.543 627.186 138.24C627.186 131.333 630.146 125.845 636.066 121.775C642.11 117.705 650.126 115.67 660.116 115.67C665.296 115.67 670.538 116.102 675.841 116.965C681.145 117.705 685.708 118.753 689.531 120.11C689.531 123.193 688.853 127.202 687.496 132.135C686.263 137.068 684.906 140.645 683.426 142.865C678.74 139.165 674.115 136.267 669.551 134.17C664.988 131.95 661.041 130.84 657.711 130.84C656.231 131.21 654.936 131.888 653.826 132.875C652.84 133.738 652.346 134.725 652.346 135.835C652.346 137.438 653.395 138.918 655.491 140.275C657.588 141.508 660.918 143.05 665.481 144.9C670.908 147.12 675.41 149.217 678.986 151.19C682.563 153.163 685.646 155.815 688.236 159.145C690.826 162.475 692.121 166.545 692.121 171.355C692.121 178.878 689.038 184.737 682.871 188.93C676.828 193 668.318 195.035 657.341 195.035Z" fill="#272627"/>
                        <path d="M715.073 194.11C710.386 194.11 705.083 193.74 699.163 193C700.026 177.213 700.458 161.488 700.458 145.825C700.458 124.365 699.841 107.592 698.608 95.505C706.748 93.0383 716.059 91.805 726.543 91.805C725.679 98.3417 724.939 107.283 724.323 118.63C723.829 129.977 723.583 140.768 723.583 151.005V153.225C734.929 141.015 742.391 132.628 745.968 128.065C749.668 123.378 751.518 119.925 751.518 117.705C759.041 116.348 766.934 115.67 775.198 115.67C769.524 121.097 760.274 130.47 747.448 143.79C762.001 168.827 773.224 184.305 781.118 190.225C774.828 192.815 765.146 194.11 752.073 194.11C749.606 189.3 742.761 178.323 731.538 161.18L723.768 169.875C724.384 181.468 725.618 189.177 727.468 193C723.398 193.74 719.266 194.11 715.073 194.11Z" fill="#272627"/>
                        <path d="M812.35 195.035C801.003 195.035 792.308 191.828 786.265 185.415C780.221 178.878 777.2 169.628 777.2 157.665C777.2 149.525 778.988 142.31 782.565 136.02C786.141 129.607 791.136 124.612 797.55 121.035C804.086 117.458 811.486 115.67 819.75 115.67C830.603 115.67 838.805 119.062 844.355 125.845C849.905 132.628 852.68 142.618 852.68 155.815C845.773 157.418 837.756 158.837 828.63 160.07C819.503 161.18 810.561 161.797 801.805 161.92C802.791 166.36 804.765 169.628 807.725 171.725C810.685 173.822 814.755 174.87 819.935 174.87C825.238 174.87 830.233 174.253 834.92 173.02C839.606 171.787 843.676 169.998 847.13 167.655L849.905 178.57C845.835 183.503 840.346 187.512 833.44 190.595C826.533 193.555 819.503 195.035 812.35 195.035ZM800.695 152.485C805.628 151.868 810.438 151.005 815.125 149.895C819.935 148.785 823.943 147.49 827.15 146.01C826.903 140.83 825.238 136.513 822.155 133.06C819.195 129.607 815.495 127.88 811.055 127.88C807.971 129.977 805.505 133.245 803.655 137.685C801.805 142.125 800.818 147.058 800.695 152.485Z" fill="#272627"/>
                        <path d="M880.573 194.11C876.503 194.11 871.693 193.74 866.143 193C867.006 180.667 867.438 167.532 867.438 153.595C867.438 146.812 867.191 141.632 866.698 138.055C866.328 134.478 865.649 131.642 864.663 129.545C863.799 127.448 862.319 125.29 860.223 123.07C862.813 120.727 865.588 119.062 868.548 118.075C871.631 116.965 874.344 116.41 876.688 116.41C885.814 116.41 890.748 120.85 891.488 129.73C894.448 125.907 897.593 122.762 900.923 120.295C904.253 117.828 908.076 115.978 912.393 114.745C913.256 117.582 913.996 121.59 914.613 126.77C915.353 131.827 915.723 136.513 915.723 140.83C913.503 140.46 911.406 140.275 909.433 140.275C905.733 140.275 902.279 140.768 899.073 141.755C895.866 142.618 893.214 143.79 891.118 145.27C890.871 153.163 890.748 159.823 890.748 165.25C890.748 178.2 891.981 187.45 894.448 193C890.994 193.74 886.369 194.11 880.573 194.11Z" fill="#272627"/>
                        <path d="M948.774 195.035C935.577 195.035 925.156 192.815 917.509 188.375C917.509 185.168 917.756 182.27 918.249 179.68C918.866 177.09 919.791 174.253 921.024 171.17C923.491 174.13 926.821 176.535 931.014 178.385C935.331 180.112 940.141 180.975 945.444 180.975C954.201 180.975 958.579 178.94 958.579 174.87C958.579 173.02 957.592 171.54 955.619 170.43C953.646 169.32 950.316 167.84 945.629 165.99C940.202 163.893 935.639 161.858 931.939 159.885C928.362 157.912 925.217 155.137 922.504 151.56C919.914 147.983 918.619 143.543 918.619 138.24C918.619 131.333 921.579 125.845 927.499 121.775C933.542 117.705 941.559 115.67 951.549 115.67C956.729 115.67 961.971 116.102 967.274 116.965C972.577 117.705 977.141 118.753 980.964 120.11C980.964 123.193 980.286 127.202 978.929 132.135C977.696 137.068 976.339 140.645 974.859 142.865C970.172 139.165 965.547 136.267 960.984 134.17C956.421 131.95 952.474 130.84 949.144 130.84C947.664 131.21 946.369 131.888 945.259 132.875C944.272 133.738 943.779 134.725 943.779 135.835C943.779 137.438 944.827 138.918 946.924 140.275C949.021 141.508 952.351 143.05 956.914 144.9C962.341 147.12 966.842 149.217 970.419 151.19C973.996 153.163 977.079 155.815 979.669 159.145C982.259 162.475 983.554 166.545 983.554 171.355C983.554 178.878 980.471 184.737 974.304 188.93C968.261 193 959.751 195.035 948.774 195.035Z" fill="#272627"/>
                    </svg>
                </div> 

                <div className="mr-2">
                    <a href="#" onClick={handleModalShow}>About</a>
                </div>

            </div>
 
        </header>

        {/* ABOUT PAGE MODAL  */}
        <Modal className="aboutModal" show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to the Whiskers Banner Generator, the place to bring together your favorite Whiskers and share them with the rest of the world.</p> 
                    <p><strong>Don't own a Whisker?</strong> No worries, head over to our <a target="_blank" href="https://opensea.io/collection/whiskersnft">Opensea collection</a> to grab one and join the fun.</p>
                    <p><strong>Coded and designed with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>
                    {/* <br/>proud owner of:</p>
                    <p>
                    <a href="https://opensea.io/assets/0x3acce66cd37518a6d77d9ea3039e00b3a2955460/8104" target="_blank" rel="noreferrer"><img className="responsive" height="150" alt="WonderPal #8104" src={tchoupiWP1} /></a>
                    <a href="https://opensea.io/assets/0x3acce66cd37518a6d77d9ea3039e00b3a2955460/8105" target="_blank" rel="noreferrer"><img className="responsive" height="150" alt="WonderPal #8104" src={tchoupiWP2} /></a>
                    */}
                    </p> 

                    <AboutModalCommonNote />

                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                OK
            </Button>
            </Modal.Footer>
        </Modal>

    </>

    );

}

export default Header;