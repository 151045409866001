import React, {useState} from 'react'

// Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'react-bootstrap/Alert';

// Spinning wheel
import {ReactSpinner} from 'react-spinning-wheel';
import 'react-spinning-wheel/dist/style.css';

// Custom Icons Bootstrap
import { ArrowsCollapse } from 'react-bootstrap-icons';

// Custom Icons FontAwesome (TODO: Migrate to bootsrap if available)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";

// Context modules
import { useSharedGlobalState } from "../shared/GlobalState";
import { useSharedOpenseaFunctions } from "../shared/OpenseaFunctions";
import { useSharedNavigationFunctions } from "../shared/NavigationFunctions";

// Number formating
import NumberFormat from "react-number-format";

const OpenseaStat = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        userIsAdmin
    } = useSharedGlobalState();
    // Opensea functions
    const { 
        openseaDataCollectionStats,                     // Opensea data "Collection Stats" for UI
        openseaDataCollectionAssetsListFromArtist,      // Opensea data "Collection Assets" for UI
        openseaDataCollectionTopSales,                  // Top sales for collection ranked by # sales (DESC)
        openseaDataCollectionMostExpensiveSales,        // Most expensive sales (excluding mint sales)
        openseaCollectionOwnAssetDetails,               // Details of assets currently own
        openseaCollectionTopHolders,                    // Top holders of the collection, rank my # of item own
        openseaCollectionSnapshotHolders,               // Snapshot holder list 
        openseaDataCollectionHistoricalSales,           // Historical sales of assets, from newest to holders (top 50)
        openseaDataCollectionOldestHolders,             // Top olders holders of the collection, rank by seniority (in days of holding)
        openseaDataCollectionLoyalAssets,               // All assetst that have never been sold since last acquisition 
        openseaLegacyReportEnabled                      // Are we using the legacy Opensea report or the new one (this is to handle migration and is temporary)
    } = useSharedOpenseaFunctions();
    // Mavigation functions
    const { 
        userHasCow,                                             // TODO: Move somewhere else
        collectionStatisticsLabels,                             // TODO: Move somewhere else
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------

    // TOGGLE ELEMENTS FOR OPENSEA PAGE 
    const [toggleUIStatsOverview, setToggleUIStatsOverview] = useState(true);
    const [toggleUIStatsCowForAdoption, setToggleUIStatsCowForAdoption] = useState(true);
    const [toggleUIStatsPopularCows, setToggleUIStatsPopularCows] = useState(false);
    const [toggleUIStatsCowHolders, setToggleUIStatsCowHolders] = useState(false);
    const [toggleUIStatsHistoricalSales, setToggleUIStatsHistoricalSales] = useState(false);
    const [toggleUIStatsExpensiveCows, setToggleUIStatsExpensiveCows] = useState(false);
    const [toggleUIStatsHolderSnapshot, setToggleUIStatsHolderSnapshot] = useState(false);
    const [toggleUIStatsAvailableCows, setToggleUIStatsAvailableCows] = useState(true);
    const [toggleUIStatsOlderHolders, setToggleUIStatsOlderHolders] = useState(false);
    const [toggleUIStatsLoyalAssets, setToggleUIStatsLoyalAssets] = useState(false);
    

    

    // -----------------
    // UTILITY FUNCTIONS
    // -----------------

    // Get current date 
    function getCurrentDate(separator=''){
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }

    return (

        <Container>

        { !openseaLegacyReportEnabled ? (
            <>
            { openseaDataCollectionStats !== null && (
                <Alert variant={"warning"} className="mt-2">The information on this page is based on Opensea data and is up to date as of {openseaDataCollectionStats.openseaLastRefreshDate} UTC</Alert>
            )}
            </>
        ) : (

            <>
            { openseaDataCollectionStats !== null && (
                <Alert variant={"warning"} className="mt-2">The information on this page is based on real-time Opensea data and might take a while to load!</Alert>
            )}
            </>

        )}

        {/* 
        
        ================================= OVERALL COLLETION STATISTICS MODULE
        
        */}

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsOverview(toggleUIStatsOverview => !toggleUIStatsOverview)}
                aria-controls="collapseToggleUIStatsOverview"
                aria-expanded={toggleUIStatsOverview}>
                <strong>Overall Collection Statistics</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsOverview}>
                <div className="collapse" id="collapseToggleUIStatsOverview">
            <Card.Body>
                <Card.Text>

                    { !openseaLegacyReportEnabled ? (

                    <>
                        { openseaDataCollectionStats !== null ? (
                            <>
                            <Row className="align-items-center">
                                <Col className="d-flex flex-column align-items-center">
                                    <div className="OSoverallStats">{collectionStatisticsLabels.maxCollectionSize}</div>
                                    <div className="OSoverallStatsLegend"><strong>Total {collectionStatisticsLabels.itemName} In The Collection</strong></div>
                                </Col>
                                <Col className="d-flex flex-column align-items-center">
                                    <div className="OSoverallStats">{openseaDataCollectionStats.count_holdersOnlyAssets}</div>
                                    <div className="OSoverallStatsLegend"><strong>{collectionStatisticsLabels.itemName} Adopted</strong></div>
                                </Col>
                                { openseaDataCollectionStats.isCollectionSoldOut === false && openseaDataCollectionStats.count_remainingToMint > 0 ? (
                                    <Col className="d-flex flex-column align-items-center">
                                        { openseaDataCollectionStats.count_remainingToMint === 0 ? (
                                            <></>
                                        ): (
                                            <>
                                                <div className="OSoverallStats">{openseaDataCollectionStats.count_remainingToMint} <span className="OSoverallStatsSmall">(Custom: {openseaDataCollectionStats.count_remainingToMint_regular}, Regular: {openseaDataCollectionStats.count_remainingToMint_custom})</span></div>
                                                <div className="OSoverallStatsLegend"><strong>Total {collectionStatisticsLabels.itemName} Left To Be Drawn</strong></div>
                                            </>
                                        )}       
                                    </Col>
                                ) : (
                                    <Col className="d-flex flex-column align-items-center">
                                        
                                    </Col>                             
                                )}
                            </Row>
                            <Row className="mt-4 align-items-center">
                                <Col className="d-flex flex-column align-items-center">
                                    <div className="OSoverallStats">{openseaDataCollectionStats.floor_price} <FontAwesomeIcon icon={faEthereum} /></div>
                                    <div className="OSoverallStatsLegend"><strong>Floor Price</strong></div>
                                </Col>
                                <Col className="d-flex flex-column align-items-center">
                                    <div className="OSoverallStats">
                                            <NumberFormat
                                                value={openseaDataCollectionStats.salesNb_last_14_days}
                                                displayType="text"
                                                decimalScale={0}/>&nbsp;
                                            / <NumberFormat
                                                value={openseaDataCollectionStats.salesNb_last_30_days}
                                                displayType="text"
                                                decimalScale={0}/>&nbsp;
                                            / <NumberFormat
                                                value={openseaDataCollectionStats.salesNb_last_180_days}
                                                displayType="text"
                                                decimalScale={0}/>
                                        </div>
                                    <div className="OSoverallStatsLegend"><strong># of sales on OpenSea in the past 14/30/180 days</strong></div>
                                </Col>
                                <Col className="d-flex flex-column align-items-center">
                                    <div className="OSoverallStats">    
                                            <NumberFormat
                                                value={openseaDataCollectionStats.salesEth_last_14_days}
                                                displayType="text"
                                                decimalScale={0}/><FontAwesomeIcon icon={faEthereum} />&nbsp;
                                            / <NumberFormat
                                                value={openseaDataCollectionStats.salesEth_last_30_days}
                                                displayType="text"
                                                decimalScale={0}/><FontAwesomeIcon icon={faEthereum} />&nbsp;
                                            / <NumberFormat
                                                value={openseaDataCollectionStats.salesEth_last_180_days}
                                                displayType="text"
                                                decimalScale={0}/><FontAwesomeIcon icon={faEthereum} />
                                    </div>
                                    <div className="OSoverallStatsLegend"><strong>Total Sales in the past 14/30/180 days</strong></div>
                                </Col>
                            </Row>
                            </>
                        ) : (
                            <Row className="align-items-center">
                                <Col className="d-flex flex-column align-items-center col-lg-12">
                                    <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                                </Col>
                            </Row>
                        )}
                    </>

                    ) : (

                    <>
                        { openseaDataCollectionStats !== null ? (
                            <>
                                <Row className="align-items-center">
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="OSoverallStats">{collectionStatisticsLabels.maxCollectionSize}</div>
                                        <div className="OSoverallStatsLegend"><strong>Total {collectionStatisticsLabels.itemName} In The Collection</strong></div>
                                    </Col>
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="OSoverallStats">{openseaDataCollectionStats.count_holdersOnlyAssets}</div>
                                        <div className="OSoverallStatsLegend"><strong>{collectionStatisticsLabels.itemName} Adopted</strong></div>
                                    </Col>
                                    { openseaDataCollectionStats.total_left_custom_to_draw !== undefined ? (
                                    <Col className="d-flex flex-column align-items-center">
                                        { openseaDataCollectionStats.total_left_custom_to_draw === 0 ? (
                                            <div className="OSoverallStats">{openseaDataCollectionStats.total_left_to_draw}</div>
                                        ): (
                                            <div className="OSoverallStats">{openseaDataCollectionStats.total_left_to_draw} <span className="OSoverallStatsSmall">(Custom: {openseaDataCollectionStats.total_left_custom_to_draw}, Regular: {openseaDataCollectionStats.total_left_regular_to_draw})</span></div>
                                        )}
                                        <div className="OSoverallStatsLegend"><strong>Total {collectionStatisticsLabels.itemName} Left To Be Drawn</strong></div>
                                    </Col>
                                    ) : (
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="OSoverallStats">0</div>
                                        <div className="OSoverallStatsLegend"><strong>Total {collectionStatisticsLabels.itemName} Left To Be Drawn</strong></div>
                                    </Col>
                                    
                                    )}
                                </Row>
                                <Row className="mt-4 align-items-center">
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="OSoverallStats">{openseaDataCollectionStats.floor_price} <FontAwesomeIcon icon={faEthereum} /></div>
                                        <div className="OSoverallStatsLegend"><strong>Floor Price</strong></div>
                                    </Col>
                                    <Col className="d-flex flex-column align-items-center">
                                    </Col>
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="OSoverallStats">{openseaDataCollectionStats.total_sales}</div>
                                        <div className="OSoverallStatsLegend"><strong>Total Sales (incl. primary) in the past 90 days</strong></div>
                                    </Col>
                                </Row>
                                </>
                        ) : (
                            <Row className="align-items-center">
                                <Col className="d-flex flex-column align-items-center col-lg-12">
                                    <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                                </Col>
                            </Row>
                        )}
                    </>   

                    )}
                    
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        {/* 
        
        ================================= ASSET AVAILABLE FOR ADOPTION (FOR NON SOLD OUT COLLECTIONS)
        
        */}

        { openseaDataCollectionStats !== null && !openseaDataCollectionStats.isCollectionSoldOut && (

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsCowForAdoption(toggleUIStatsCowForAdoption => !toggleUIStatsCowForAdoption)}
                aria-controls="collapseToggleUIStatsCowForAdoption"
                aria-expanded={toggleUIStatsCowForAdoption}>
                <strong>{collectionStatisticsLabels.itemName} Up For Adoption</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsCowForAdoption}>
                <div className="collapse" id="collapseToggleUIStatsCowForAdoption">
            <Card.Body>
                <Card.Text>

                    <p>Here are {collectionStatisticsLabels.itemName} that the artist has not listed yet!</p>
                    <Row className="align-items-center">
                        { openseaDataCollectionAssetsListFromArtist !== null ? (
                            <>
                            { openseaDataCollectionAssetsListFromArtist.length >0 ? (
                                <>
                                    <Col className="justify-content-center">
                                    {openseaDataCollectionAssetsListFromArtist.map(d => (
                                        <a target="blank" href={d.opensea_asset_url}>
                                            <img
                                                alt={d.asset_name}
                                                title={d.asset_name}
                                                src={d.image_thumbnail_url}
                                                height={120}
                                            />
                                        </a>
                                    ))}
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col className="text-center"><strong>There aren't any cows available for adoption at this time!</strong><br/>But no worries, there are still {openseaDataCollectionStats.total_left_to_draw} left so make sure to join the Discord to learn more.</Col>
                                </>
                            )}
                            </>
                        ) : (
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                        )}

                    </Row>
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        ) }

        {/* 
        
        ================================= [BEHIND WALLET GATE] POPULAR ASSETS
        
        */}

        { userHasCow && ( 

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsPopularCows(toggleUIStatsPopularCows => !toggleUIStatsPopularCows)}
                aria-controls="collapseToggleUIStatsPopularCows"
                aria-expanded={toggleUIStatsPopularCows}>
                <strong>Popular {collectionStatisticsLabels.itemName}</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsPopularCows}>
                <div className="collapse" id="collapseToggleUIStatsPopularCows">
            <Card.Body>
                <Card.Text>
                    <p>Here are the cows that have change hands the most, from the most exchanged cow, to the least exchanged cow</p>
                    
                        { openseaDataCollectionTopSales !== null ? (
                            <>
                            {openseaDataCollectionTopSales.map(numSales => (
                                 <>
                                <Row className="d-flex mb-4 align-items-center flex-row">
                                    <Col className="col-lg-2 justify-content-center">
                                            <p><strong>Sold {numSales[0].num_sales} times<br/>Total {collectionStatisticsLabels.itemName}: {numSales.length}</strong></p>
                                    </Col>
                                    <Col className="col-lg-10 justify-content-start">
                                    {numSales.map(d => (
                                        <a target="blank" href={d.opensea_asset_url}>
                                            <img
                                                alt={d.asset_name}
                                                title={d.asset_name}
                                                src={d.image_thumbnail_url}
                                                height={60}
                                            />
                                        </a>
                                    ))}
                                    </Col>
                                </Row>
                                </>
                            ))}
                            </>
                            
                        ) : (
                            <Row>
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                            </Row>
                        )}

                   
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        ) }

        {/* 
        
        ================================= [BEHIND WALLET GATE] TOP HOLDERS 
        
        */}

        { userHasCow && ( 

        <Card className="mt-2">
            <Card.Header 
               onClick={() => setToggleUIStatsCowHolders(toggleUIStatsCowHolders => !toggleUIStatsCowHolders)}
                aria-controls="collaspeToggleUIStatsCowHolders"
                aria-expanded={toggleUIStatsCowHolders}>
                    <strong>{collectionStatisticsLabels.itemName} Hoarders</strong>
                    <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsCowHolders}>
                <div className="collapse" id="collaspeToggleUIStatsCowHolders">
            <Card.Body>
                <Card.Text>
                    <p>Here are the people that hold the most {collectionStatisticsLabels.itemName}</p>
                    
                        { openseaCollectionTopHolders !== null ? (
                            <>
                            {openseaCollectionTopHolders.map(groupSet => (
                                <>
                                <Row className="mt-1">

                                    {/* Rendering for people holding more than one cow */}
                                    
                                    {groupSet[0].length > 1 ? (

                                    <>
                                    <Col className="col-lg-2 justify-content-center">
                                        <strong>Holding {groupSet[0].length} {collectionStatisticsLabels.itemName}</strong>
                                    </Col>

                                    <Col className="col-lg-10">
                                
                                        {groupSet.map(userSet => (
                                            <Row className="mt-1" >
                                                <Col className="col">
                                                    <a target="_blank" rel="noreferrer" href={userSet[0].e_userOpenseaSeaProfileLink}>{userSet[0].e_userAbbreviatedName}</a>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                    {userSet.map(d => (
                                                        <Col className="m-0 p-0">
                                                        <a target="blank" href={d.opensea_asset_url}>
                                                            <img
                                                                alt={d.asset_name + " (" + d.winner_account_username + ")"}
                                                                title={d.asset_name + " (" + d.winner_account_username + ")"}
                                                                src={d.image_thumbnail_url}
                                                                height={60}
                                                            />
                                                        </a>
                                                        </Col>
                                                    ))}
                                                    </Row>
                                                </Col>
                                            </Row>                                         
                                        ))}
                                    
                                    </Col>
                                    </>

                                    ) : (

                                        <>
                                        {/* {groupSet.map(userSet => (
                                            <div className="">
                                                {userSet.map(d => (
                                                    <a target="blank" href={d.permalink}>
                                                        <img
                                                            title={d.asset_name + " (" + d.userName + ")"}
                                                            src={d.image_thumbnail_url}
                                                            height={60}
                                                        />
                                                    </a>
                                                ))}
                                            </div>                                         
                                        ))} */}
                                        </>

                                    )}

                                </Row>
                                </>
                            ))}
                            </>
                            
                        ) : (
                            <Row>
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                            </Row>
                        )}

                
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        ) }

        {/* 
        
        ================================= [ADMIN ONLY] SNAPSHOT MODULE 
        
        */}

        { userIsAdmin && ( 

            <Card className="mt-2">
            <Card.Header
                onClick={() => setToggleUIStatsHolderSnapshot(toggleUIStatsHolderSnapshot => !toggleUIStatsHolderSnapshot)}
                aria-controls="collapseUICowSelector"
                aria-expanded={toggleUIStatsHolderSnapshot}>
            <strong>{collectionStatisticsLabels.itemName} Holders Snapshot</strong>
            <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsHolderSnapshot}>
                <div className="collapse" id="collapseStatsHolderSnapshot">
                <Card.Body>
                    <Card.Text>
                        <p>Snapshot of current holders as of {getCurrentDate}</p>

                        { openseaCollectionSnapshotHolders !== null ? (
                            <>
                            {openseaCollectionSnapshotHolders.map(userSet => (
                                <>
                                {/* {groupSet.map(userSet => (
                                    <> */}
                                        <Row>
                                            <pre>User: {userSet.holderUserName} - with {userSet.nbAsset} NFT(s): {userSet.holderAddress}</pre>                     
                                        </Row>
                                    {/* </>
                                ))} */}
                                </>
                            ))}
                            </>
                        ) : (
                            <Row>
                                <Col className="d-flex flex-column align-items-center col-lg-12">
                                    <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                                </Col>
                            </Row>
                        )}

                    </Card.Text>
                </Card.Body>
                </div>
            </Collapse>
        </Card>

        )}

        {/* 
        
        ================================= HISTORICAL SALES 
        
        */}

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsHistoricalSales(toggleUIStatsHistoricalSales => !toggleUIStatsHistoricalSales)}
                aria-controls="collapseToggleUIStatsExpensiveCows"
                aria-expanded={toggleUIStatsHistoricalSales}>
                <strong>Latest {collectionStatisticsLabels.itemName} sales</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsHistoricalSales}>
                <div className="collapse" id="collapseToggleUIStatsHistoricalSales">
            <Card.Body>
                <Card.Text>
                        { openseaDataCollectionHistoricalSales !== null ? ( 
                            <>
                            <p>Here are the last {openseaDataCollectionHistoricalSales.length} {collectionStatisticsLabels.itemName} that have been purchased (Time in UTC, excluding direct sales)</p>
                        
                            <Row className="align-items-center">
                                {openseaDataCollectionHistoricalSales.map(d => (
                                    <>
                                    {d.transaction_price > 0.1 && (
                                        <>
                                        <Col className="mb-2">
                                        {/* <div className="align-items-center"> */}
                                            <a target="blank" href={d.opensea_asset_url}>
                                                <img
                                                    alt={d.asset_name}
                                                    title={d.asset_name}
                                                    src={d.image_thumbnail_url}
                                                    height={60}
                                                />
                                            </a>
                                            <br/>
                                            <NumberFormat
                                                value={d.transaction_price}
                                                displayType="text"
                                                decimalScale={2}/>
                                            <FontAwesomeIcon icon={faEthereum} /> 
                                            {/* ( or {d.last_sale_price_usd} <CurrencyDollar />) */}
                                            <br/><a target="_blank" rel="noreferrer" href={d.e_userOpenseaSeaProfileLink}>{d.e_userAbbreviatedName}</a>
                                            <br/>{d.e_transaction_date_short}
                                        {/* </div> */}
                                        </Col>
                                        </>
                                    )}
                                    </>
                                ))}
                                
                            </Row>

                            { userIsAdmin && ( 
                                <>
                                <hr/>
                                <Row>
                                    <p>Latest {collectionStatisticsLabels.itemName} scooped from the field</p>
                                    <ul>
                                        {openseaDataCollectionHistoricalSales.map(d => (
                                            <>
                                            {d.transaction_price > 0.1 && (
                                                <>
                                                <Row>
                                                    <li><a target="_blank" rel="noreferrer" href={d.opensea_asset_url}>{d.asset_name}</a> to <a target="_blank" rel="noreferrer" href={d.e_userOpenseaSeaProfileLink}>{d.winner_account_username}</a> for {d.transaction_price} ETH (on {d.e_transaction_date_short})</li>                  
                                                </Row>
                                                </>
                                            )}
                                            </>
                                        ))}
                                    </ul>
                                </Row>
                                </>
                            )}

                            </> 
                        ) : (
                            <Row>
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                            </Row>
                        )}
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        {/* 
        
        ================================= [BEHIND WALLET GATE] OLDEST ASSETS/HOLDERS 
        
        */}

        { !openseaLegacyReportEnabled && userHasCow && ( 

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsOlderHolders(toggleUIStatsOlderHolders => !toggleUIStatsOlderHolders)}
                aria-controls="collapseToggleUIStatssOlderHolders"
                aria-expanded={toggleUIStatsOlderHolders}>
                <strong>Oldest {collectionStatisticsLabels.itemName}</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsOlderHolders}>
                <div className="collapse" id="collapsesetToggleUIStatsOlderHolders">
            <Card.Body>
                <Card.Text>
                        <p>Here are the {collectionStatisticsLabels.itemName} that have been hold the longest by their respective holders - (Only top 10% of total {collectionStatisticsLabels.itemName} are listed) </p>
                        { openseaDataCollectionOldestHolders !== null ? ( 
                            <>
                            <Row className="align-items-center">
                                {openseaDataCollectionOldestHolders.map(d => (
                                    <>
                                    <Col className="">
                                    {/* <div className="align-items-center"> */}
                                        <a target="blank" href={d.opensea_asset_url}>
                                            <img
                                                alt={d.asset_name}
                                                title={d.asset_name}
                                                src={d.image_thumbnail_url}
                                                height={60}
                                            />
                                        </a>
                                        <br/><p>{d.holdingTimeInDays} day(s)
                                        <br/><a target="_blank" rel="noreferrer" href={d.e_userOpenseaSeaProfileLink}>{d.e_userAbbreviatedName}</a>
                                        <br/>#{d.e_asset_name_hashtag}</p>
                                    {/* </div> */}
                                    </Col>
                                    </>
                                ))}
                                
                            </Row>
                            </> 
                        ) : (
                            <Row>
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                            </Row>
                        )}
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        ) }

        {/* 
        
        ================================= [BEHIND WALLET GATE] LOYAL ASSETS/HOLDERS 
        
        */}

        { !openseaLegacyReportEnabled && userHasCow && ( 

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsLoyalAssets(toggleUIStatsLoyalAssets => !toggleUIStatsLoyalAssets)}
                aria-controls="collapseToggleUIStatssLoyalAssets"
                aria-expanded={toggleUIStatsLoyalAssets}>
                <strong>Loyal {collectionStatisticsLabels.itemName}</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsLoyalAssets}>
                <div className="collapse" id="collapsesetToggleUIStatsLoyalAssets">
            <Card.Body>
                <Card.Text>
                        { openseaDataCollectionLoyalAssets !== null ? ( 
                            <>
                            <p>Here are the {openseaDataCollectionLoyalAssets.length}/{collectionStatisticsLabels.maxCollectionSize} {collectionStatisticsLabels.itemName} that have been loyal to their holders as they never got sold since they got adopted! </p>
                        
                            <Row className="align-items-center">
                                {openseaDataCollectionLoyalAssets.map(d => (
                                    <>
                                    {/* <Col className=""> */}
                                    {/* <div className="align-items-center"> */}
                                        <a target="blank" href={d.opensea_asset_url}>
                                            <img
                                                alt={d.asset_name}
                                                title={d.asset_name}
                                                src={d.image_thumbnail_url}
                                                height={60}
                                            />
                                        </a>
                                        {/* <br/><p><a target="_blank" rel="noreferrer" href={d.e_userOpenseaSeaProfileLink}>{d.e_userAbbreviatedName}</a>
                                        <br/>#{d.e_asset_name_hashtag}</p> */}
                                    {/* </div> */}
                                    {/* </Col> */}
                                    </>
                                ))}
                                
                            </Row>
                            </> 
                        ) : (
                            <Row>
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                            </Row>
                        )}
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        ) }

       {/* 
        
        ================================= [BEHIND WALLET GATE] MOST EXPENSIVE ASSETS 
        
        */}

        { !openseaLegacyReportEnabled && userHasCow && ( 

        <Card className="mt-2">
            <Card.Header onClick={() => setToggleUIStatsExpensiveCows(toggleUIStatsExpensiveCows => !toggleUIStatsExpensiveCows)}
                aria-controls="collapseToggleUIStatsExpensiveCows"
                aria-expanded={toggleUIStatsExpensiveCows}>
                <strong>Expensive {collectionStatisticsLabels.itemName}</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsExpensiveCows}>
                <div className="collapse" id="collapseToggleUIStatsExpensiveCows">
            <Card.Body>
                <Card.Text>
                        <p>Here are the most expensive {collectionStatisticsLabels.itemName} (as per last sale price) from the most expensive, to the least expensive that was sold. This exclude the first sale to the owner (either through direct sale or auction)</p>
                        { openseaDataCollectionMostExpensiveSales !== null ? ( 
                            <>
                            <Row className="align-items-center">
                                {openseaDataCollectionMostExpensiveSales.map(d => (
                                    <>
                                    <Col className="">
                                    {/* <div className="align-items-center"> */}
                                        <a target="blank" href={d.opensea_asset_url}>
                                            <img
                                                alt={d.asset_name}
                                                title={d.asset_name}
                                                src={d.image_thumbnail_url}
                                                height={60}
                                            />
                                        </a>
                                        <br/><p><NumberFormat
                                                value={d.transaction_price}
                                                displayType="text"
                                                decimalScale={2}/> <FontAwesomeIcon icon={faEthereum} />
                                        <br/>#{d.e_asset_name_hashtag}</p>
                                    {/* </div> */}
                                    </Col>
                                    </>
                                ))}
                                
                            </Row>
                            </> 
                        ) : (
                            <Row>
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                            </Row>
                        )}
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>

        ) }

        {/* 
        
        ================================= [BEHIND WALLET GATE] AVAILABLE ASSETS
        
        */}

        <Card className="mt-2 mb-2">
            <Card.Header onClick={() => setToggleUIStatsAvailableCows(toggleUIStatsAvailableCows => !toggleUIStatsAvailableCows)}
                aria-controls="collapseToggleUIStatsAvailableCows"
                aria-expanded={toggleUIStatsAvailableCows}>
                <strong>{collectionStatisticsLabels.itemName} Adopted</strong>
                <ArrowsCollapse className="float-right"/></Card.Header>
            <Collapse in={toggleUIStatsAvailableCows}>
                <div className="collapse" id="collapseToggleUIStatsAvailableCows">
            <Card.Body>
                <Card.Text>
                    <p>These {collectionStatisticsLabels.itemName} have found a lucky owner! Come meet them in the Discord</p>
                    <Row className="align-items-center">
                        { openseaCollectionOwnAssetDetails !== null ? (
                            <>
                                <Col className="justify-content-center">
                                {openseaCollectionOwnAssetDetails.map(d => (
                                    <>
                                        <a target="blank" href={d.opensea_asset_url}>
                                            <img
                                                alt={d.asset_name}
                                                title={d.asset_name}
                                                src={d.image_thumbnail_url}
                                                height={100} />
                                        </a>
                                        {/* <p>Name: {d.asset_name} | TokenID: {d.opensea_token_id}</p> */}
                                    </>
                                ))}
                                </Col>
                            </>
                        ) : (
                            <Col className="d-flex flex-column align-items-center col-lg-12">
                                <div className=""><ReactSpinner /><strong>Loading data...</strong></div>
                            </Col>
                        )}
                    </Row>
                </Card.Text>
            </Card.Body>
            </div>
            </Collapse>
        </Card>
        
    </Container>

    )

}

export default OpenseaStat;