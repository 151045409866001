import React from "react";
import { Html } from "react-konva-utils";

function getStyle(width, height, fontSize, fontWeight, fontFamily, textAlignment, textColor) {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const baseStyle = {
    width: `${width+40}px`,
    height: `${height+40}px`,
    border: "6px solid red",
    padding: "0px",
    margin: "0px",
    background: "rgb(158,158,158, .4)",   // Light gray w/ opacity 10%
    outline: "none",
    resize: "none",
    color: `${textColor}`,
    fontSize: `${fontSize}px`,
    fontWeight:`${fontWeight}`,
    fontFamily: `${fontFamily}`,
    textAlign: `${textAlignment}`,
    display: "flex",
    alignItems: "center"

  };
  if (isFirefox) {
    return baseStyle;
  }
  return {
    ...baseStyle,
    margintop: "-4px"
  };
}

export function EditableTextInput({
  x,
  y,
  width,
  height,
  value,
  onChange,
  onKeyDown,
  fontSize,
  fontWeight,
  fontFamily,
  textAlign,
  textColor
}) {

  const style = getStyle(width, height, fontSize, fontWeight, fontFamily, textAlign, textColor);

  // Need to recompute x & y to overlap with source shape 
  const updatedX = x-(width/2);
  const updatedY = y-(height/2);

  //console.log("EditableTextInput conf: x=" + x + " y=" + y + " width=" + width + " height="+ height);
  //console.log("EditableTextInput updatedX=" + updatedX + " updatedY=" + updatedY);
  
  return (
    <Html 
          groupProps={{ x: updatedX, y: updatedY }} 
          divProps={{ style: { opacity: 1 } }}
          >
      <div contentEditable="true"
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={style}>
            {value}
      </div>
      {/* <p style={{'color': "red", 'font-size': "30px"}}>You are editing the above text.<br/>Press Return or Esc key to exit and save</p> */}
      
    </Html>
  )
}
