// Bootstrap declarations
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// KC
import kittyConesFrontBanner from "./assets/KC_Storefront_banner.png"
import kittyConesHeaderLogo from "./assets/KC_animated_logo.gif"
import tchoupiRobocone from "./assets/Robocone.jpeg"

// Bringing up the additional content to add to the app modal
import AboutModalCommonNote from "../../components/shared/AboutModalCommonNote"

// Context modules
import { useSharedNavigationFunctions } from "../../shared/NavigationFunctions";

const Header = (props) => {

    // Retrieve items from context --------------------------------------------------
    // Navigation functions
    const { 
        handleModalShow, handleModalClose,
        modalShow,
        selectedNavItem, selectGenericLink
    } = useSharedNavigationFunctions();
    // Retrieve items from context --------------------------------------------------

    return (

    <>

        <header className="container-fluid header">
            <Container>
            {/* Storefront row */}
            <Row className="d-flex justify-content-center mb-4">
                <img className="d-none d-md-block" src={kittyConesFrontBanner} 
                    alt="Kitty Cones Front Banner" 
                    height={120}
                    />
            </Row>
            <Row className="justify-content-center">
                {/* Navigation menu row */}
                <div className="headerLogo">
                    <a target="_blank" rel="noreferrer" href="https://www.kittycones.com/"><img src={kittyConesHeaderLogo}
                    height={100} 
                    alt="Kitty Cones Logo" 
                     /></a>
                </div> 
                <Col className="d-sm-flex justify-content-evenly align-items-center">
                    {selectedNavItem === "BACKGROUND_GENERATOR_NOT_GATED" && 
                        <button className="navButtonSelected" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_NOT_GATED")}>Meme Generator</button>
                    }
                    {selectedNavItem !== "BACKGROUND_GENERATOR_NOT_GATED" && 
                        <button className="navButton" onClick={() => selectGenericLink("BACKGROUND_GENERATOR_NOT_GATED")}>Meme Generator</button>
                    }
                    {selectedNavItem === "STATS_COLLECTION_KITTYCONES" ? (
                        <button className="navButtonSelected" onClick={() => selectGenericLink("STATS_COLLECTION_KITTYCONES")}>OG Collection Statistics</button>
                    ) : (
                        <button className="navButton" onClick={() => selectGenericLink("STATS_COLLECTION_KITTYCONES")}>OG Collection Statistics</button>
                    )}
                    <button className="navButton" onClick={handleModalShow}>About</button>
                </Col>
            </Row>
            </Container>
        </header>

        {/* ABOUT PAGE MODAL  */}
        <Modal show={modalShow} onHide={handleModalClose}>
            <Modal.Header>
            <Modal.Title>About this app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p>Welcome to the Kitty Cones Meme Generator that lets you customize your own Kitty Cone banner or meme.</p> 
                    <p><strong>Don't own a Kitty Cones?</strong> No worries, head over to our <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/kitty-cones-classic-collection">Opensea collection</a> to grab one and join the fun.</p>
                    <p><strong>Coded and designed with ❤️ &nbsp;by <a target="_blank" rel="noreferrer" href="https://twitter.com/ToudeMoune">Tchoupi</a></strong>, proud owner of <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/74975937772666258590622858317476707436629968938658204892454822515624745369601" target="_blank" rel="noreferrer">Robocone.</a></p>
                    <p><img alt="Robocone" src={tchoupiRobocone} /></p>
                    <AboutModalCommonNote />
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
                OK
            </Button>
            </Modal.Footer>
        </Modal>

    </>

    );

}

export default Header;