import { useState } from "react";
import { useBetween } from "use-between";

// Context modules
import { useSharedGlobalState } from "../shared/GlobalState";
import { useSharedNavigationFunctions } from "./NavigationFunctions";
import { useSharedOpenseaFunctions } from "./OpenseaFunctions";

// --------------------------
// PAGE NAVIGATION MANAGEMENT
// --------------------------

const useNavigationPageGlobalState = () => {

    // Retrieve items from context --------------------------------------------------
    // Global states
    const { 
        userIsAdmin, setUserIsAdmin
    } = useSharedGlobalState();
    // Navigation functions
    const { 
        setSelectedNavItem, selectedNavItem, selectGenericLink,
        isNavItemSelected, setIsNavItemSelected,
        //isSelectedCowOnCanvas, setIsSelectedCowOnCanvas
        userHasCow, setUserHasCow
    } = useSharedNavigationFunctions();
    // Opensea functions
    const { 
        openseaCollectionTopHolders, setOpenseaCollectionTopHolders,
        openseaDataCollectionStats, setOpenseaDataCollectionStats,
        openseaDataCollectionAssetsListFromArtist, setOpenseaDataCollectionAssetsListFromArtist,
        openseaCollectionOwnAssetDetails, setOpenseaCollectionOwnAssetDetails,
        openseaDataCollectionTopSales, setOpenseaDataCollectionTopSales,
        openseaDataCollectionMostExpensiveSales, setOpenseaDataCollectionMostExpensiveSales,
        openseaDataCollectionHistoricalSales, setOpenseaDataCollectionHistoricalSales,
    } = useSharedOpenseaFunctions();
    // Retrieve items from context --------------------------------------------------
    

    /// MODULE CONFIGURATION ------------------------------

    /// MODULE CONFIGURATION ------------------------------



    // Opensea data
    const [ownerAvailableCows, setOwnerAvailableCows] = useState([]);               // List of assets own by connected user
    const [ownerAvailableCowsNFT, setOwnerAvailableCowsNFT] = useState(0);          // Number of assets own by connected user
    const [ownerWalletAddress, setOwnerWalletAddress] = useState(null);
    
    const [openseaCollectionStats] = useState([]);
    const [openseaCollectionData, setOpenseaCollectionData] = useState([]);
    
    // Change the cow selected by the user
    const [hasUserSelectedACow, setHasUserSelectedACow] = useState(false);
    const [selectedCowData, setSelectedCowData] = useState(false);




    
    return {
        isNavItemSelected, setIsNavItemSelected,
        selectedNavItem, setSelectedNavItem, 
        selectGenericLink,
        //availableCows, setAvailableCows,
        ownerAvailableCows, setOwnerAvailableCows,
        ownerAvailableCowsNFT, setOwnerAvailableCowsNFT,
        //openseaCollectionStatistics,
        openseaCollectionStats,
        userHasCow, setUserHasCow,
        hasUserSelectedACow, setHasUserSelectedACow,
        selectedCowData, setSelectedCowData,
        ownerWalletAddress, setOwnerWalletAddress,                  // User wallet address to prevent querying user collection multiple time 
        openseaCollectionData, setOpenseaCollectionData,            // Store overall opensea configuration
        openseaDataCollectionStats, setOpenseaDataCollectionStats,  // Opensea data "Collection Stats" for UI
        openseaDataCollectionAssetsListFromArtist, setOpenseaDataCollectionAssetsListFromArtist, // Opensea data "Collection Assets" for UI
        openseaDataCollectionTopSales, setOpenseaDataCollectionTopSales,     // Top sales for collection ranked by # sales (DESC)
        openseaDataCollectionMostExpensiveSales, setOpenseaDataCollectionMostExpensiveSales,    // Most expensive sales (excluding mint sales)
        openseaCollectionOwnAssetDetails, setOpenseaCollectionOwnAssetDetails,  // Details of assets currently own
        openseaCollectionTopHolders, setOpenseaCollectionTopHolders,         // Top holders of the collection, rank my # of item own
        openseaDataCollectionHistoricalSales, setOpenseaDataCollectionHistoricalSales,  // Historical sales of assets, from newest to holders (top 50)
        userIsAdmin, setUserIsAdmin
    };

};

export const useSharedNavigationPageGlobalState = () => useBetween(useNavigationPageGlobalState);